// Customizable Area Start
import React, { Component } from "react";
import { Typography, styled } from "@mui/material";

interface Props {
  title: string;
}

interface State {}

export default class MainTitle extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { props } = this;
    return <MainTitleStyle>{props.title}</MainTitleStyle>;
  }
}

const MainTitleStyle = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "40px",
  textAlign: "center",
  color: "#1C1917",
  fontWeight: "500",
  lineHeight: "50px",
});

// Customizable Area End
