import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}
interface RealWeddingData{
  id: number,
 type: string,
 attributes: {
     id: number,
     title: string,
     description: string,
     real_wedding_profile_id: number
 }}
interface RealWeddingMahendiData{
  id: number,
 type: string,
 attributes: {
     id: number,
     title: string,
     description: string,
     real_wedding_profile_id: number,
     image: {
      url: string,
      type: string
  }
 }
}
interface RealWeddingAlbumData{
  id: number,
 type: string,
 attributes: {
     id: number,
     title: string,
     description: string,
     real_wedding_profile_id: number,
     album: [{
      url: string,
      type: string
  },
  {
    url: string,
    type: string
},
{
  url: string,
  type: string
},
{
  url: string,
  type: string
},
{
  url: string,
  type: string
},
{
  url: string,
  type: string
}]
 }
}
interface RealWeddingDescriptionDatas {
            id: string,
             type: string,
            attributes: {
                id: number,
                venue: string,
                wedding_planner: string,
                decor: string,
                photography: string,
                groom_outfit: string,
                bride_outfits: string,
                makeup: string,
                entertainment: string,
                encee: string,
                choreography: string,
                wedding_invitation: string,
                bartenders: string,
                real_wedding_profile_id:number
            }
        }
interface S {
  // Customizable Area Start
  isLoading: boolean;
  hasMore: boolean;
  realWeddingId:number;
  realWeddingData: RealWeddingData;
  realWeddingMahendiData: RealWeddingMahendiData;
  realWeddingHaldiData: RealWeddingMahendiData;
  realWeddingBaratData: RealWeddingMahendiData;
  realWeddingSangitData:RealWeddingMahendiData;
  realWeddingAlbumData:RealWeddingAlbumData;
  realWeddingDescriptionData: RealWeddingDescriptionDatas
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class RealWeddingItemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRealWeddingProfileDataCallId:string ="";
  getRealWeddingMahendiCallId:string="";
  getRealWeddingHaldiCallId:string="";
  getRealWeddingBaratCallId:string="";
  getRealWeddingSangitCallId:string="";
  getRealWeddingAlbumCallId:string="";
  getRealWeddingDescriptionCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      hasMore: true,
      realWeddingId:0,
      realWeddingData:{
        id: 0,
        type: "",
        attributes: {
          id: 0,
          title: "",
          description: "",
          real_wedding_profile_id:0
        }
      },
      realWeddingMahendiData:{
        id: 0,
        type: "",
        attributes: {
          id: 0,
          title: "",
          description: "",
          real_wedding_profile_id: 0,
          image: {
            url: "",
            type: ""
          }
        }
      },
      realWeddingHaldiData:{
        id: 0,
        type: "",
        attributes: {
          id: 0,
          title: "",
          description: "",
          real_wedding_profile_id: 0,
          image: {
            url: "",
            type: ""
          }
        }
      },
      realWeddingBaratData:{
        id: 0,
        type: "",
        attributes: {
          id: 0,
          title: "",
          description: "",
          real_wedding_profile_id: 0,
          image: {
            url: "",
            type: ""
          }
        }
      },
      realWeddingSangitData:{
        id: 0,
        type: "",
        attributes: {
          id: 0,
          title: "",
          description: "",
          real_wedding_profile_id: 0,
          image: {
            url: "",
            type: ""
          }
        }
      },
      realWeddingAlbumData:{
        id: 0,
        type: "",
        attributes: {
          id: 0,
          title: "",
          description: "",
          real_wedding_profile_id: 0,
          album: [{
            url: "",
            type: ""
          }, {
            url: "",
            type: ""
          }, {
            url: "",
            type: ""
          },{
            url: "",
            type: ""
          },{
            url: "",
            type: ""
          },{
            url: "",
            type: ""
          }]
        },
      },
      realWeddingDescriptionData:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          venue: "",
          wedding_planner: "",
          decor: "",
          photography: "",
          groom_outfit: "",
          bride_outfits: "",
          makeup: "",
          entertainment: "",
          encee: "",
          choreography: "",
          wedding_invitation: "",
          bartenders: "",
          real_wedding_profile_id: 0
        }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.getRealWeddingProfileDataCallId:
          if(!webResponseJson.error){
            const RealWeddingItemsData= webResponseJson.data.filter((data:RealWeddingData) => {
              return data.attributes.real_wedding_profile_id === this.state.realWeddingId;
            })
            this.setState({
              isLoading: false,
              realWeddingData: RealWeddingItemsData[0],
            });
          }
          break;
          case this.getRealWeddingMahendiCallId:
            const RealWeddingItemsMahendiData= webResponseJson.data.filter((data:RealWeddingMahendiData) => {
              return data.attributes.real_wedding_profile_id === this.state.realWeddingId;
            })
            this.setState({
              isLoading: false,
              realWeddingMahendiData: RealWeddingItemsMahendiData[0],
            });
            break;
            case this.getRealWeddingHaldiCallId:
            const realWeddingHaldiDatas= webResponseJson.data.filter((data:RealWeddingMahendiData) => {
              return data.attributes.real_wedding_profile_id === this.state.realWeddingId;
            })
            this.setState({
              isLoading: false,
              realWeddingHaldiData: realWeddingHaldiDatas[0],
            });
            break;
            case this.getRealWeddingBaratCallId:
            const realWeddingBaratDatas= webResponseJson.data.filter((data:RealWeddingMahendiData) => {
              return data.attributes.real_wedding_profile_id === this.state.realWeddingId;
            })
            this.setState({
              isLoading: false,
              realWeddingBaratData: realWeddingBaratDatas[0],
            });
            break;
            case this.getRealWeddingSangitCallId:
            const realWeddingSangitDatas= webResponseJson.data.filter((data:RealWeddingMahendiData) => {
              return data.attributes.real_wedding_profile_id === this.state.realWeddingId;
            })
            this.setState({
              isLoading: false,
              realWeddingSangitData: realWeddingSangitDatas[0],
            });
            break;
            case this.getRealWeddingAlbumCallId:
            const realWeddingAlbumDatas= webResponseJson.data.filter((data:RealWeddingMahendiData) => {
              return data.attributes.real_wedding_profile_id === this.state.realWeddingId;
            })
            this.setState({
              isLoading: false,
              realWeddingAlbumData: realWeddingAlbumDatas?.[0],
            });
            break;
            case this.getRealWeddingDescriptionCallId:
            const realWeddingDescriptionDatas= webResponseJson.data.filter((data:RealWeddingMahendiData) => {
              return data.attributes.real_wedding_profile_id === this.state.realWeddingId;
            })
            this.setState({
              isLoading: false,
              realWeddingDescriptionData: realWeddingDescriptionDatas[0],
            });
            break;
          default:
        }
      }
    }

  async componentDidMount(): Promise<void> {
    let realWeddingId = await getStorageData('realWeddingId')
    this.setState({realWeddingId: Number(realWeddingId)});
    this.getRealWeddingProfileData()
    this.getRealWeddingMahendi()
    this.getRealWeddingHaldi()
    this.getRealWeddingBarat()
    this.getRealWeddingSangit()
    this.getRealWeddingAlbum()
    this.getRealWeddingDescriptionData()
  }
  getRealWeddingProfileData= async() => {
    this.setState({
      isLoading: true,
    });
    this.getRealWeddingProfileDataCallId = await apiCall({
      endPoint: "bx_block_content_management/real_wedding_headings",
      method: "GET",
    });
  }
  getRealWeddingMahendi= async() => {
    this.setState({
      isLoading: true,
    });
    this.getRealWeddingMahendiCallId = await apiCall({
      endPoint: "bx_block_content_management/real_wedding_mehendis",
      method: "GET",
    });
  }
  getRealWeddingHaldi= async() => {
    this.setState({
      isLoading: true,
    });
    this.getRealWeddingHaldiCallId = await apiCall({
      endPoint: "bx_block_content_management/real_wedding_haldis",
      method: "GET",
    });
  }
  getRealWeddingBarat= async() => {
    this.setState({
      isLoading: true,
    });
    this.getRealWeddingBaratCallId = await apiCall({
      endPoint: "bx_block_content_management/real_wedding_barats",
      method: "GET",
    });
  }
  getRealWeddingSangit= async() => {
    this.setState({
      isLoading: true,
    });
    this.getRealWeddingSangitCallId = await apiCall({
      endPoint: "bx_block_content_management/real_wedding_sangets",
      method: "GET",
    });
  }
  getRealWeddingAlbum= async() => {
    this.setState({
      isLoading: true,
    });
    this.getRealWeddingAlbumCallId = await apiCall({
      endPoint: "bx_block_content_management/real_wedding_albums",
      method: "GET",
    });
  }
  getRealWeddingDescriptionData= async() => {
    this.setState({
      isLoading: true,
    });
    this.getRealWeddingDescriptionCallId = await apiCall({
      endPoint: "bx_block_content_management/real_wedding_descriptions",
      method: "GET",
    });
  }
  // Customizable Area End
}
