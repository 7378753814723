// Customizable Area Start
import React, { Component } from "react";
import { Box, Typography, styled, Button,InputLabel } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { contactUsBackground } from "../assets";
import SelectField from "../../../../components/src/SelectField.web";
import TextAreaFiled from "../../../../components/src/TextAreaField.web";
import TextInputFiled from "../../../../components/src/TextInputField.web";
import { isError, helperText } from "../../../../components/src/utils";
import { ISubject } from "../LandingPageController";
import MainTitle from "./MainTitle.web";
import PhoneInput from "react-phone-input-2";

interface Props {
  subjectList: ISubject[];
  contactUsApiIntegration: (values: {
    name: string;
    email: string;
    subject_id: string;
    phone_number: string;
    description: string;
    phoneId:string;
  }) => void;
  formikContactRef: any;
}

interface State {}

export default class ContactUsSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  handlePhoneChange = (phone:any) => {
    this.setState({ phoneNumber: phone });
  }

  render() {
    const { props } = this;
    
    return (
      <ContactUsContainer component="section" id="contact-us">
        <MainTitle title="Get in touch with us" />
        <Typography className="contact-us-description">
          Planning your dream wedding starts here. We’re excited to help you
          every step of the way! Whether you have questions, need inspiration,
          or are ready to start planning, we’re here for you.
        </Typography>
        <Box className="contact-us-image-container">
          <Box className="contact-us-form-container">
            <Typography className="contact-us-title">
              Get in touch with us{" "}
            </Typography>
            <Formik
              initialValues={{
                email: "",
                name: "",
                phone_number: "",
                subject_id: "",
                description: "",
                phoneId:"+91"
              }}
              innerRef={props.formikContactRef}
              data-test-id="contact-us-formik"
              enableReinitialize
              validationSchema={validationContactUsSchema}
              onSubmit={(values) => {
                props.contactUsApiIntegration(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    <TextInputFiled
                      name="name"
                      sx={{ marginBottom: "12px" }}
                      placeholder="Enter your name"
                      label="Full name"
                      data-test-id="full-name-input"
                      value={values.name}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isRequired
                      type="text"
                      error={isError(touched.name, errors.name)}
                      helperText={helperText(touched.name, errors.name)}
                    />
                    <TextInputFiled
                      name="email"
                      sx={{ marginBottom: "12px" }}
                      placeholder="Enter your email"
                      label="Email"
                      data-test-id="email-input"
                      value={values.email}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isRequired
                      type="text"
                      error={isError(touched.email, errors.email)}
                      helperText={helperText(touched.email, errors.email)}
                    />
                    <InputLabel className="labelClass" style={{fontSize:"14px",fontWeight:500,color:"rgb(51, 65, 85)",display:"block", fontFamily:"poppins"}}>Your mobile number<span style={{color:"red"}}>*</span></InputLabel>
                    <Box
                      className="numberField"
                      sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", sm: "row" } }} 
                    >
                      <PhoneInput
                        data-test-id="countryId"
                        country={"in"}
                        enableSearch={true}
                        disableSearchIcon={true}
                        enableAreaCodes={false}
                        enableTerritories={false}
                        onChange={(phone) => {
                          const formattedPhone = `+${phone}`;
                          setFieldValue("phoneId", formattedPhone);
                        }}
                        inputProps={{
                          readOnly: true,
                        }}
                        containerStyle={{
                          width: "auto",
                        }}
                        inputStyle={{
                          display: "none",
                        }}
                        buttonStyle={{
                          width: "auto",
                          backgroundColor: "#EFF6FF",
                          border: "none",
                        }}
                        dropdownStyle={{
                          width: "45vw",
                        }}
                        searchStyle={{
                          border: "none",
                          padding: "12px",
                          width: "100%",
                          borderBottom: "1px solid black",
                        }}
                      />
                      <TextInputFiled
                        name="phone_number"
                        sx={{
                          marginLeft: { xs: "0", sm: "3rem" },
                          marginTop: "1rem",
                          width: { xs: "100%", sm: "60vw", md: "45vw", lg: "40vw" },
                        }}
                        placeholder="Enter your number"
                        data-test-id="mobile-number-input"
                        value={values.phone_number} 
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isRequired
                        type="text" 
                        error={isError(touched.phone_number, errors.phone_number)}
                        helperText={helperText(touched.phone_number, errors.phone_number)}
                      />
                    </Box>
                    <SelectField
                      data-test-id="subject-list-select"
                      error={isError(touched.subject_id, errors.subject_id)}
                      sx={{ marginBottom: "12px" }}
                      isRequired
                      touched={touched.subject_id}
                      placeholder="Select your subject"
                      handleBlur={handleBlur("subject_id")}
                      helperText={touched.subject_id && errors.subject_id}
                      label="Subject"
                      name="subject_id"
                      options={props.subjectList.map((data) => ({
                        label: data.name,
                        value: data.id,
                      }))}
                      setFieldValue={setFieldValue}
                      value={values.subject_id}
                    />
                    <TextAreaFiled
                      sx={{ marginBottom: "31px" }}
                      name="description"
                      placeholder="Write your message..."
                      label="Your message"
                      data-test-id="message"
                      value={values.description}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isRequired
                      type="text"
                      error={isError(touched.description, errors.description)}
                      helperText={helperText(
                        touched.description,
                        errors.description
                      )}
                    />
                    <Button
                      className="contact-us-button"
                      fullWidth
                      type="submit"
                    >
                      Send
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </ContactUsContainer>
    );
  }
}

const validationContactUsSchema = Yup.object({
  name: Yup.string()
    .required("Full name is required")
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Full name can only contain letters")
    .test(
      "not-only-digits",
      "Full name cannot contain only digits",
      (value) => {
        return !/^\d+$/.test(value);
      }
    )
    .max(50, "Maximum 50 characters are allowed"),
  email: Yup.string()
    .email("Email should be in a valid email format (user@example.com)")
    .required("Email is required")
    .trim(),
  phoneId:Yup.string()
    .required('Country code is required'),
  phone_number: Yup.string()
    .required("Mobile number is required")
    .min(10, "Minimum 10 digits are required")
    .max(10, "Maximum 10 digits are allowed")
    .trim(),
  subject_id: Yup.string().required("Subject is required"),
  description: Yup.string()
    .required("Message is required")
    .max(500, "Maximum 500 characters are allowed")
    .trim(),
});

const ContactUsContainer = styled(Box)(({ theme }) => ({
  padding: "30px 0",
  "& .contact-us-description": {
    margin: "18px 0 60px",
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    color: "#57534E",
  },
  "& .contact-us-form-container": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "39px 78px 108px",
    borderRadius: "8px",
    maxWidth: "667px",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      padding: "39px 50px 80px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  "& .contact-us-image-container": {
    backgroundImage: `url(${contactUsBackground})`,
    padding: "52px",
    borderRadius: "8px",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "52px 30px",
    },
  },
  "& .contact-us-title": {
    fontFamily: "Poppins",
    fontSize: "32px",
    fontWeight: "500",
    lineHeight: "50px",
    color: "#1C1917",
    marginBottom: "42px",
    textAlign: "center",
  },
  "& .contact-us-button": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    color: "#FFFFFF",
    lineHeight: "24px",
    backgroundColor: "#801187 !important",
    textTransform: "none",
    borderRadius: "30px",
    padding: "15px",
  },
}));
// Customizable Area End
