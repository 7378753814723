import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/utils";
import { productDefaultImage } from "./assets";
import { toast } from "react-toastify";
import eventEmitter from "../../../components/src/EventEmitter";

// Customizable Area Start
export interface DropdownData {
  id: number;
  name: string;
  value?: string | number;
  description?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  showDress: boolean;
  occassionName: string;
  productType: string;
  selectedOcassion: number[];
  selectedPrice: number[];
  selectedAvailability: string;
  selectedCategory: number[];
  selectedRating: string;
  selectedCity: number[];
  selectedSortBy: string;
  isLoading: boolean;
  errorMessage: string;
  functionsData: DropdownData[];
  categories: DropdownData[];
  priceRangeData: DropdownData[];
  stockFilterData: DropdownData[];
  serviceLocationData: DropdownData[];
  sortingFilterData: DropdownData[];
  productsListing: Array<any>;
  pageDescription: string;
  currentPage: number;
  perPageLimit: number;
  totalPages: number;
  productCardLoader: boolean;
  clickedProductId: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShopByOccassionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getShopByCategoryDataId: string = "";
  getPriceFilterDataId: string = "";
  getServiceLocationDataId: string = "";
  getProductsListingDataId: string = "";
  addToCartId: string = "";
  addProductToWishlistApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      showDress: false,
      occassionName: "",
      productType: "",
      selectedOcassion: [],
      selectedPrice: [],
      selectedAvailability: "in_stock",
      selectedCategory: [],
      selectedRating: "",
      selectedCity: [],
      selectedSortBy: "",
      isLoading: true,
      errorMessage: '',
      functionsData: [],
      categories: [],
      priceRangeData: [],
      serviceLocationData: [],
      productsListing: [],
      sortingFilterData: [
        {
          id: 1,
          name: 'Price: Low to High',
          value: 'price_low_to_high',
        },
        {
          id: 2,
          name: 'Price: High to Low',
          value: 'price_high_to_low',
        },
        {
          id: 3,
          name: 'Rating: Low to High',
          value: 'rating_low_to_high',
        },
        {
          id: 4,
          name: 'Rating: High to Low',
          value: 'rating_high_to_low',
        },
      ],
      stockFilterData: [
        {
          id: 1,
          name: 'In Stock',
          value: 'in_stock',
        },
        {
          id: 2,
          name: 'Out of Stock',
          value: 'out_of_stock',
        },
      ],
      pageDescription: "",
      currentPage: 1,
      perPageLimit: 8,
      totalPages: 0,
      productCardLoader: false,
      clickedProductId: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (webApiRequestCallId) {
        case this.getShopByCategoryDataId:
          this.handleShopByCategoryDataResponse(webResponseJson);
          break;

        case this.getPriceFilterDataId:
          this.handlePriceFilterDataResponse(webResponseJson);
          break;

        case this.getServiceLocationDataId:
          this.handleServiceLocationDataResponse(webResponseJson);
          break;

        case this.getProductsListingDataId:
          this.handleProductsListingDataResponse(webResponseJson);
          break;

        case this.addToCartId:
          this.handelAddToCartIdResponse(webResponseJson);
          break;

        case this.addProductToWishlistApiId:
          this.handelAddToWishlistResponse(webResponseJson);
          break;

        default:
          break;
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    let occassionName = await this.props.navigation.getParam("name", "");
    let productType = await this.props.navigation.getParam("type", "");

    const token = await getStorageData("authToken");
    this.setState({ occassionName, productType, token });
    this.getShopByCategoryData();
    this.getPriceFilterData();
    this.getServiceLocationData();
  }

  handleAvailability = (event: any) => {
    this.setState({
      selectedAvailability: event.target.value as string
    }, () => {
      this.getProductsListingData();
    })
  }

  handleRating = (event: any) => {
    this.setState({ selectedRating: event.target.value as string })
  }

  handleSortBy = (event: any) => {
    this.setState({ selectedSortBy: event.target.value as string }, () => {
      this.getProductsListingData();
    })
  }

  getShopByCategoryData = async () => {
    this.setState({ isLoading: true });
    this.getShopByCategoryDataId = await apiCall({
      endPoint: configJSON.shopByCategoryEndpoint,
      method: configJSON.httpGetType,
      token: this.state.token
    });
  };

  handleShopByCategoryDataResponse = (response:
    {
      data:
      {
        attributes:
        {
          functions: Array<any>,
          categories: Array<any>,
        }
      },
      errors: any
    }) => {
    if (response?.data) {
      const data = response.data.attributes;

      const functions = data.functions;
      const functionsData = functions.map((item) => {
        return {
          id: item.attributes.id,
          name: item.attributes.name,
          description: item.attributes.description,
        }
      });

      const categories = data.categories.map((item) => {
        return {
          id: item.attributes.id,
          name: item.attributes.name,
          description: item.attributes.description,
        }
      });

      const { productType } = this.state;
      let pageDescription = '';
      let occassioId = 0;
      let categoryId = 0;
      if (productType === 'function') {
        pageDescription = functionsData.find((item) => item.name.toLocaleLowerCase() === this.state.occassionName.toLocaleLowerCase())?.description;
        occassioId = functionsData.find((item) => item.name.toLocaleLowerCase() === this.state.occassionName.toLocaleLowerCase())?.id;
      }

      if (productType === 'category') {
        pageDescription = categories.find((item) => item.name.toLocaleLowerCase() === this.state.occassionName.toLocaleLowerCase())?.description;
        categoryId = categories.find((item) => item.name.toLocaleLowerCase() === this.state.occassionName.toLocaleLowerCase())?.id;
      }

      this.setState({ functionsData, categories, pageDescription, selectedOcassion: [occassioId], selectedCategory: [categoryId] }, () => {
        this.getProductsListingData();
      });
    }
    if (response.errors) {
      this.setResponseErrorMsg(response.errors);
    }
  };

  getPriceFilterData = async () => {
    this.setState({ isLoading: true });
    this.getPriceFilterDataId = await apiCall({
      endPoint: configJSON.priceFilterDataEndpoint,
      method: configJSON.httpGetType,
      token: this.state.token,
    });
  };

  handlePriceFilterDataResponse = (response: any) => {
    if (response?.price_ranges) {
      const data = response.price_ranges;
      const priceRangeData = data.map((item: any) => {
        return {
          id: item.id,
          name: `₹ ${item.min_price} - ₹ ${item.max_price}`,
        }
      });
      this.setState({ priceRangeData });
    }
    if (response.errors) {
      this.setResponseErrorMsg(response.errors);
    }
  }

  getServiceLocationData = async () => {
    this.setState({ isLoading: true });
    this.getServiceLocationDataId = await apiCall({
      endPoint: configJSON.serviceLocationDataEndpoint,
      method: configJSON.httpGetType,
      token: this.state.token,
    });
  };

  handleServiceLocationDataResponse = (response: any) => {
    if (response?.data) {
      const data = response.data;
      const serviceLocationData = data.map((item: any) => {
        return {
          id: item.attributes.id,
          name: item.attributes.location_name,
        }
      });
      this.setState({ serviceLocationData });
    }
    if (response.errors) {
      this.setResponseErrorMsg(response.errors);
    }
  }

  getProductsListingData = async () => {
    const { selectedCategory, perPageLimit, currentPage, selectedAvailability, selectedPrice, selectedSortBy, selectedCity, productType, selectedOcassion } = this.state;
    let apiURL = `${configJSON.productsListingEndpoint}?type=${productType}&page=${currentPage}&per_page=${perPageLimit}&availability=${selectedAvailability}`;

    if (selectedCategory.length > 0 && selectedCategory[0] !== 0) {
      apiURL += `&category_id=${selectedCategory[0]}`;
    }

    if (selectedOcassion.length > 0 && selectedOcassion[0] !== 0) {
      apiURL += `&function_id=${selectedOcassion[0]}`;
    }

    if (selectedPrice.length > 0) {
      apiURL += `&price_range_ids=${selectedPrice}`;
    }

    if (selectedCity.length > 0) {
      apiURL += `&service_location_ids=${selectedCity}`;
    }

    if (selectedSortBy) {
      apiURL += `&sort_order=${selectedSortBy}`;
    }

    this.setState({ isLoading: true });
    this.getProductsListingDataId = await apiCall({
      endPoint: apiURL,
      method: configJSON.httpGetType,
      token: this.state.token,
    });
  };

  handleProductsListingDataResponse = (response: any) => {
    if (response?.data) {
      const data = response.data;
      const meta = response.meta;
      let productsListing = data.map((item: any) => {
        const product = item.attributes;
        return {
          id: product.id,
          image: product.media_files.length > 0 ? product.media_files[0].url : productDefaultImage,
          title: product.name,
          location: this.getServiceLocations(product.service_locations),
          price: `₹ ${product.price}`,
          rating: 1,
          ratingText: '4.5 (120 Ratings)',
          productVariants: product.product_variants,
        }
      });
      this.setState({ productsListing, totalPages: meta.total_pages });
    }
    if (response.errors) {
      this.setResponseErrorMsg(response.errors);
    }
    this.setState({ isLoading: false });
  }

  handleOccassionChange = (event: any, selectedValues: number[], name: string) => {
    const { functionsData, productType } = this.state;
    let selectedDDValues: number[] = [];
    const value = event.target.value;
    selectedDDValues.push(value);

    let pageDescription = '';
    let occassioName = '';
    if (productType === 'function') {
      pageDescription = functionsData.find((item) => item.id === value)?.description || '';
      occassioName = functionsData.find((item) => item.id === value)?.name || '';
      this.setState({
        occassionName: occassioName || '',
        pageDescription: pageDescription || '',
      });
    }

    this.setState({
      selectedOcassion: selectedDDValues,
      productsListing: [],
    }, () => {
      this.getProductsListingData();
    });
  };

  handleCategoryChange = (event: any, selectedValues: number[], name: string) => {
    const { categories, productType } = this.state;
    let selectedDDValues: number[] = [];
    const value = event.target.value;
    selectedDDValues.push(value);

    let pageDescription = '';
    let occassioName = '';
    if (productType === 'category') {
      pageDescription = categories.find((item) => item.id === value)?.description || '';
      occassioName = categories.find((item) => item.id === value)?.name || '';
      this.setState({
        occassionName: occassioName || '',
        pageDescription: pageDescription || '',
      });
    }

    this.setState({
      selectedCategory: selectedDDValues,
      productsListing: [],
    }, () => {
      this.getProductsListingData();
    });
  };

  handleMultiselectDropdownChange = (event: any, selectedValues: number[], name: string) => {
    let selectedDDValues: number[] = selectedValues;
    const value = event.target.value;

    if (selectedDDValues.length > 0 && selectedDDValues.includes(value)) {
      selectedDDValues = selectedDDValues.filter(id => id !== value);
    } else {
      selectedDDValues.push(value);
    }

    this.setState((prevState) => ({
      ...prevState,
      [name]: selectedDDValues,
    }), () => {
      this.getProductsListingData();
    });
  };

  checkSelectedValues = (id: number, values: number[]): boolean => {
    if (values.length > 0 && values.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  renderSelectedValues = (selectedValues: number[], dropdownData: Array<any>): string => {
    let renderString = '';
    if (selectedValues.length > 0) {
      renderString = dropdownData
        .filter(item => selectedValues.includes(item.id))
        .map(item => item.name)
        .join(", ");
    }
    return renderString;
  }

  renderStringValueDropdown = (value: string, dropdownData: DropdownData[]) => {
    return dropdownData.find((item) => item.value === value)?.name;
  }

  setResponseErrorMsg = (error: any) => {
    if (typeof error === "string") {
      this.setState({ errorMessage: error });
    } else {
      this.setState({ errorMessage: error[0].contact[0] });
    }
    this.setState({ isLoading: false, productsListing: [] });
  }

  handlePaginationChange = (event: any, value: number) => {
    this.setState({ currentPage: value }, () => {
      this.getProductsListingData();
    })
  }

  getServiceLocations = (locations: any): string => {
    let locationsText = '';
    if (locations.data && locations.data.length > 0) {
      locations.data.forEach((item: any) => {
        locationsText += item.attributes.location_name;
        if (locations.data.indexOf(item) != locations.data.length - 1) {
          locationsText += ', ';
        }
      })
    }
    return locationsText;
  }

  handleOpenProductDetails = (productId: number) => {
    this.props.navigation.navigate("ProductSummary", { id: productId });
  }

  handleAddToCart = async (event: any, productId: number) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    const token = await getStorageData("authToken");
    if (!token) {
      toast.error('Please login to add product to cart', { position: 'bottom-right' });
      return false;
    }

    const { productsListing } = this.state;
    const product = productsListing.find((item) => item.id == productId);
    const variant = product.productVariants[0];

    if (variant && variant.id) {
      this.addToCartApiCall(variant, productId);
    } else {
      toast.error('No product variant available', { position: 'bottom-right' });
    }
  }

  addToCartApiCall = async (variant: any, productId: number) => {
    const requestBody = {
      cart: {
        product_id: productId,
        product_size_id: parseInt(variant.attributes.product_size.id),
        product_color_id: parseInt(variant.attributes.product_color.id),
        quantity: 1
      }
    }
    this.setState({ clickedProductId: productId, productCardLoader: true });
    this.addToCartId = await apiCall({
      endPoint: configJSON.addToCartEndpoint,
      method: configJSON.httpPostType,
      contentType: 'application/json',
      body: JSON.stringify(requestBody),
    });
  }

  handelAddToCartIdResponse = (response: { data: any, error: string, errors: any[], message: string }) => {
    if (response?.data) {
      eventEmitter.emit('updateCartCounter', 1, []);
      toast.success('Product added to cart', { position: 'bottom-right' });
    }
    if (response?.message) {
      toast.warning(response.message, { position: 'bottom-right' });
    }
    if (response?.error) {
      toast.error(response.error, { position: 'bottom-right' });
    }
    if (response?.errors) {
      toast.error(response.errors[0]?.token || 'Failed to add product to cart', { position: 'bottom-right' });
    }
    this.setState({ clickedProductId: 0, productCardLoader: false });
  }

  handleAddToWishlist = async (event: any, productId: number) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    const token = await getStorageData("authToken");
    if (!token) {
      toast.error('Please login to add product to wishlist', { position: 'bottom-right' });
      return false;
    }
    const payload = {
      wishlist: { product_id: productId }
    };
    this.setState({ clickedProductId: productId, productCardLoader: true });
    this.addProductToWishlistApiId = await apiCall({
      contentType: 'application/json',
      method: configJSON.httpPostType,
      endPoint: configJSON.addToWishlistEndpoint,
      body: JSON.stringify(payload),
    });
  }

  handelAddToWishlistResponse = (responseJson: { data: any, error: string, errors: any[], message: string }) => {
    if (responseJson?.data) {
      toast.success('Product added to wishlist', { position: 'bottom-right' });
    }
    if (responseJson?.errors) {
      toast.error(responseJson.errors[0]?.token || 'Failed to add product to wishlist', { position: 'bottom-right' });
    }
    if (responseJson?.error) {
      toast.error(responseJson.error, { position: 'bottom-right' });
    }
    if (responseJson?.message) {
      toast.warning(responseJson.message, { position: 'bottom-right' });
    }
    this.setState({ productCardLoader: false });
  }
  // Customizable Area End
}
