import React from "react";
import { Box, Typography, styled } from "@mui/material";

interface IShoppingCardProps {
  image_url: string;
  description: string;
  title: string;
  onCardClick?: (id: number) => void;
  id?: number;
}

const ShoppingCard: React.FC<IShoppingCardProps> = ({
  image_url,
  description,
  title,
  onCardClick,
  id,
}) => {
  return (
    <ShoppingCardStyle onClick={() => onCardClick && id && onCardClick(id)}>
      <img src={image_url} className="shop-details-image" alt="shop" />
      <Typography className="shop-details-title">{title}</Typography>
      <Typography className="shop-details-description">
        {description}
      </Typography>
    </ShoppingCardStyle>
  );
};

export default ShoppingCard;

const ShoppingCardStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "fit-content",
  cursor: "pointer",
  "& .shop-details-image": {
    height: "184.01px",
    width: "184.01px",
    borderRadius: "50%",
    objectFit: "cover",
    objectPosition: "center",
  },
  "& .shop-details-title": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "18px",
    textAlign: "center",
    color: "#801187",
    margin: "18px 0 9px",
  },
  "& .shop-details-description": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    textAlign: "center",
    color: "#44403C",
    maxWidth: "276px",
    wordWrap:"break-word"
  },
}));
