import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/utils";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  viewAll: any;
  categoryId: string;
  isLoading: boolean;
  errorMessage: string;
  categoryTitle : string,
  services: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CategoriessubcategoriesVendorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVendorCategoryDataId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      errorMessage: '',
      categoryId: "",
      viewAll: false,
      services: [],
      categoryTitle : ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webApiRequestCallId === this.getVendorCategoryDataId) {
        this.handleVendorCategoryResponse(webResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getVendorCategoryData = async () => {
    this.setState({ isLoading: true });
    const token = await getStorageData("authToken");

    // Ensure the API call to fetch the vendor category data is working
    this.getVendorCategoryDataId = await apiCall({
      endPoint: configJSON.categoryEndpoint, // Ensure this endpoint is correct
      method: configJSON.httpGetType,
      token: token,
    });
  };

  handleVendorCategoryResponse = (webResponseJson:
    {
      data: {
        id: string,
        type: string,
        attributes: {
          id: number,
          name: string,
          image: {
            id: number,
            date: string,
            url: string,
            type: string
          }
        }
      };
      errors: any
    }) => {
    if (webResponseJson?.data) {
      this.setState({ services: webResponseJson.data })
      console.log(Array.isArray(webResponseJson.data), 'VC')
      console.log(this.state.services)
    }
    if (webResponseJson.errors) {
      const errorMessage = webResponseJson.errors[0].contact[0];
      this.setState({ errorMessage });
    }
    this.setState({ isLoading: false });
  };

  async componentDidMount(): Promise<void> {
    let categoryId = await getStorageData('categoryId')
    this.setState({ categoryId: categoryId });
    this.getVendorCategoryData();
  }
  viewAllHandler = () => {
    this.setState(prevState => ({
      viewAll: !prevState.viewAll
    }));
  }
  // Customizable Area End
}