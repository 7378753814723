import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { apiCall } from "../../../components/src/utils";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showDress: boolean;
  selectedOccasion: string;
  occassionId: string;
  selectedValue: string;
  selectedPrice: string;
  selectedAvailability: string;
  selectedCategory: string;
  selectedRating: string;
  selectedCity: string;
  selectedSortBy: string;
  isLoading: boolean;
  errorMessage: string;
  pageHeader: {
    title: string,
    subHeading: string,
    description: string,
    image: string,
  },
  functionsData: {
    id: number,
    name: string,
    image: string,
  }[];
  categories: {
    id: number,
    name: string,
    image: string,
  }[];
  discountBanner: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShopController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getShopByCategoryDataId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showDress: false,
      selectedOccasion: '',
      occassionId: "",
      selectedValue: "",
      selectedPrice: "",
      selectedAvailability: "",
      selectedCategory: "",
      selectedRating: "",
      selectedCity: "",
      selectedSortBy: "",
      isLoading: false,
      errorMessage: '',
      pageHeader: {
        title: '',
        subHeading: '',
        description: '',
        image: '',
      },
      functionsData: [],
      categories: [],
      discountBanner: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webApiRequestCallId === this.getShopByCategoryDataId) {
        this.handleShopByCategoryDataResponse(webResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    let occassionId = await getStorageData('occassionId')
    this.setState({ occassionId: occassionId });
    this.getShopByCategoryData();
  }

  showFunctionDress = (name: string) => {
    setStorageData("occassionId", name)
    this.props.navigation.navigate("ShopByOccassion", { type: 'function', name });
  }

  openCategoryProducts = (name: string) => {
    this.props.navigation.navigate("ShopByOccassion", { type: 'category', name });
  }

  handleOccassion = (event: any) => {
    this.setState({ selectedValue: event.target.value as string });
  };

  handlePrice = (event: any) => {
    this.setState({ selectedPrice: event.target.value as string });
  }

  handleAvailability = (event: any) => {
    this.setState({
      selectedAvailability: event.target.value as string
    })
  }

  handleCategory = (event: any) => {
    this.setState({ selectedCategory: event.target.value as string })
  }
  handleRating = (event: any) => {
    this.setState({ selectedRating: event.target.value as string })
  }

  handleCity = (event: any) => {
    this.setState({ selectedCity: event.target.value as string })
  }

  handleSortBy = (event: any) => {
    this.setState({ selectedSortBy: event.target.value as string })
  }

  getShopByCategoryData = async () => {
    this.setState({ isLoading: true });
    const token = await getStorageData("authToken");
    this.getShopByCategoryDataId = await apiCall({
      endPoint: configJSON.shopByCategoryEndpoint,
      method: configJSON.httpGetType,
      token: token
    });
  };

  handleShopByCategoryDataResponse = (webResponseJson:
    {
      data:
      {
        attributes:
        {
          page_title: string,
          mini_description: string,
          description: string,
          top_image: string,
          discount_banner: string,
          functions: Array<any>,
          categories: Array<any>,
        }
      },
      errors: any,
      error: string,
    }) => {
    if (webResponseJson?.data) {
      const data = webResponseJson.data.attributes;
      const pageHeader = {
        title: data.page_title,
        subHeading: data.mini_description,
        description: data.description,
        image: data.top_image,
      }

      const functions = data.functions;
      const functionsData = functions.map((item) => {
        return {
          id: item.attributes.id,
          name: item.attributes.name,
          image: item.attributes.function_image,
        }
      });

      const categories = data.categories.map((item) => {
        return {
          id: item.attributes.id,
          name: item.attributes.name,
          image: item.attributes.image,
        }
      });

      this.setState({ pageHeader, functionsData, categories, discountBanner: data.discount_banner });
    }
    if (webResponseJson.errors) {
      const errorMessage = webResponseJson.errors[0].contact[0];
      this.setState({
        errorMessage, pageHeader: {
          title: '',
          subHeading: '',
          description: '',
          image: '',
        }, functionsData: [], categories: [], discountBanner: ''
      });
    }
    if (webResponseJson?.error) {
      this.setState({
        pageHeader: {
          title: '',
          subHeading: '',
          description: '',
          image: '',
        }, functionsData: [], categories: [], discountBanner: ''
      });
    }
    this.setState({ isLoading: false });
  };
  // Customizable Area End
}
