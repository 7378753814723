import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import React from 'react'
import { toast } from "react-toastify";
export interface FormDataOption {
  reference_id: string;
  email: string;
  fullName: string;
  gstNumber:string;
  businessName:string;
  businessCategory:string;
  primaryContact:string;
  secondaryNumber:string;
  showPassword: boolean;
  pincode:string;
  phoneId:string;
  city:string;
  state:string;
  country:string;
  branchName:string;
  ifscCode:string;
  bankAccountDetails:string;
  paymentMethod:string;
  phoneIdNo:string;
  serviceProvided:string;
  serviceLocation:string;
  address:string;
  portfolioLink:string;
  primaryContactPersonName:string;
  description:string;
  selectedLocation:string,
  selectedService:string,
  addLink:Link[]
  productOffered:string;
  addProduct : Product[];
};
interface Link{
  link:string
}
interface Product{
  product:string
 }
interface Product{
  product:string
}
interface CountryData {
  label: string;
  value: number;
}
interface StateData {
  label: string;
  value: string;
}
export interface ColorOption {
  label: string;
  value: number;
}
interface IReference {
  id: number;
  attributes : {
    name: string;
  }
  created_at: string;
  updated_at: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  formData:FormDataOption;  
  isEditing:boolean;
  locationData:ColorOption[];
  serviceData:ColorOption[],
  selectedLocations: ColorOption[];
  selectedServices:ColorOption[]
  token:string;
  countryData:CountryData[];
  stateData:StateData[];
  cityData:StateData[];
  showName:string;
  uploadImage:File | null;
  phoneNumberData:Array<object>;
  searchTerm : string,

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateSettingApiCallId:string="";
  serviceLocationApiCallId:string="";
  countryDataApiCallId:string="";
  stateDataApiCallId:string="";
  cityDataApiCallId:string="";
  settingDataApiCallId:string=""
  serviceProvidedApicallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      formData: {
        email: "",
        fullName: "",
        businessCategory:"",
        serviceLocation:"",
        bankAccountDetails:"",
        branchName:"",
        gstNumber:"",
        ifscCode:"",
        businessName:"",
        paymentMethod:"",
        pincode:"",
        state:"",
        country:"",
        primaryContact:"",
        address:"",
        city:"",
        secondaryNumber:"",
        showPassword: false,
        reference_id: "",
        serviceProvided:"",
        productOffered:"",
        portfolioLink:"",
      primaryContactPersonName:"",
      phoneId:'',
      phoneIdNo:'',
      description:"",
      selectedLocation:"",
      selectedService:"",
      addLink:[{link:""}],
      addProduct:[{product:""}],
    },
      searchTerm : "",
      isEditing:false,
      locationData:[],
      serviceData:[],
      selectedLocations:[],
      selectedServices:[],
      token:"",
      countryData:[],
      stateData:[],
      cityData:[],
      showName:"",
      uploadImage: null,
      phoneNumberData :[]

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.serviceLocationApiCallId:
          const services = responseJson && responseJson.data && responseJson.data.map((location: any) => ({
            value: location && location.id,
            label: location && location.attributes && location.attributes.location_name
          }));
          this.setState({ locationData: services });
          break;
          case this.countryDataApiCallId:
            const mappedCountryData = responseJson && responseJson.data && responseJson.data.map((country: any) => ({
              value: country && country.country_code,
              label: country && country.country_full_name
            })) 
            const mappedCountryCode = responseJson && responseJson.data && responseJson.data.map((country: any) => ({
              value: country && `+${country.numeric_code}`,
              label: country && country.numeric_code
            }));
            this.setState({ countryData: mappedCountryData, phoneNumberData:mappedCountryCode });
            break;
            case this.stateDataApiCallId:
              console.log(responseJson, "STATE")
              let newMapState:Array<{value:string,label:string}>;              
              newMapState =  responseJson && responseJson.data && await responseJson.data.map((state: { state_code: string, state_name: string }) => ({
                value: state && state.state_code,
                label: state && state.state_name
              }));
              this.setState({ stateData: newMapState}, () =>{
              });
          break;
          case this.serviceProvidedApicallId:
            console.log(responseJson, "SERVICE")
            const mappedService = responseJson && responseJson.data && responseJson.data.map((service: any) => ({
              value: service && service.attributes && service.attributes.id,
              label: service && service.attributes && service.attributes.name
            }));
            this.setState({ serviceData: mappedService })
            break;
          case this.cityDataApiCallId:
            console.log(responseJson, "CITY")
          const mappedCityData = responseJson && responseJson.data && responseJson.data.map((city: any) => ({
            value: city && city.city_name,
            label: city && city.city_name
          }));
          this.setState({ cityData: mappedCityData });
          break;
          case this.settingDataApiCallId:
            console.log(responseJson, "SETTINGGGG")
          const attributes = responseJson && responseJson.data && responseJson.data.attributes
          const addProduct = await Promise.all(
            attributes.products_offered?.map(async (data:string) => ({
             product: data || '',
            })) || []
           );
          const addLink = await Promise.all(
            attributes.social_media_links?.map(async (link:string) => ({
              link: link || '',
            })) || []
          );

          const locationData = attributes && attributes.vendor_address ? attributes.vendor_address.attributes && attributes.vendor_address.attributes.service_locations?.map((location: any) => ({
            value:location && location.id,
            label: location && location.location_name
          })):null;
          const serviceData = attributes && attributes.service_provided ? attributes.service_provided.map((data : any) => ({
            value: data && data.attributes && data.attributes.id,
            label: data && data.attributes && data.attributes.name
          })):null
          this.setState((prevState:any) => {
            return {
              formData: {
                ...prevState.formData,
                email: attributes && attributes.email,
                fullName: "",
                businessCategory:"",
                serviceLocation:"",
                bankAccountDetails:attributes && attributes.account_number,
                branchName: attributes && attributes.branch_name,
                gstNumber:attributes && attributes.GST_number,
                ifscCode: attributes && attributes.ifsc_code,
                businessName:attributes && attributes.business_name,
                paymentMethod: attributes && attributes.bank_name,
                pincode: attributes && attributes.vendor_address ? attributes.vendor_address.attributes && attributes.vendor_address.attributes.pincode : null,
                state:attributes && attributes.vendor_address ? attributes.vendor_address.attributes && attributes.vendor_address.attributes.state :null,
                country:attributes && attributes.vendor_address ? attributes.vendor_address.attributes && attributes.vendor_address.attributes.country : null,
                primaryContact: attributes && attributes.primary_contact_phone_number,
                address: attributes && attributes.vendor_address ? attributes.vendor_address.attributes && attributes.vendor_address.attributes.address: null,
                city: attributes && attributes.vendor_address ? attributes.vendor_address.attributes && attributes.vendor_address.attributes.city: null,
                secondaryNumber:attributes && attributes.secondary_contact_phone_number,
                showPassword: false,
                reference_id: "",
                productOffered:"",
                portfolioLink: attributes && attributes.portfolio_link,
                primaryContactPersonName:attributes && attributes.full_name,
                phoneId: attributes && attributes.primary_contact_country_code,
                phoneIdNo: attributes && attributes.secondary_contact_country_code,
                description: attributes && attributes.business_detail_description,
                addLink,
                addProduct,
                      },
                    };
                  });
                  const uploadImageUrl = attributes && attributes.profile_picture
                  this.getAllStateApi(this.state.formData && this.state.formData.country)
                  this.getCityApiCall(this.state.formData && this.state.formData.country,this.state.formData && this.state.formData.state)
                  const uploadImageFile = await this.urlToFile(uploadImageUrl, "size_chart.png", "image/png");
                  this.setState({
                  showName : attributes && attributes.full_name,
                  uploadImage: uploadImageFile, 
                  selectedLocations: locationData, 
                  selectedServices :serviceData
                })
                  
                  break;
                  case this.updateSettingApiCallId:
                    if(responseJson && responseJson.errors){
                      toast.error(responseJson.errors[0])
                    }
                    else{
                      toast.success("Edit Business Setting Successfully");
                      this.setState({isEditing:false})
                    }
                    break;
                default:
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // Customizable Area Start

  componentDidMount = async () => {
    this.serviceLoationApi();
    this.getToken()
    this.getAllSettingData()
    this.getAllCountryApi();
    this.getServiceDataList()
  };
  urlToFile = async(url: RequestInfo, filename: string, mimeType: string) =>  {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], filename, { type: mimeType });
  }
  handleEdit = () => {
    this.setState({isEditing:true})
    this.getAllSettingData()
  }
  handleEditApiCall = async (values:FormDataOption) => {
    console.log(values, "VALUESSS")
let formData = new FormData();
  formData.append("full_name",values.primaryContactPersonName);
formData.append("business_name", values.businessName);
formData.append("GST_number", values.gstNumber);
formData.append("service_id", "1");
formData.append("primary_contact_no", `${values.phoneId}${values.primaryContact}`);
formData.append("secondary_contact_no", `${values.phoneIdNo}${values.secondaryNumber}`);
formData.append("email", values.email);
formData.append("address_params[address]", values.address);
formData.append("address_params[country]", values.country);
formData.append("address_params[state]", values.state);
formData.append("address_params[city]", values.city);
formData.append("address_params[pincode]", values.pincode);
formData.append("portfolio_link", values.portfolioLink);
formData.append("business_detail_description", values.description);
formData.append("bank_name", values.paymentMethod);
formData.append("account_number", values.bankAccountDetails);
formData.append("ifsc_code", values.ifscCode);
formData.append("branch_name", values.branchName);
if (this.state.uploadImage) {
  formData.append("profile_picture", this.state.uploadImage);
  }
if (Array.isArray(values.selectedLocation)) {
values.selectedLocation.forEach((spec: any) => {
formData.append("address_params[service_location_ids]", spec.value);
});
}
Array.isArray(values.selectedService) && values.selectedService.forEach((spec: any) => {
  formData.append("service_ids", spec.value);
});
  
values.addLink.forEach((item: Link) => {
formData.append(`social_media_links[]`, item.link);
});
values.addProduct.forEach((item: Product) => {
  formData.append(`products_offered[]`, item.product);
  });
  this.updateSettingApiCallId = await apiCall({
    endPoint: "account_block/accounts/update",
    method: "PATCH",
    body: formData,
  });
  }

handleDeleteLocationChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
  console.log(optionToDelete, "OOOOPPPTTION")
  const newSelectedLocation = this.state.selectedLocations.filter(
    (item) => item.value != optionToDelete.value
  );
  this.setState({ selectedLocations: newSelectedLocation });
  setFieldValue('selectedLocation', newSelectedLocation);
};
handleSelectLocationChange = (newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
  console.log(this.state.selectedLocations)
  console.log(newValue)
  this.setState({ selectedLocations: newValue });
  setFieldValue('selectedLocation', newValue);
};


toggleLocationOption = (optionToToggle: ColorOption) => {
  console.log(this.state.selectedLocations, "SEESESESELECTED")
  console.log(optionToToggle)
  this.setState((prevState) => {
    const { selectedLocations } = prevState;
    const isSelected = Array.isArray(selectedLocations) && selectedLocations.some(option => option && option.value == optionToToggle?.value);
    if (isSelected) {
      return {
        selectedLocations: selectedLocations.filter(option => option && option.value != optionToToggle?.value),
      };
    } else {
      return {
        selectedLocations: Array.isArray(selectedLocations) ? [...selectedLocations, optionToToggle] : [optionToToggle]};
    }
  });
};
handleLocationOption = (option: ColorOption,isSelected:any,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
  console.log(option)
  console.log(this.state.selectedLocations)
  const currentSelectedLocations = Array.isArray(this.state.selectedLocations)
    ? this.state.selectedLocations
    : [];
  const newSelectedLocation = isSelected
              ? currentSelectedLocations.filter(
                  (selected) => selected.value != option.value
                )
              : [...currentSelectedLocations, option];

            this.setState({ selectedLocations: newSelectedLocation });
            setFieldValue('selectedLocation', newSelectedLocation);
}

getToken = async () => {
  const token = await getStorageData("authToken");
  if (token) {
    this.setState({
      token,
    });
  } else {
    this.props.navigation.goBack();
  }
};


handleDeleteServiceChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
console.log(optionToDelete)
  const newSelectedService = this.state.selectedServices.filter(
  (item) => item.value !== optionToDelete.value
);
this.setState({ selectedServices: newSelectedService });
setFieldValue('selectedService', newSelectedService);
};

toggleServiceOption = (optionToToggle: ColorOption) => {
  console.log(optionToToggle)
this.setState((prevState) => {
  const { selectedServices } = prevState;
  const isSelected = Array.isArray(selectedServices) && selectedServices.some(option => option?.value === optionToToggle?.value);
  if (isSelected) {
    return {
      selectedServices: selectedServices.filter(option => option?.value !== optionToToggle?.value),
    };
  } else {
    return {
      selectedServices: Array.isArray(selectedServices) ? [...selectedServices, optionToToggle] : [optionToToggle]};
  }
});
};

handleSelectServiceChange = (newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
  console.log(newValue)
  this.setState({ selectedServices: newValue });
  setFieldValue('selectedService', newValue);
};

handleServiceOption = (option: ColorOption,isSelected:any,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
console.log(option)
console.log(isSelected)
const currentSelectedServices = Array.isArray(this.state.selectedServices)
  ? this.state.selectedServices
  : [];

const newSelectedService = isSelected
            ? currentSelectedServices.filter(
                (selected) => selected.value !== option.value
              )
            : [...currentSelectedServices, option];

          this.setState({ selectedServices: newSelectedService });
          setFieldValue('selectedService', newSelectedService);
}
getServiceDataList = async () => {
  this.serviceProvidedApicallId = await apiCall({
    endPoint: "account_block/services",
    method: "GET",
    contentType: "application/json",
  });
};
serviceLoationApi = async() => {
  this.serviceLocationApiCallId = await apiCall({
    endPoint: "bx_block_multipageforms2/service_locations",
    method: "GET",
    token: this.state.token,
  });
}
removeLink = (indexToRemove:number, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, values:FormDataOption) => {
  const updatedLinks = values.addLink.filter((_, index) => index !== indexToRemove);
  setFieldValue("addLink", updatedLinks);
};

getAllCountryApi = async () => {
  this.countryDataApiCallId = await apiCall({
    endPoint: "account_block/accounts/country_code_and_flags/fetch_countries",
    method: "GET",
    token: this.state.token,
  });
}
getAllStateApi = async (selectedCountry:string) => {
  this.stateDataApiCallId = await apiCall({
    endPoint: `account_block/accounts/country_code_and_flags/fetch_states?country_code=${selectedCountry}`,
    method: "GET",
    token: this.state.token,
  });
}


getAllSettingData = async() => {
  const token = await getStorageData("authToken");
  this.settingDataApiCallId = await apiCall({
    endPoint: "account_block/accounts/id",
    method: "GET",
    token: token,
  });
}
getCityApiCall = async (selectedCountry:string,selectedCity:string) => {
  this.cityDataApiCallId = await apiCall({
    endPoint: `account_block/accounts/country_code_and_flags/fetch_cities?country_code=${selectedCountry}&state_code=${selectedCity}`,
    method: "GET",
    token: this.state.token,
  });
}

removeproduct = (indexToRemove:number, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, values:FormDataOption) => {
  const updatedLinks = values.addProduct.filter((_, index) => index !== indexToRemove);
  setFieldValue("addProduct", updatedLinks);
 
 };
 handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];
  if(file){
      this.setState({uploadImage: file});
  }
};
  handleSearckKeyDown = (e: any) => {
    if ( e.key === "Backspace") {
      this.setState({ searchTerm: this.state.searchTerm.slice(0, -1) });
    }
     else {
      this.setState({ searchTerm: this.state.searchTerm + e.key });
    }
  };  
    //Customizable Area End
 }