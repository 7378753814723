import React from "react";

// Customizable Area Start
import { Box, Button, styled, Typography } from "@mui/material";

import { happi_ffie_image } from "./assets";
import LoginSideImageWrapper from "../../../components/src/LoginSideImageWrapper.web";
// Customizable Area End

import ShowVerificationController, {
  Props,
} from "./ShowVerificationController";

export default class ShowVerification extends ShowVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginSideImageWrapper isLoading={this.state.isLoading} carouselImages={this.state.listCarousel}>
        <ScrollableContentBox data-test-id="ScrollableContentBox">
          <Box className="sign-up-form-container">
            <img
              src={happi_ffie_image}
              alt="wedding"
              className="sign-up-main-image"
            />
            <Typography className="verify-email-text">
              Verify your email address
            </Typography>
            <Typography className="verify-email-description">
              You’ve entered{" "}
              <Typography component="span">{this.state.email}</Typography> as
              the email address for your account. Please verify the email
              address by clicking button below
            </Typography>
            <SignUpButton
              data-test-id="verify-email"
              fullWidth
              onClick={this.sendOtpInEmail}
            >
              Verify your email
            </SignUpButton>
          </Box>
        </ScrollableContentBox>
      </LoginSideImageWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ScrollableContentBox = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  width: "50%",
  height: "100%",
  boxSizing: "border-box",
  padding: "98px 98px 40px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    minHeight: "fit-content",
    height: "auto",
    overflowY: "unset",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "60px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "30px",
  },
  "& .sign-up-form-container": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      maxWidth: "600px",
    },
  },
  "& .sign-up-main-image": {
    height: "237px",
    width: "428px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "unset",
    },
  },
  "& .verify-email-text": {
    margin: "40px 0 24px",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "600",
    color: "#000000",
    lineHeight: "30px",
    textAlign: "center",
  },
  "& .verify-email-description": {
    fontWeight: "400",
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#000000",
    lineHeight: "27px",
    textAlign: "center",
    "& span": {
      fontWeight: "600",
      fontFamily: "Poppins",
      fontSize: "18px",
      color: "#000000",
      lineHeight: "27px",
    },
  },
}));

const SignUpButton = styled(Button)({
  marginTop: "81px",
  padding: "19px 20px",
  border: `1px solid #801187`,
  fontFamily: "Poppins",
  textTransform: "unset",
  fontSize: "16px",
  fontWeight: "400",
  backgroundColor: "#801187",
  lineHeight: "24px",
  color: "#FFFFFF",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#801187",
  },
});
// Customizable Area End
