import React from "react";

// Customizable Area Start
import { Box, Typography, styled } from "@mui/material";

import { WeddingLogo, thumbLogo } from "./assets";

import LoginSideImageWrapper from "../../../components/src/LoginSideImageWrapper.web";

// Customizable Area End

import PasswordConfirmationController, {
  Props,
} from "./PasswordConfirmationController";

export default class PasswordConfirmation extends PasswordConfirmationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginSideImageWrapper isLoading={this.state.isLoading} carouselImages={this.state.datacarousel}>
        <ScrollableContentBox data-test-id="ScrollableContentBox">
          <Box className="sign-up-form-container">
            <Box className="reset-password-confirmation-container">
              <img
                src={WeddingLogo}
                className="wedding-logo"
                alt="Wedding Logo"
              />
              <Typography className="sign-up-form-container-text-1">
                Reset password Confirmation
              </Typography>
              <Typography className="sign-up-form-container-text-2">
                Your password has been successfully reset
              </Typography>
              <Box className="success-image-text-container">
                <img src={thumbLogo} alt="thumb" className="thumb-icon" />
                <Typography className="success-text">Successfully</Typography>
                <Typography className="reset-password-text">
                  You have successfully reset your password for your account
                </Typography>
                <Typography className="login-main-text">
                  Return to{" "}
                  <Typography
                    component="span"
                    className="login-text"
                    data-test-id="login"
                    onClick={() => this.goToLogin()}
                  >
                    Log in
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
        </ScrollableContentBox>
      </LoginSideImageWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ScrollableContentBox = styled(Box)(({ theme }) => ({
  padding: "30px 72.32px 30px 72.32px",
  width: "50%",
  boxSizing: "border-box",
  height: "100%",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    height: "100%",
    minHeight: "fit-content",
    overflowY: "unset",
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "30px 30px 68px",
  },
  "& .sign-up-form-container-text-2": {
    lineHeight: "24px",
    fontWeight: "400",
    fontFamily: "Poppins",
    color: "#78716C",
    marginBottom: "95px",
    fontSize: "16px",
  },
  "& .sign-up-form-container-text-1": {
    color: "#000000",
    marginBottom: "6px",
    lineHeight: "24px",
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "22px",
  },
  "& .sign-up-form-container": {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
  },
  "& .wedding-logo": {
    width: "120.62px",
    height: "60px",
    marginBottom: "24px",
    aspectRation: "1",
  },
  "& .login-main-text": {
    color: "#44403C",
    lineHeight: "24px",
    textAlign: "center",
    marginTop: "40px",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Poppins",
  },
  "& .login-text": {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#7F1287",
    fontWeight: "500",
    lineHeight: "24px",
    cursor: "pointer",
  },
  "& .success-image-text-container": {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .thumb-icon": {
    height: "90px",
    marginBottom: "33px",
    width: "90px",
  },
  "& .success-text": {
    fontFamily: "Poppins",
    marginBottom: "24px",
    fontSize: "36px",
    fontWeight: "400",
    lineHeight: "30px",
    textAlign: "center",
    color: "#7F1287",
  },
  "& .reset-password-text": {
    maxWidth: "380px",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "27px",
    textAlign: "center",
    color: "#000000",
  },
  "& .reset-password-confirmation-container": {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "600px",
    },
  },
}));

// Customizable Area End
