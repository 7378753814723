import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { clearStorageData, redirectToPage,  } from "../../../components/src/utils";
import { setStorageData } from "../../../framework/src/Utilities";

interface CrouselData {
  id: number;
  attributes: {
    id: number;
    img_vid_url: {
      type: string;
      url:string;      
      id: 23, 
      
    }
}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formData: {
    [x: string]: string;
    phoneNumber: string;
  };
  isLoading: boolean;
  invalidPhoneNumber: boolean;
  phoneNumberData:Array<object>;
  phoneId:string
  dataCarouselList:CrouselData[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorPhoneLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginEmailApiCallId: string = "";
  apiGetCarouselListCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      formData: {
        phoneNumber: "",
      },
      isLoading: false,
      invalidPhoneNumber: false,
      phoneNumberData:[{label:"a", value:"+91"},{label:"b", value:"+01"},{label:"c", value:"+90"}],
      phoneId:"",
      dataCarouselList:[] 
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.getCarousel(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this.apiGetCarousel()
    await clearStorageData();
  };
  changePhoneValid = () => {
    this.setState({ invalidPhoneNumber: false });
  };
  goToEnailLogin = () => {
    const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }
  
  getCarousel= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetCarouselListCallId &&
			this.apiGetCarouselListCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson.data){
        this.setState({dataCarouselList:responseJson.data})
			}
		}
  }


  redirectFromVendorLoginToHomePage = () => {
    redirectToPage(this.props.navigation, "LandingPageWeb");
  };

  goToOtpPage = (values:{phoneNumber:string}) => {
    setStorageData("phoneLogin", values.phoneNumber)
    const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorOTPLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }

  apiGetCarousel = async () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const body =null
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.carouselGetApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );this.apiGetCarouselListCallId = requestMessage.messageId;
    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
