import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}
interface IInspirationDetailData {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    inspiration_detail_id:number;
    gallery: [
      {
          id: number,
          date: string,
          url: string,
          type: string
      }
  ]
  };
}
interface IInspirationDetailBrowseData{
  id: string;
  type: string;
  attributes: {
    id: number;
    heading: string;
    description: string;
    inspiration_detail_id:number;
    image:
      {
          id: number,
          date: string,
          url: string,
          type: string
      }
  };
}
interface IInspirationDetailPhotoData {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    inspiration_detail_id:number;
    photo: 
      {
          id: number,
          date: string,
          url: string,
          type: string
      }
  };
}
interface S {
  // Customizable Area Start
  isLoading: boolean;
  id: number;
  inspirationDetailsData:IInspirationDetailData;
  inspirationDetailsPhotosData:IInspirationDetailPhotoData;
  inspirationDetailData: IInspirationDetailBrowseData[];

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class InspirationsItemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInspirationDetailsCallId:string = "";
  getInspirationDetailsPhotoCallId:string= "";
  inspirationApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      id: 0,
      inspirationDetailsData:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          title: "",
          description: "",
          inspiration_detail_id:0,
          gallery: [
            {
              id: 0,
              date: "",
              url: "",
              type: ""
          }
          ]
        }
      },
      inspirationDetailsPhotosData:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          title: "",
          description: "",
    inspiration_detail_id:0,
          photo: 
            {
              id: 0,
              date: "",
              url: "",
              type: ""
          }
          
        }
      },
      inspirationDetailData:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.getInspirationDetailsCallId:
          const inspirationId= await getStorageData('inspirationId')
          const inspirationDetailsDatas= webResponseJson.data.filter((data:IInspirationDetailData) => {
            return data.attributes.inspiration_detail_id == inspirationId;
          })
          this.setState({
            isLoading: false,
            inspirationDetailsData: inspirationDetailsDatas[0],
          });
          break;
          case this.getInspirationDetailsPhotoCallId:
            const inspirationPhotoId= await getStorageData('inspirationId')
            const inspirationDetailsPhotosDatas= webResponseJson.data.filter((data:IInspirationDetailPhotoData) => {
            return data.attributes.inspiration_detail_id == inspirationPhotoId;
          })
          this.setState({
            isLoading: false,
            inspirationDetailsPhotosData: inspirationDetailsPhotosDatas[0],
          });
          break;
          case this.inspirationApiCallId:
            const inspirationBrowse= await getStorageData('inspirationId')
            const inspirationDetailsDetailsDatas= webResponseJson.data.filter((data:IInspirationDetailPhotoData) => {
            return inspirationBrowse !== data.id;
          })
          this.setState({
            inspirationDetailData: inspirationDetailsDetailsDatas,
            isLoading: false,
          });
          break;
        default:
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  redirectToInspirationItemsPage = (id: number) => {
    setStorageData("inspirationId", id)
    this.props.navigation.navigate("InspirationsItem", { id });
    this.getInspirationDetails();
    this.getInspirationDetailsPhoto()
    this.getInspirationDetailData()
  };
  async componentDidMount() {
    this.getInspirationDetails();
    this.getInspirationDetailsPhoto()
    this.getInspirationDetailData()
  }
  getInspirationDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.getInspirationDetailsCallId = await apiCall({
      endPoint: "bx_block_content_management/inspiration_detail_galleries",
      method: "GET",
    });
  };
  getInspirationDetailsPhoto = async () => {
    this.setState({
      isLoading: true,
    });
    this.getInspirationDetailsPhotoCallId = await apiCall({
      endPoint: "bx_block_content_management/inspiration_detail_photos",
      method: "GET",
    });
  };
  getInspirationDetailData = async () => {
    this.setState({
      isLoading: true,
    });
    this.inspirationApiCallId = await apiCall({
      endPoint: "bx_block_content_management/inspiration_details",
      method: "GET",
    });
  };
  // Customizable Area End
}
