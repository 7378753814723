export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const LoginImage_L = require("../assets/LoginImage_.jpg");
export const WeddingLogo = require("../assets/image_happifielogo.svg");
export const googlelogo = require("../assets/Google.png");
export const happi_ffie_image = require("../assets/happyffie.png");
export const hidePassword = require("../assets/hide_password.svg");
export const showPassword = require("../assets/show_password.svg");
export const uncheckImage = require("../assets/unchecked_checkbox.svg");
export const checkImage = require("../assets/checked_checkbox.svg");
export const wedding_logo_2 = require("../assets/image_happifielogo.png");
