Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.shopByCategoryEndpoint = "bx_block_categories/categories/shop_by_category";
exports.getProductByIdEndpoint = "bx_block_multipageforms2/products";
exports.addToCartEndpoint = "bx_block_shopping_cart/orders";
exports.addToWishlistEndpoint = "bx_block_wishlist/wishlists";
exports.categoryEndpoint ="account_block/services"
exports.vendorsEndpoint = "bx_block_categories/vendor_services"
// Customizable Area End