// Customizable Area Start
import React, { Component } from "react";
import { Box, Typography, styled, SxProps, Button, Theme } from "@mui/material";

import ShoppingCard from "../../../../components/src/ShoppingCard.web";
import MainTitle from "./MainTitle.web";
import {
  rightArrowButton,
  shopImag1,
  shopImag2,
  shopImag3,
  shopImag4,
} from "../assets";

interface Props {
  title: string;
  description: string;
  sx?: SxProps<Theme>;
  buttonText: string;
}

interface State {}

export default class ShopSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { props } = this;
    return (
      <ShopContainer sx={props.sx} component="section" id="shop">
        <MainTitle title={props.title} />
        <Typography className="shop-description">
          {props.description}
        </Typography>
        <Box className="shop-container">
          <ShoppingCard
            image_url={shopImag1}
            title="Bangles"
            description="Step into a world of elegance and romance at our breathtaking wedding venues."
          />
          <ShoppingCard
            image_url={shopImag2}
            title="Varmala"
            description="Step into a world of elegance and romance at our breathtaking wedding venues."
          />
          <ShoppingCard
            image_url={shopImag3}
            title="Lehenga"
            description="Your wedding day is one of the most significant and memorable days of your life"
          />
          <ShoppingCard
            image_url={shopImag4}
            title="Jewelry"
            description="A destination wedding offers a unique and magical experience that combines your special day"
          />
        </Box>
        <Box className="shop-button-container">
          <MoreItemsButton>
            {props.buttonText}
            <img alt="right" src={rightArrowButton} />
          </MoreItemsButton>
        </Box>
      </ShopContainer>
    );
  }
}

const ShopContainer = styled(Box)(({ theme }) => ({
  padding: "30px 0",
  "& .shop-description": {
    margin: "18px 0 60px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#57534E",
  },
  "& .shop-container": {
    display: "flex",
    justifyContent: "center",
    padding: "0 0px 28px",
    flexWrap: "wrap",
    gap: "43px",
  },
  "& .shop-button-container": {
    textAlign: "center",
  },
}));

const MoreItemsButton = styled(Button)({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "24px",
  textAlign: "left",
  color: "#7F1287",
  textTransform: "none",
  border: "1px solid #7F1287",
  borderRadius: "30px",
  padding: "9px 11px",
  gap: "8px",
  "& img": {
    height: "24px",
    width: "24px",
  },
});
// Customizable Area End
