//@ts-nocheck
import React from "react";

// Customizable Area Start
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
 
export interface Item {
  attributes: {
    availability: number;
    breadth: number;
    description: string;
    discount: string;
    height: number;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
    length: number;
    manufacture_date: string;
    name: string;
    on_sale: true;
    price: number;
    recommended: false;
    sale_price: string;
    sku: string;
    stock_qty: number;
    weight: string;
  };
  id: string;
  type: string;
}
// Customizable Area End

import TrendingController, {
  Props,
  configJSON,
} from "./TrendingController.web";

 class Trending extends TrendingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  trending = () => {
    return (
      <Grid container item spacing={4} xs={12} direction="row" alignItems="center">
        {this.state.list.map((item: Item, index) => {
          return (
            <Grid id="p_list_trending" item xs={12} md={6} lg={4} key={index}>
              <Card
                id={"cardPress"}
             onClick={()=>this.moveToProductDetails(item)}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="365"
                    image={item.attributes.images[0].url}
                    style={{ objectFit: "contain" }}
                  />
                  <CardContent>
                    <Typography variant="body2" component="p">
                      {item.attributes.description}
                    </Typography>

                    <Typography variant="h6" color="primary">
                      {item.attributes.price}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box
        display="flex"
        id="main_box"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
      >
        {this.state.loading ? (
          <CircularProgress />
        ) : (
          <Grid
            container
            spacing={4}
            item
            xs={12}
            direction="row"
            alignItems="center"
            style={{ marginTop: "20px" }}
          >
            <Typography variant="h4" component="h1">
              {configJSON.trendingList}
            </Typography>
            {this.trending()}
          </Grid>
        )}
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default Trending
// Customizable Area End
