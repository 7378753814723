// Customizable Area Start
import React, { Component } from "react";
import { Box, Typography, styled } from "@mui/material";

import MainTitle from "./MainTitle.web";
import { IHowItWorks } from "../LandingPageController";

interface Props {
  howItWorkData: IHowItWorks[];
}

interface State {}

export default class HowItWorkSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { howItWorkData } = this.props;
    return (
      <HowItWorkContainer component="section" id="how-it-work">
        <MainTitle data-test-id="main-title" title="How it works" />
        <Box className="how-it-work-items-container">
          {howItWorkData.map((data) => (
            <Box className="how-it-work-item-container" key={data.id}>
              <Box className="how-it-work-circle-border">
                <Box className="how-it-work-circle">
                  <img
                    src={data.attributes.image.url}
                    alt={data.attributes.heading}
                    className="how-it-work"
                  />
                </Box>
              </Box>
              <Typography
                className="how-it-work-title"
                title={data.attributes.heading}
              >
                {data.attributes.heading}
              </Typography>
              <Typography
                className="how-it-work-description"
                title={data.attributes.description}
              >
                {data.attributes.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </HowItWorkContainer>
    );
  }
}

const HowItWorkContainer = styled(Box)({
  padding: "30px 0",
  "& .how-it-work": {
    height: "61px",
    width: "61px",
    objectFit: "cover",
  },
  "& .how-it-work-circle": {
    width: "184.01px",
    height: "184.01px",
    backgroundColor: "#80118726",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .how-it-work-circle-border": {
    border: "1px solid #8011878A",
    padding: "8.49px",
    width: "fit-content",
    height: "fit-content",
    borderRadius: "50%",
  },
  "& .how-it-work-title": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "18px",
    textAlign: "center",
    marginTop: "10px",
    color: "#801187",
    maxWidth: "246px",
  },
  "& .how-it-work-description": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "18px",
    textAlign: "center",
    color: "#57534E",
    marginTop: "9px",
    maxWidth: "294px",
  },
  "& .how-it-work-item-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "fit-content",
  },
  "& .how-it-work-items-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "39px",
    marginTop: "40px",
  },
});
// Customizable Area End
