import React from "react";

// Customizable Area Start
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import { Box, Typography, Select, MenuItem, styled, CardActionArea, CardContent, CardActions, Card, Rating, Radio, Button, Checkbox, Grid, Skeleton, Pagination, CircularProgress } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

// Customizable Area End

import ShopByOccassionController, { Props } from "./ShopByOccassionController.web";
import { color } from "react-native-reanimated";

export default class ShopByOccassion extends ShopByOccassionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {
            functionsData,
            categories,
            priceRangeData,
            stockFilterData,
            selectedOcassion,
            selectedCategory,
            selectedPrice,
            selectedAvailability,
            serviceLocationData,
            selectedCity,
            sortingFilterData,
            selectedSortBy,
            productsListing,
            occassionName,
            pageDescription,
            isLoading,
            errorMessage,
            currentPage,
            totalPages,
            productCardLoader,
            clickedProductId,
        } = this.state;
        return (
            // Customizable Area Start
            <CustomFunctionBox>
                <HeaderFooterLayout navigation={this.props.navigation}>
                    <Box data-test-id='OccassionContainer' sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Select
                            className="multiSelectDropdown"
                            labelId="time-period-select"
                            value={selectedOcassion}
                            onChange={(event: any) => this.handleOccassionChange(event, selectedOcassion, 'selectedOcassion')}
                            data-test-id="occassion"
                            displayEmpty
                            sx={{
                                marginRight: '16px',
                                border: "1px solid white",
                                '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                            }}
                            renderValue={() => this.renderSelectedValues(selectedOcassion, functionsData) || 'Occasion'}
                        >
                            {functionsData.map((item) => (
                                <MenuItem value={item.id} style={{ textTransform: 'capitalize' }}>
                                    <CustomRadio checked={this.checkSelectedValues(item.id, selectedOcassion)} />
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <Select
                            className="multiSelectDropdown"
                            labelId="price-select"
                            value={selectedPrice}
                            onChange={(event: any) => this.handleMultiselectDropdownChange(event, selectedPrice, 'selectedPrice')}
                            data-test-id="price"
                            displayEmpty
                            renderValue={() => this.renderSelectedValues(selectedPrice, priceRangeData) || 'Price'}
                            sx={{
                                marginRight: '16px',
                                border: "1px solid white",
                                '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                            }}
                        >
                            {priceRangeData.map((item) => (
                                <MenuItem value={item.id} style={{ textTransform: 'capitalize' }}>
                                    <CustomCheckBox checked={this.checkSelectedValues(item.id, selectedPrice)} />
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <Select
                            className="multiSelectDropdown"
                            labelId="category-select"
                            displayEmpty
                            value={selectedCategory}
                            data-test-id="category"
                            onChange={(event: any) => this.handleCategoryChange(event, selectedCategory, 'selectedCategory')}
                            renderValue={() => this.renderSelectedValues(selectedCategory, categories) || 'Category'}
                            sx={{
                                marginRight: '16px',
                                border: "1px solid white",
                                '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                            }}
                        >
                            {categories.map((item) => (
                                <MenuItem value={item.id} style={{ textTransform: 'capitalize' }}>
                                    <CustomRadio checked={this.checkSelectedValues(item.id, selectedCategory)} />
                                    {item.name}
                                </MenuItem>
                            ))}

                        </Select>

                        <Select
                            labelId="availability-select"
                            value={this.state.selectedAvailability || ""}
                            onChange={this.handleAvailability}
                            data-test-id="availability"
                            displayEmpty
                            sx={{
                                marginRight: '16px',
                                border: "1px solid white",
                                '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                            }}
                            renderValue={(selected) => this.renderStringValueDropdown(selected, stockFilterData) || 'Availability'}
                        >
                            {stockFilterData.map((item) => (
                                <MenuItem value={item.value} style={{ textTransform: 'capitalize' }}>
                                    <CustomRadio checked={selectedAvailability === item.value} />
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>

                        <Select
                            className="ratingsSelect"
                            labelId="ratings-select"
                            displayEmpty
                            value={this.state.selectedRating || ""}
                            data-test-id="rating"
                            onChange={this.handleRating}
                            renderValue={(selected) => selected || 'Rating'}
                            sx={{
                                marginRight: '16px',
                                border: "1px solid white",
                                '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                            }}
                        >
                            <MenuItem value="1 Star">
                                <CustomCheckBox checked={this.state.selectedRating === '1 Star'} />
                                1 Star
                            </MenuItem>
                            <MenuItem value="2 Stars">
                                <CustomCheckBox checked={this.state.selectedRating === '2 Stars'} />
                                2 Stars
                            </MenuItem>
                            <MenuItem value="3 Stars">
                                <CustomCheckBox checked={this.state.selectedRating === '3 Stars'} />
                                3 Stars
                            </MenuItem>
                            <MenuItem value="4 Stars">
                                <CustomCheckBox checked={this.state.selectedRating === '4 Stars'} />
                                4 Stars
                            </MenuItem>
                            <MenuItem value="5 Stars">
                                <CustomCheckBox checked={this.state.selectedRating === '5 Stars'} />
                                5 Stars
                            </MenuItem>
                        </Select>

                        <Select
                            className="multiSelectDropdown"
                            labelId="city-select"
                            displayEmpty
                            value={selectedCity}
                            data-test-id="city"
                            renderValue={() => this.renderSelectedValues(selectedCity, serviceLocationData) || 'City'}
                            onChange={(event: any) => this.handleMultiselectDropdownChange(event, selectedCity, 'selectedCity')}
                            sx={{
                                marginRight: '16px',
                                border: "1px solid white",
                                '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                            }}
                        >
                            {serviceLocationData.map((item) => (
                                <MenuItem value={item.id} style={{ textTransform: 'capitalize' }}>
                                    <CustomCheckBox checked={this.checkSelectedValues(item.id, selectedCity)} />
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Select
                            className="sortBySelect"
                            data-test-id='sortBy'
                            labelId="sort-by-select"
                            displayEmpty
                            value={selectedSortBy}
                            renderValue={(selected) => this.renderStringValueDropdown(selected, sortingFilterData) || 'Sort by'}
                            onChange={this.handleSortBy}
                            sx={{
                                marginRight: '16px', border: "1px solid white",
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: "none"
                                },

                            }}
                        >
                            {sortingFilterData.map((item) => (
                                <MenuItem value={item.value} style={{ textTransform: 'capitalize' }}>
                                    <Radio checked={selectedSortBy === item.value} />
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box className='occassionTextbox'>
                        <Typography className='occassionText'>{occassionName}</Typography>
                    </Box>
                    <Box className='occassionDescriptionBox'>
                        <Typography className='occassionDescriptionText'>
                            {pageDescription}
                        </Typography>
                    </Box>
                    <Grid container spacing={3} padding={"0px 100px 0px 100px"}>
                        {!isLoading && productsListing.map((product, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ flexGrow: 1, borderRadius: '12px', overflow: 'hidden', position: 'relative' }}>
                                <Card key={index} className="productCard" sx={{ boxShadow: 3 }} data-test-id={`productCard${index}`} onClick={() => this.handleOpenProductDetails(product.id)}>
                                    {clickedProductId === product.id && productCardLoader &&
                                        <Box className="productCardLoader">
                                            <CircularProgress value={10} size={60} />
                                        </Box>
                                    }
                                    <Box data-test-id={`addToWishlistButton${index}`} onClick={(event) => this.handleAddToWishlist(event, product.id)} className="likeIconBox">
                                        <FavoriteBorderIcon sx={{ color: 'white' }} />
                                    </Box>
                                    <Box className="overlayButton">
                                        <Button data-test-id={`addToCartButton${index}`} onClick={(event) => this.handleAddToCart(event, product.id)} className='addToCartBtn' variant="contained" style={{ background: "#7F1187", textTransform: "none" }}>
                                            Add to Cart
                                        </Button>
                                    </Box>
                                    <CardActionArea>
                                        <img className='cardsImg' src={product.image} alt={product.title} style={{ width: '100%', height: '450px', objectFit: 'cover' }} />
                                        <CardContent>
                                            <Box className="productTitleRatingBox" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography className="productTitle productTitleEllipsis" variant="h6">
                                                    {product.title}
                                                </Typography>
                                                <Box className="ratingBox" sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Rating value={product.rating} max={1} precision={1} readOnly size="small" />
                                                    <Typography className="ratingText" sx={{ marginLeft: '4px', fontSize: '0.875rem' }}>
                                                        {product.ratingText}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography className="productLocation" variant="body2" color="textSecondary">
                                                {product.location}
                                            </Typography>
                                        </CardContent>
                                        <CardActions style={{ padding: '0 8px 15px 15px' }}>
                                            <Typography className="productPrice" variant="h6" color="primary">
                                                {product.price}
                                            </Typography>
                                        </CardActions>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    {!isLoading && productsListing.length == 0 &&
                        <Grid container spacing={3} padding={"0px 100px 0px 100px"}>
                            <Grid item xs={12} style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography style={{ fontSize: '20px', fontWeight: 500, fontFamily: 'Poppins', color: "red" }}>{errorMessage + '!' || 'No products found!'}</Typography>
                            </Grid>
                        </Grid>
                    }
                    {isLoading &&
                        <Grid container spacing={3} padding={"30px 100px 0px 100px"}>
                            {[1, 2, 3, 4].map((item) => (
                                <Grid key={item} item xs={12} sm={6} md={4} lg={3} xl={3} style={{ display: "flex", justifyContent: "center", flexFlow: "column", gap: "10px" }}>
                                    <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'430px'} />
                                    <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'30px'} />
                                    <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'80%'} height={'10px'} />
                                    <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'50%'} height={'10px'} />
                                </Grid>
                            ))}
                        </Grid>
                    }
                    <Grid container spacing={3} padding={"0px 100px 0px 100px"}>
                        <Grid item xs={12} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "50px" }}>
                            <Pagination page={currentPage} count={totalPages} onChange={this.handlePaginationChange} variant="outlined" color="primary" />
                        </Grid>
                    </Grid>
                </HeaderFooterLayout>
            </CustomFunctionBox>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const CustomRadio = styled(Radio)({
    '&.MuiRadio-root': {
        color: '#64748B !important',
    },
    '& .MuiSvgIcon-root': {
        color: "#6200EA "
    },
});

const CustomCheckBox = styled(Checkbox)({
    '&.MuiRadio-root': {
        color: '#64748B !important',
    },
    '& .MuiSvgIcon-root': {
        color: "#6200EA "
    },
});

const CustomFunctionBox = styled(Box)({
    '& .likeIconBox': {
        borderRadius: "50%",
        width: "35px",
        height: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.5)",
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 9
    },
    '& .radioBtn': {

    },

    '& .cardsImg': {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
    },
    '& .select': {
        marginRight: '16px',
        border: "1px solid white",
        '& .MuiOutlinedInput-notchedOutline':
            { border: "none" },
    },
    '& .productTitleRatingBox': {
        display: "flex",
        justifyContent: "space-between",
    },
    '& .occassionDescriptionBox': {
        display: "flex",
        padding: "2rem 5rem",
        justifyContent: "center",
    },
    '& .productListBox': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: "2rem",
        justifyContent: 'center',
    },
    '& .addToCartBtn': {
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: 'poppins',
        width: "207px",
        height: '44px',
        borderRadius: "30px",
        background: "#7F1187",
        color: "white"
    },
    '& .productCard': {
        position: 'relative',
        transition: 'opacity 0.3s ease',
        '&:hover': {
            opacity: 0.8,
        },
        '&:hover .overlayButton': {
            opacity: 1,
        },
        cursor: 'pointer',
        borderRadius: '12px',
        marginBottom: '10px',
    },
    '& .occassionDescriptionText': {
        fontSize: "16x",
        fontWeight: 400,
        fontFamily: "poppins",
        textAlign: "center",
        color: "#57534E"
    },
    '& .overlayButton': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: 0,
        transition: 'opacity 0.3s ease',
        zIndex: 1,
    },
    '& .occassionTextbox': {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "2rem"
    },
    '& .occassionText': {
        fontSize: "40px",
        fontWeight: 500,
        fontFamily: "poppins",
        textTransform: "capitalize",
    },
    '& .productTitle': {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: 'Poppins',
        '@media (max-width: 768px)': { fontSize: '1rem' },
        '@media (max-width: 480px)': { fontSize: '0.9rem' },
    },
    '& .ratingText': {
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: "#78716C",
        '@media (max-width: 768px)': { fontSize: '0.7rem' },
        '@media (max-width: 480px)': { fontSize: '0.6rem' },
    },
    '& .productLocation': {
        paddingTop: "1rem",
        fontFamily: 'Poppins',
        textTransform: 'capitalize',
        '@media (max-width: 768px)': { paddingTop: "0.5rem" },
        '@media (max-width: 480px)': { fontSize: '0.8rem' },
    },
    '& .productPrice': {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: 'Poppins',
        color: "#801188",
        '@media (max-width: 768px)': { fontSize: "14px" },
        '@media (max-width: 480px)': { fontSize: '0.9rem' },
    },
    '& .multiSelectDropdown': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px',
        textTransform: 'capitalize',
    },
    '& .productTitleEllipsis': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '240px',
        textTransform: 'capitalize',
    },
    '& .productCardLoader': {
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: '#CDCDCDCC',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        top: '0px',
    },
})

// Customizable Area End
