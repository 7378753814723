import React from "react";

// Customizable Area Start
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import { Box, Typography, Select, MenuItem, styled, Rating, Button, Grid, Skeleton, CircularProgress, Modal, Accordion, AccordionSummary, AccordionDetails, IconButton, Card, CardActionArea, CardContent, CardActions } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IosShareIcon from '@mui/icons-material/IosShare';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { productDefaultImage } from "./assets";

const productsData = [
    {
        id: 1,
        title: "Wedding decorations",
        image_url:
            "https://www.thepackersmovers.com/blog/wp-content/uploads/2022/05/Latest-Home-Decoration-Ideas-for-Indian-Wedding.jpg",
        description:
            "Step into a world of elegance and romance at our breathtaking wedding venues.",
    },
    {
        id: 2,
        title: "Mehendi designs",
        image_url:
            "https://photosly.net/wp-content/uploads/2024/02/arabic-mehndi-design-easy-photo24.jpg",
        description:
            "Your wedding day is one of the most significant and memorable days of your life",
    },
    {
        id: 3,
        title: "Wedding photography",
        image_url:
            "https://kamatharjun.com/wp-content/uploads/2019/07/AV-wed15.jpeg",
        description:
            "Step into a world of elegance and romance at our breathtaking wedding venues",
    },
    {
        id: 4,
        title: "Wedding songs",
        image_url:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX_jto6fR0WfN4Sw6HoT52UNowkMjKLVjXVom22m5EdzTAB3vTWFdDaWwUaw&s",
        description:
            "A destination wedding offers a unique and magical experience that combines your special day ",
    },
];
// Customizable Area End

import ProductSummaryController, { Props } from "./ProductSummaryController.web";
import { color } from "react-native-reanimated";
import { AiOutlineClose } from "react-icons/ai";

export default class ProductSummary extends ProductSummaryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const {
            isLoading,
            product,
            quantity,
            productSizes,
            productColors,
            selectedSize,
            selectedColor,
            lowStockAlertValue,
            showButtonLoader,
            clickedButton,
            openSizeChartModal,
            productMainImage,
            productFaqs,
            wishlistLoader,
        } = this.state;
        return (
            // Customizable Area Start
            <HeaderFooterLayout navigation={this.props.navigation}>
                <StyleWrapper>
                    {!isLoading && product &&
                        <Box padding={"3% 5% 5% 5%"}>
                            <Grid container spacing={5}>
                                <Grid item xs={12} width={"100%"}>
                                    <Typography className="pageTitle">{product.name}</Typography>
                                    <Typography className="pageDescription">{product.description}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} position={"relative"}>
                                    <Box className={`mainImageBox`}>
                                        <img style={{ ...{ width: "100%", height: "100%", objectFit: "cover" }, ...product.available_quantity === 0 ? { filter: "grayscale(100%)" } : {} }} src={productMainImage.url} alt="Product image" />
                                        {wishlistLoader ?
                                            <IconButton className="favoriteButton"> <CircularProgress style={{ color: "#FFF" }} size={20} /></IconButton>
                                            :
                                            <IconButton data-test-id="addToWishList" onClick={() => this.handleAddToWishlist(product.id)} className="favoriteButton"><FavoriteBorderIcon /></IconButton>
                                        }

                                    </Box>
                                    <Box width={"90%"} height={"100%"} position={"absolute"} top={"0px"}>
                                        {productMainImage.id < product.media_files.length &&
                                            <IconButton data-test-id="imageNextButton" onClick={() => this.handleLoadNextImage()} className="imageRightArrow"><ArrowForwardIos /></IconButton>
                                        }
                                        {productMainImage.id !== 1 &&
                                            <IconButton data-test-id="imagePreviousButton" onClick={() => this.handleLoadPreviousImage()} className="imageLeftArrow"><ArrowBackIos /></IconButton>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <Grid container spacing={3} maxWidth={"90vw"} marginBottom={"30px"} display={"flex"} flexDirection={"row"} flexWrap={"nowrap"} overflow={"hidden"}>
                                        {this.getProductImages(product.media_files).map((image) => (
                                            <Grid item xs={3}>
                                                <Box style={{ borderRadius: "12px", width: "100%", overflow: "hidden" }}>
                                                    <img style={{ ...{ width: "100%", height: "100%", objectFit: "cover" }, ...product.available_quantity === 0 ? { filter: "grayscale(100%)" } : {} }} src={image.url} alt="Product image" />
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12} md={9} lg={9} xl={9} display={"flex"} alignItems={"start"} flexDirection={"column"}>
                                                    <Typography className="productName">{product.name}</Typography>
                                                    <Box className="ratingBox" sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Rating value={4.1} max={5} precision={0.1} readOnly size="small" />
                                                        <Typography className="ratingText" sx={{ marginLeft: '4px', fontSize: '0.875rem' }}>
                                                            {'4.1 (100 Ratings)'}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                    <Box style={{ display: "flex", justifyContent: "end", alignItems: "start", flexDirection: "row" }}>
                                                        <>
                                                            <Typography className="quantity" component={"span"}>Qty</Typography>
                                                            <Select
                                                                data-test-id={`quantityDropdown`}
                                                                className="quantity"
                                                                labelId="quantity-select"
                                                                displayEmpty
                                                                value={quantity}
                                                                onChange={this.handleQuantityChange}
                                                                sx={{
                                                                    border: "1px solid white",
                                                                    fontSize: "16px",
                                                                    fontWeight: 700,
                                                                    fontFamily: "inter",
                                                                    '& .MuiOutlinedInput-notchedOutline': { border: "none" },
                                                                }}
                                                            >
                                                                {[...Array(10).keys()].map((num) => (
                                                                    <MenuItem key={num + 1} value={num + 1}>
                                                                        {num + 1}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </>
                                                        <Button className="shareBtn"><IosShareIcon style={{ color: 'black' }} /></Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12} md={9} lg={9} xl={9} display={"flex"} alignItems={"start"} flexDirection={"column"}>
                                                    <Typography className="sizeHeading">{'Sizes Available'}</Typography>
                                                    {productSizes.length > 0 &&
                                                        <Box className="ratingBox" sx={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                                                            {productSizes.map((size, index) => (
                                                                <Button data-test-id={`productSizeButton${index}`} onClick={() => this.handleSelectSize(size.id)} className={selectedSize === size.id ? "sizeButton activeSize" : "sizeButton"}>
                                                                    {`${size.size_label}/${size.measurement}`}
                                                                </Button>
                                                            ))}
                                                        </Box>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                    <Box style={{ display: "flex", justifyContent: "end", alignItems: "start", flexDirection: "row" }}>
                                                        <Typography data-test-id={`openSizeChart`} onClick={() => this.handleOpenSizeChartModal()} className="viewSizeChart">{'See Size Chart'}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className="sizeHeading">{'Colors Available'}</Typography>
                                            {productColors.length > 0 &&
                                                <Box className="ratingBox" sx={{ width: '95%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: "10px" }}>
                                                    {productColors.map((color, index) => (
                                                        <Button data-test-id={`productColorButton${index}`} onClick={() => this.handleSelectColor(color.id)} className={selectedColor === color.id ? "colorButton activeColor" : "colorButton"}>
                                                            <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={color.color_image || productDefaultImage} alt={color.name} />
                                                        </Button>
                                                    ))}
                                                </Box>
                                            }
                                        </Grid>
                                        <Grid item xs={12} display={"flex"} alignItems={"start"} flexDirection={"column"}>
                                            <Typography className="sizeHeading">{'Price'}</Typography>
                                            <Box display={"flex"} justifyContent={"center"} alignItems={"start"} paddingTop={"10px"}>
                                                <Typography component={"span"}><CurrencyRupeeIcon /></Typography>
                                                <Typography className="productName"> {product.price} </Typography>
                                                {product.available_quantity <= lowStockAlertValue && <Typography className="fewStocksText"> {'(Few stocks only available)'}</Typography>}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} marginTop={"20px"}>
                                            <Grid container spacing={3} style={{ display: "felx", flexWrap: "wrap" }}>
                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <Button disabled={showButtonLoader || product.available_quantity === 0} classes={{ disabled: "" }} data-test-id="buyNowButton" className="buyNowButton" type="button" onClick={() => { this.setState({ clickedButton: 'buy_now' }); this.handleAddToCart(product.id) }}>
                                                        {(!showButtonLoader || clickedButton !== 'buy_now') && <Typography>Buy Now</Typography>}
                                                        {showButtonLoader && clickedButton === 'buy_now' && <Typography> <CircularProgress style={{ color: "#FFF" }} size={20} /></Typography>}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <Button disabled={showButtonLoader || product.available_quantity === 0} data-test-id="addToCartButton" className="secondaryButton" type="button" onClick={() => { this.setState({ clickedButton: 'add_to_cart' }); this.handleAddToCart(product.id) }}>
                                                        {(!showButtonLoader || clickedButton !== 'add_to_cart') && <Typography>Add to Cart</Typography>}
                                                        {showButtonLoader && clickedButton === 'add_to_cart' && <Typography> <CircularProgress style={{ color: "#7F1287" }} size={20} /></Typography>}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                    <Button className="secondaryButton" type="button"> Compare </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} marginTop={"50px"}>
                                                <Box sx={{ cursor: "pointer" }} width={"100%"} height={"56px"} padding={"0px 10px 0px 10px"} borderTop={"1px solid #A8A29E"} borderBottom={"1px solid #A8A29E"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Typography className="accordianTitle">Product details</Typography>
                                                    <Typography> <ExpandMoreIcon /> </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} width={"100%"} marginTop={"30px"}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className="accordianTitle">Product Description</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="accordianData">
                                                This lehenga set is designed for the modern woman who values tradition with a contemporary twist. The rich color and delicate craftsmanship make it a standout piece for weddings, sangeet, or festive gatherings.
                                            </Typography>
                                            <ul className="accordianData">
                                                <li>
                                                    Color: Emerald Green
                                                </li>
                                                <li>
                                                    Blouse: Sleeveless, sequin-embellished, round neckline
                                                </li>
                                                <li>
                                                    Skirt: Full flare with embroidered hemline
                                                </li>
                                                <li>
                                                    Occasion: Weddings, festive events, parties
                                                </li>
                                            </ul>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className="accordianTitle">Fabric details</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="accordianData">
                                                The charming floral applique motifs in bright colors embolden the pastel base of the gorgeous lehenga. the scallop details on the dupatta features.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} width={"100%"}>
                                    <Typography className="productSectionTitle">You might also like this</Typography>
                                    <Grid container spacing={3} padding={"0px"}>
                                        {productsData.map((product, index) => (
                                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ flexGrow: 1, borderRadius: '12px', overflow: 'hidden' }}>
                                                <Card
                                                    key={index}
                                                    className="productCard"
                                                    sx={{ boxShadow: 3, marginBottom: "10px", borderRadius: "12px", overflow: "hidden" }}
                                                >
                                                    <Box className="likeIconBox">
                                                        <FavoriteBorderIcon sx={{ color: 'white' }} />
                                                    </Box>
                                                    <CardActionArea>
                                                        <img className='cardsImg' src={product.image_url} alt={product.title} style={{ width: '100%', height: '450px', objectFit: 'cover' }} />
                                                        <CardContent>
                                                            <Box className="productTitleRatingBox" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <Typography className="productTitle productTitleEllipsis" variant="h6">
                                                                    {product.title}
                                                                </Typography>
                                                                <Box className="productRatingBox" sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Rating value={5} max={1} precision={1} readOnly size="small" />
                                                                    <Typography className="ratingText" sx={{ marginLeft: '4px', fontSize: '0.875rem' }}>
                                                                        4.5 (678 Ratings)
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            <Typography className="productLocation" variant="body2" color="textSecondary">
                                                                Mumbai
                                                            </Typography>
                                                        </CardContent>
                                                        <CardActions style={{ padding: '0 8px 15px 15px' }}>
                                                            <Typography className="productPriceText" variant="h6">
                                                                ₹ 10,000
                                                            </Typography>
                                                        </CardActions>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} width={"100%"}>
                                    <Typography className="faqHeading">Frequently asked questions</Typography>
                                    {productFaqs.length > 0 ?
                                        <Box width={"100%"} height={"auto"} borderRadius={"12px"} padding={"25px"} style={{ display: "flex", flexDirection: "column", background: "#FBE8FC" }}>
                                            {productFaqs.map((faq, index) => (
                                                <Box marginBottom={"25px"}>
                                                    <Box data-test-id={`faqToggle${index}`} onClick={() => this.handleFaqToggle(faq.id)} padding={"25px 15px"} borderRadius={"8px"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} style={{ background: "#FFF", cursor: "pointer" }}>
                                                        <Typography className="faqTitle" style={faq.open ? { color: "#7F1187" } : { color: "#000" }}>{faq.question} </Typography>
                                                        <Typography className="faqTitle"> {faq.open ? <RemoveIcon /> : <AddIcon />} </Typography>
                                                    </Box>
                                                    {faq.open &&
                                                        <Box marginTop={"-25px"} padding={"25px 15px"} borderRadius={"8px"} style={{ background: "#FFF" }}>
                                                            <Typography className="accordianData"> {faq.answer}</Typography>
                                                        </Box>
                                                    }
                                                </Box>
                                            ))
                                            }
                                        </Box>
                                        :
                                        <Box width={"100%"} height={"150px"} borderRadius={"12px"} padding={"25px"} style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "#FBE8FC" }}>
                                            <Typography>No questions added!</Typography>
                                        </Box>
                                    }

                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {isLoading &&
                        <Box padding={"100px"}>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                    <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'80%'} height={'750px'} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={3}>
                                            <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'250px'} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'250px'} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'250px'} />
                                        </Grid>
                                    </Grid>
                                    <Skeleton sx={{ background: "#CDCDCD", margin: "30px 0px 10px 0px" }} animation="wave" variant="rounded" width={'100%'} height={'20px'} />
                                    <Skeleton sx={{ background: "#CDCDCD", margin: "10px 0px 10px 0px" }} animation="wave" variant="rounded" width={'50%'} height={'20px'} />
                                    <Skeleton sx={{ background: "#CDCDCD", margin: "30px 0px 10px 0px" }} animation="wave" variant="rounded" width={'100%'} height={'10px'} />
                                    <Skeleton sx={{ background: "#CDCDCD", margin: "10px 0px 50px 0px" }} animation="wave" variant="rounded" width={'25%'} height={'10px'} />
                                    <Grid container spacing={3}>
                                        <Grid item xs={1}>
                                            <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'80px'} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'80px'} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'80px'} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Skeleton sx={{ background: "#CDCDCD" }} animation="wave" variant="rounded" width={'100%'} height={'80px'} />
                                        </Grid>
                                    </Grid>
                                    <Skeleton sx={{ background: "#CDCDCD", margin: "50px 0px 10px 0px" }} animation="wave" variant="rounded" width={'100%'} height={'25px'} />
                                    <Skeleton sx={{ background: "#CDCDCD", margin: "50px 0px 10px 0px" }} animation="wave" variant="rounded" width={'100%'} height={'40px'} />
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </StyleWrapper >

                <Modal
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={openSizeChartModal}
                    onClose={this.handleCloseSizeChartModal}
                    aria-labelledby="size-modal-title"
                    aria-describedby="sizr-modal-description"
                >
                    <Box style={modalStyle.mainContainer}>
                        <Box style={modalStyle.header}>
                            <Typography style={modalStyle.applySizeChartTitle}>Size Chart </Typography>
                            <Typography datat-test-id="modalCloseButton" style={{ ...modalStyle.applySizeChartTitle, ...{ cursor: 'pointer' } }} onClick={() => this.handleCloseSizeChartModal()}><AiOutlineClose /> </Typography>
                        </Box>
                        <Box width={"100%"} height={"500px"} overflow={"auto"}>
                            <Grid container padding={"24px"}>
                                <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <img style={{ height: '450px', objectFit: 'contain' }} src={product?.size_chart || productDefaultImage} alt="size chart" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Modal>
            </HeaderFooterLayout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const modalStyle = {
    mainContainer: {
        background: '#FFF',
        width: '600px',
        minHeight: '400px',
        borderRadius: '10px',
        overflow: 'hidden',
    },
    header: {
        height: "max-content",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px",
        borderBottom: "1px solid #D6D3D1",
    },
    applySizeChartTitle: {
        fontFamily: 'Poppins',
        fontSize: '20px',
        lineHeight: '22px',
        fontWeight: 600,
        color: '#292524',
    },
}

const StyleWrapper = styled(Box)({
    '& .pageTitle': {
        fontFamily: "Poppins",
        fontSize: "40px",
        fontWeight: 500,
        lineHeight: "50px",
        textAlign: "center",
        textTransform: "capitalize",
        color: "#1C1917",
        marginBottom: "10px",
    },
    '& .pageDescription': {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "26px",
        textAlign: "center",
        color: "#57534E",
        marginBottom: "20px",
    },
    '& .mainImageBox': {
        borderRadius: "12px",
        width: "90%",
        overflow: "hidden",
        position: "relative",
        transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
    },
    '& .slide-out-left': {
        transform: "translateX(-100%)",
        opacity: 0,
    },
    '& .slide-in-right': {
        transform: "translateX(100%)",
        opacity: 0
    },
    '& .slide-out-right': {
        transform: "translateX(100%)",
        opacity: 0,
    },
    '& .slide-in-left': {
        transform: "translateX(-100%)",
        opacity: 0,
    },
    '& .active': {
        transform: "translateX(0)",
        opacity: 1,
    },
    '& . productImage': {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    '& .productName': {
        fontFamily: "Poppins",
        fontSize: "22px",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.005em",
        textAlign: "left",
        textTransform: "capitalize",
    },
    '& .quantity': {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "inter",
        lineHeight: "22px",
        padding: "0px",
        height: "20px",
    },
    "& .shareBtn": {
        color: '#57534E',
        display: 'flex',
        gap: '5px',
        padding: "0px",
        alignItems: 'center',
        "& span": {
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Poppins",
            lineHeight: "20px",
            textTransform: "capitalize"
        }
    },
    '& .ratingBox': {
        marginTop: "10px",
    },
    '& .sizeHeading': {
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Poppins",
        lineHeight: "22px",
        color: "#78716C",
        textTransform: "capitalize"
    },
    '& .viewSizeChart': {
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Poppins",
        lineHeight: "22px",
        color: "#7F1187",
        textTransform: "capitalize",
        cursor: "pointer",
    },
    '& .sizeButton': {
        minWidth: "70px",
        width: "max-content",
        height: "32px",
        padding: "6px 12px 6px 8px",
        gap: "4px",
        borderRadius: "26px",
        border: "1px solid #B2B2B2",
        opacity: "0px",
        color: "#292524",
    },
    '& .colorButton': {
        widht: "85px",
        height: "95px",
        borderRadius: "12px",
        overflow: "hidden",
        padding: "0px",
    },
    '& .activeSize': {
        border: "2px solid #7F1187",
    },
    '& .activeColor': {
        border: "2px solid #7F1187",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    },
    '& .fewStocksText': {
        color: "#DC2626",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Poppins",
        lineHeight: "22px",
        marginLeft: "20px"
    },
    '& .buyNowButton': {
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#7F1187",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "100%",
        cursor: "pointer",
        "&:hover": {
            background: "#7F1287"
        },
        "&:disabled": {
            background: "#808080",
            color: "#ababab",
        }
    },
    '& .secondaryButton': {
        color: "#7F1187",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textTransform: "capitalize",
        background: "#FFF",
        padding: "10px 0px",
        borderRadius: "30px",
        width: "100%",
        cursor: "pointer",
        border: "1px solid #7F1187",
        "&:disabled": {
            border: "1px solid #808080",
            color: "#ababab",
        }
    },
    '& .accordianTitle': {
        color: "#000",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
    },
    '& .accordianData': {
        color: "#57534E",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
    },
    '& .faqHeading': {
        color: "#1C1917",
        fontFamily: "Poppins",
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "50px",
        marginBottom: "20px",
    },
    '& .imageRightArrow': {
        width: "40px",
        height: "40px",
        padding: "0px",
        position: "absolute",
        right: "15px",
        top: "50%",
        color: "#000",
        background: "#FFF",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        zIndex: 9,
    },
    '& .imageLeftArrow': {
        width: "40px",
        height: "40px",
        padding: "0px 0px 0px 7px",
        position: "absolute",
        left: "-20px",
        top: "50%",
        color: "#000",
        background: "#FFF",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        zIndex: 9,
    },
    '& .favoriteButton': {
        width: "40px",
        height: "40px",
        padding: "0px",
        position: "absolute",
        right: "10px",
        top: "10px",
        color: "#FFF",
        zIndex: 10,
    },
    '& .faqTitle': {
        color: "#000",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "25px",
    },
    '& .likeIconBox': {
        position: "absolute",
        top: "0.5rem",
        right: "0.5rem",
        zIndex: 1,
    },
    '& .cardsImg': {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
    },
    '& .productTitleRatingBox': {
        display: "flex",
        justifyContent: "space-between",
    },
    '& .productTitle': {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: 'Poppins',
        '@media (max-width: 768px)': { fontSize: '1rem' },
        '@media (max-width: 480px)': { fontSize: '0.9rem' },
    },
    '& .productRatingBox': {
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
    },
    '& .rating': {
        fontSize: "12px",
        fontFamily: 'Poppins',
        color: "#78716C",
        '@media (max-width: 480px)': { fontSize: '0.6rem' },
        '@media (max-width: 768px)': { fontSize: '0.7rem' },
    },
    '& .productLocationText': {
        paddingTop: "1rem",
        fontFamily: 'Poppins',
        '@media (max-width: 768px)': { paddingTop: "0.5rem" },
        '@media (max-width: 480px)': { fontSize: '0.8rem' },
    },
    '& .productPriceText': {
        fontWeight: 600,
        fontSize: "16px",
        color: "#801188",
        fontFamily: 'Poppins',
        '@media (max-width: 768px)': { fontSize: "14px" },
        '@media (max-width: 480px)': { fontSize: '0.9rem' },
    },
    '& .productSectionTitle': {
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "24px",
        color: "#44403C",
        fontFamily: 'Poppins',
        marginTop: "30px",
        marginBottom: "30px",
    },
});
// Customizable Area End
