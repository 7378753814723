import React from "react";
import { Box, Typography, styled, Button } from "@mui/material";

interface IVendorCardProps {
  vendorImage: string;
  title: string;
  description: string;
  buttonText: string;
  isType1: boolean;
}

const VendorCard: React.FC<IVendorCardProps> = ({
  vendorImage,
  title,
  description,
  buttonText,
  isType1,
}) => {
  return isType1 ? (
    <VendorCardStyle className="vendor-type1">
      <Box className="right-side-rounded-box" />
      <Box className="left-box">
        <Typography className="left-box-title">{title}</Typography>
        <Typography className="left-box-second-title">{description}</Typography>
        <Button className="left-box-button">{buttonText}</Button>
      </Box>
      <img src={vendorImage} alt="vender" />
    </VendorCardStyle>
  ) : (
    <VendorCardStyle className="vendor-type2">
      <Typography className="left-box-title">{title}</Typography>
      <img src={vendorImage} className="vender-2-image" alt="vender-2-image" />
      <Typography className="left-box-second-title">{description}</Typography>
      <Button className="left-box-button">{buttonText}</Button>
    </VendorCardStyle>
  );
};

export default VendorCard;

const VendorCardStyle = styled(Box)(({ theme }) => ({
  "&.vendor-type1": {
    display: "flex",
    flex: "1 1 auto",
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "8px",
    overflow: "hidden",
    boxSizing: "border-box",
    position: "relative",
    minHeight: "180px",
    "& img": {
      width: "52.808%",
      objectFit: "cover",
      zIndex: "0",
      position: "absolute",
      right: "0",
      top: "0",
      height: "100%",
      bottom: "0",
      objectPosition: "center",
    },
  },
  "&.vendor-type2": {
    boxShadow: "0px 4px 4px 0px #00000040",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    minHeight: "180px",
    padding: "24px 27px",
    boxSizing: "border-box",
    position: "relative",
    "& .vender-2-image": {
      position: "absolute",
      height: "30px",
      width: "31px",
      top: "24px",
      right: "24px",
    },
  },
  "& .left-box": {
    width: "54.97%",
    zIndex: "1",
    padding: "24px 27px",
    boxSizing: "border-box",
  },
  "& .left-box-title": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: "#292524",
    marginBottom: "4px",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "26px",
    },
  },
  "& .left-box-second-title": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    textAlign: "left",
    color: "#44403C",
  },
  "& .left-box-button": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "22px",
    textAlign: "left",
    padding: "0",
    textTransform: "none",
    color: "#D6197D",
    marginTop: "6px",
  },
  "& .right-side-rounded-box": {
    width: "71.471%",
    height: "167.23%",
    backgroundColor: "#FFF",
    left: "-17.208%",
    top: "-33.89%",
    position: "absolute",
    zIndex: "1",
    borderRadius: "50%",
  },
}));
