// Customizable Area Start
import React, { Component } from "react";
import { Box, Typography, styled, Button } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation } from "swiper";

import {
  doNotWorkImage,
  ratingStar,
  leftArrow,
  rightArrow,
  userImage,
} from "../assets";

const breakpoints = {
  480: {
    slidesPerView: 1,
  },
  600: {
    slidesPerView: 2,
  },
  980: {
    slidesPerView: 3,
  },
};

interface Props {}

interface State {}

export default class DoNotSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <DoNotContainer component="section" id="do-not-take-word">
        <Typography component="h2" className="do-not-take-word-title">
          Don’t take our words for it -{" "}
        </Typography>
        <Typography className="do-not-take-word-description">
          customers say it best
        </Typography>
        <Box className="do-not-image-container">
          <img src={doNotWorkImage} className="do-not-image" alt="do-not" />
        </Box>
        <Box className="swipe-container">
          <Swiper
            data-test-id="swiper"
            className="carousel-container"
            modules={[Navigation]}
            spaceBetween={32}
            breakpoints={breakpoints}
            navigation={{
              prevEl: ".custom-prev",
              nextEl: ".custom-next",
            }}
          >
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <SwiperSlide key={index}>
                  <Box className="do-not-card">
                    <Box className="rating-star-container">
                      <img
                        src={ratingStar}
                        alt="rating"
                        className="rating-star"
                      />
                      <img
                        src={ratingStar}
                        alt="rating"
                        className="rating-star"
                      />
                      <img
                        src={ratingStar}
                        alt="rating"
                        className="rating-star"
                      />
                      <img
                        src={ratingStar}
                        alt="rating"
                        className="rating-star"
                      />
                      <img
                        src={ratingStar}
                        alt="rating"
                        className="rating-star"
                      />
                    </Box>
                    <Typography className="rating-description">
                      <q>
                        Happilife Wedding Planner made our dream wedding a
                        reality! From the initial consultation to the big day,
                        their team was incredibly attentive and professional.
                      </q>
                    </Typography>
                    <Box className="image-text-container">
                      <img src={userImage} alt="user" className="user-image" />
                      <Box className="text-container">
                        <Typography className="user-name">
                          Sanjana Mohan
                        </Typography>
                        <Typography className="location">Bangalore</Typography>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
          </Swiper>

          <Button className="custom-prev" data-test-id="custom-prev">
            <img src={leftArrow} alt="left" />
          </Button>

          <Button className="custom-next" data-test-id="custom-next">
            <img src={rightArrow} alt="right" />
          </Button>
        </Box>
      </DoNotContainer>
    );
  }
}

const DoNotContainer = styled(Box)(({ theme }) => ({
  padding: "20px 0 14px",
  "& .do-not-take-word-title": {
    fontFamily: "Poppins",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "52px",
    textAlign: "center",
    color: "#1C1917",
  },
  "& .do-not-take-word-description": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#292524",
    marginBottom: "32px",
  },
  "& .do-not-image-container": {
    height: "367px",
    width: "100%",
  },
  "& .do-not-image": {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  "& .rating-star": {
    width: "20.28px",
    height: "19.64px",
  },
  "& .do-not-card": {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 6px 6px 0px #00000040",
    borderRadius: "16px",
    padding: "52.68px 27px",
    height: "100%",
    boxSizing: "border-box",
  },
  "& .rating-star-container": {
    display: "flex",
    gap: "7px",
    paddingLeft: "8.34px",
  },
  "& .rating-description": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    margin: "24.68px 0 40px",
    maxWidth: "295px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
  },
  "& .image-text-container": {
    display: "flex",
    gap: "15px",
  },
  "& .user-image": {
    height: "56px",
    width: "56px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  "& .user-name": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "34px",
    letterSpacing: "-0.005em",
    color: "#292524",
    textAlign: "left",
  },
  "& .location": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "left",
    color: "#292524",
  },
  "& .swiper": {
    height: "fit-content",
    padding: "6px",
  },
  "& .swiper-slide": {
    height: "auto",
  },
  "& .custom-prev": { left: "-14px" },
  "& .custom-next": { right: "-14px" },
  "& .custom-next,& .custom-prev": {
    height: "44px",
    zIndex: 2,
    position: "absolute",
    top: "0",
    bottom: "0",
    margin: "auto",
    width: "44px",
    backgroundColor: "#FFF",
    borderRadius: "50%",
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "0",
    minWidth: "unset",
    "&:disabled": {
      display: "none",
    },
  },
  "& .swipe-container": {
    position: "relative",
    marginTop: "-102px",
    "& .swiper-wrapper": {
      height: "fit-content",
    },
  },
}));
// Customizable Area End
