import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface DiscountOfferData {
  data: Data[]
}
interface Data {
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "cost_type": string,
    "coupon_code": string,
    "exp_date": string,
    "min_order_value": number,
    "max_discount": number,
    "discount_percentage": number,
    "terms_policies": string
  }
}
interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  anchorFilterEl: HTMLElement | null;
  moreBtnId: number;
  discountOfferData: DiscountOfferData[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SubscriptionVendorController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getDiscountOfferApiCallId: string = "";
  editDiscountOfferApiCallId: string = ""
  deleteProductApiCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      anchorEl: null,
      anchorFilterEl: null,
      moreBtnId: 0,
      discountOfferData: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getDiscountOfferList()
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );

    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getDiscountOfferApiCallId) {
      this.setState({ discountOfferData: responseJson.data }, () => {
        console.log(this.state.discountOfferData, "dis")
      })
    // } else if (apiRequestCallId === this.editDiscountOfferApiCallId) {
    //   () =>{
    //     console.log("Edited Discount Offer:", responseJson);
    //   }
    }
    console.log(responseJson, "checkRes");
    console.log(apiRequestCallId, "callId")
    // Customizable Area End
  }

  // Customizable Area Start
  handleClick = (event: React.MouseEvent<HTMLElement>, id: any, data: any) => {
    this.setState({ anchorEl: event.currentTarget });
    setStorageData("data", JSON.stringify(data))
    setStorageData("id", id)
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  getDiscountOfferList = async () => {
    this.getDiscountOfferApiCallId = await apiCall({
      endPoint: "bx_block_discount/checkout_addition_costs",
      method: "GET",
      token: this.state.token,
    });
  }
  editDiscountOffer = async (id: string, updatedData: any) => {
    this.editDiscountOfferApiCallId = await apiCall({
      endPoint: `bx_block_discount/checkout_addition_costs/${id}`,
      method: "PATCH",
      token: this.state.token,
      body: updatedData
    });
  }

  goToCreateVendorPage = () => {
    setStorageData("editVendorOffer", "")
    const addCatalogueForm: Message = new Message(getName(MessageEnum.NavigationMessage));
    addCatalogueForm.addData(getName(MessageEnum.NavigationTargetMessage), "CreateVendorOffer");
    addCatalogueForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(addCatalogueForm);
  }
  handleEditOffer = () => {
    setStorageData("editVendorOffer", true)
    const addCatalogueForm: Message = new Message(getName(MessageEnum.NavigationMessage));
    addCatalogueForm.addData(getName(MessageEnum.NavigationTargetMessage), "CreateVendorOffer");
    addCatalogueForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(addCatalogueForm);
    console.log(addCatalogueForm, "addCatelog")
  }
  // Customizable Area End
}
