import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { apiCall } from "../../../components/src/utils";
import { setStorageData } from "../../../framework/src/Utilities";

interface IInspirationDetailData {
  id: string;
  type: string;
  attributes: {
    id: number;
    heading: string;
    description: string;
    image: {
      id: number;
      date: string;
      url: string;
      type: string;
    };
  };
}

interface IInspirationData {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    image: {
      id: number;
      date: string;
      url: string;
      type: string;
    };
  };
}

interface IBrowseCategoryData {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  inspirationDetailData: IInspirationDetailData[];
  inspirationData: IInspirationData[];
  token: string;
  browseByCategoryData: IBrowseCategoryData[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class InspirationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inspirationApiCallId: string = "";
  inspirationMainApiCallId: string = "";
  categoryBrowseApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      inspirationDetailData: [],
      token: "",
      inspirationData: [],
      browseByCategoryData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (webApiRequestCallId) {
        case this.inspirationApiCallId:
          this.setState({
            inspirationDetailData: webResponseJson.data,
            isLoading: false,
          });
          break;
        case this.inspirationMainApiCallId:
          this.setState({
            isLoading: false,
            inspirationData: webResponseJson.data,
          });
          break;
        case this.categoryBrowseApiCallId:
          this.setState({
            isLoading: false,
            browseByCategoryData: webResponseJson.data,
          });
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getInspirationDetailData();
    this.getInspirationData();
    this.getBrowseCategoryData();
  }

  redirectToInspirationItemsPage = (id: number) => {
    setStorageData("inspirationId", id)
    this.props.navigation.navigate("InspirationsItem", { id });
  };

  getInspirationDetailData = async () => {
    this.setState({
      isLoading: true,
    });
    this.inspirationApiCallId = await apiCall({
      endPoint: "bx_block_content_management/inspiration_details",
      method: "GET",
    });
  };

  getInspirationData = async () => {
    this.setState({
      isLoading: true,
    });
    this.inspirationMainApiCallId = await apiCall({
      endPoint: "bx_block_content_management/inspirations",
      method: "GET",
    });
  };

  getBrowseCategoryData = async () => {
    this.setState({
      isLoading: true,
    });
    this.categoryBrowseApiCallId = await apiCall({
      endPoint: "bx_block_content_management/browse_by_categories",
      method: "GET",
    });
  };
  // Customizable Area End
}
