import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Checkbox,
  styled,
  FormControlLabel,
  Dialog,
  DialogContent,InputLabel,
  Autocomplete,
  MenuItem,
  ListItemText,
  TextField,
  Stack,
  Chip
} from "@mui/material";
import sanitizeHtml from "sanitize-html";
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from "@mui/material/styles";
import {
  checkImage,
  googlelogo,
  hidePassword,
  showPassword,
  uncheckImage,
  wedding_logo_2,
  WeddingLogo,
} from "./assets";

import { Formik, Form, FormikErrors } from "formik";
import * as Yup from "yup";
import TextInputFiled from "../../../components/src/TextInputField.web";
import SelectField from "../../../components/src/SelectField.web";
import LoginSideImageWrapper from "../../../components/src/LoginSideImageWrapper.web";
// Customizable Area End

import VendorSignupController, { Props } from './VendorSignupController.web';
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

export default class VendorSignup extends VendorSignupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  isError = (
    touched: boolean | undefined,
    errors: string | undefined,
    isValid: boolean = false
  ) => {
    return isValid ? isValid : touched && !!errors;
  };
  onClickHandler = (option:any, isSelected:any, setFieldValue:any) => {
    this.toggleServiceOption(option);
    this.handleServiceOption(option, isSelected, setFieldValue);
  }
  serviceMenuItemFun = (props : any, option:any, setFieldValue:any) =>{
    {
      const isSelected = this.state.serviceProvided?.some(
        (selected) => selected.value === option.value
      );
      return (
        <MenuItem
          {...props}
          key={option?.value}
          data-testid = "serviceMenuItem"
          onClick={()=> {this.onClickHandler(option,isSelected,setFieldValue)}}
          style={{ borderBottom: '1px solid #E7E5E4' }}
        >
          <Checkbox checked={isSelected} style={{ marginRight: 8 }} />
          <ListItemText primary={option?.label} />
        </MenuItem>
      );
    }
  }
  helperText = (
    touched: boolean | undefined,
    errors: string | undefined,
    isValid: boolean = false,
    errorMessage = ""
  ) => {
    return isValid ? errorMessage : touched && errors;
  }
    dialogWrapper = (
        setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean | undefined
        ) => Promise<void | FormikErrors<{
          reference_id: string;
          email: string;
          password: string;
          fullName: string;
          showPassword: boolean;
          isTermsChecked: boolean;
          isPrivacyChecked: boolean;
        }>>,
        isTermsChecked: boolean,
        isPrivacyChecked: boolean
      ) => {
        return (
          <Formik
            data-test-id="formik-dialog"
            initialValues={{
              isModalChecked:
                this.state.openTermsPrivacyModal.modalName === "terms"
                  ? isTermsChecked
                  : isPrivacyChecked,
            }}
            validationSchema={validationModalSchema}
            onSubmit={(data) => {
              setFieldValue(
                this.state.openTermsPrivacyModal.modalName == "terms"
                  ? "isTermsChecked"
                  : "isPrivacyChecked",
                data.isModalChecked
              );
              this.openModal(false, this.state.openTermsPrivacyModal.modalName);
            }}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <DialogContent>
                  <Box className="text-image-container">
                    <img
                      src={wedding_logo_2}
                      className="wedding-logo-3"
                      alt="Wedding Logo"
                    />
                    <Typography className="terms-conditions-text-1">
                      {this.state.openTermsPrivacyModal.modalName === "terms"
                        ? "Terms and Conditions"
                        : "Privacy Policy"}
                    </Typography>
                  </Box>
                  <Box className="scroll-container">
                    {this.state.openTermsPrivacyModal.modalName === "terms" &&
                      this.state.termsConditionData.length > 0 && (
                        <Box
                          className="terms-condition-description"
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              this.state.termsConditionData[0].description
                            ),
                          }}
                        />
                      )}
                    {this.state.openTermsPrivacyModal.modalName === "privacy" &&
                      this.state.privacyData.length > 0 && (
                        <Box
                          className="terms-condition-description"
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              this.state.privacyData[0].description
                            ),
                          }}
                        />
                      )}
                    <Box className="terms-condition-button-container">
                      <CheckBoxContainer
                        isError={errors.isModalChecked ? true : false}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isModalChecked}
                              name="isModalChecked"
                              icon={<img src={uncheckImage} alt="unchecked" />}
                              checkedIcon={<img src={checkImage} alt="unchecked" />}
                              onChange={handleChange}
                            />
                          }
                          label=""
                        />
                        <Box component="div" className="terms-condition-text">
                          I have read and agree to the{" "}
                          <Typography component="span" className="text-decoration">
                            {this.state.openTermsPrivacyModal.modalName === "terms"
                              ? "terms and conditions"
                              : "privacy policy"}
                          </Typography>
                        </Box>
                      </CheckBoxContainer>
                      <AcceptButton
                        type="submit"
                        data-test-id="accept-button"
                        isChecked={values.isModalChecked}
                        disabled={!values.isModalChecked}
                      >
                        Accept
                      </AcceptButton>
                    </Box>
                  </Box>
                </DialogContent>
              </Form>
            )}
          </Formik>
        );
};
  // Customizable Area End

  render() {
    const monthOptions = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
      ];
    return (
      // Customizable Area Start
      <LoginSideImageWrapper isLoading={this.state.isLoading} carouselImages={this.state.carouselList}>
        <ScrollableContentBox data-test-id="ScrollableContentBox">
          <Box className="sign-up-form-container">
            <Formik
              data-test-id="Formik"
              initialValues={this.state.formData}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.signUpWithVendorEmail(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                const daysInMonth = this.getDaysInMonth(values.month, values.year);
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    <img
                      src={WeddingLogo}
                      className="wedding-logo"
                      data-test-id="wedding-logo"
                      alt="Wedding Logo"
                      onClick={this.redirectFromVendorSignUpToHomePage}
                    />
                    <Typography className="sign-up-form-container-text-1">
                      Sign Up
                    </Typography>
                    <Typography className="sign-up-form-container-text-2">
                      Get started for free
                    </Typography>
        <SignUpGoogleButton fullWidth>
          <Box>
            <GoogleOAuthProvider clientId="916004841115-q5bgscuf0lmppktsm2u5olmgbdrmsdkh.apps.googleusercontent.com">
               <GoogleLogin data-test-id="vendorgoogle"
                 onSuccess={this.googleVendorLoginSuccess}
                 onError={this.googleVendorLoginFailed}
                />
            </GoogleOAuthProvider>
          </Box>
        </SignUpGoogleButton>
                    <Box className="or-box-center">
                      <Typography className="or-style">- or -</Typography>
                    </Box>
                    <InputLabel className="labelClass">Name of the business <span className="star">*</span></InputLabel>
                    <TextInputFiled
                      name="businessName"
                      data-test-id="full-name-input"
                      placeholder="Enter your business name"
                      value={values.businessName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.businessName, errors.businessName)}
                      helperText={this.helperText(
                        touched.businessName,
                        errors.businessName
                      )}
                    />
                    <InputLabel className="labelClass">Full Name <span className="star">*</span></InputLabel>
                    <TextInputFiled
                      name="fullName"
                      data-test-id="full-name-input"
                      placeholder="Enter your name"
                      value={values.fullName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.fullName, errors.fullName)}
                      helperText={this.helperText(
                        touched.fullName,
                        errors.fullName
                      )}
                    />
                    <InputLabel className="labelClass">Company incorporation date<span className="star">*</span></InputLabel>
                        <Box className="dropdown-container">
                            <SelectField
                                name="month"
                                data-test-id="monthCheck"
                                value={values.month}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                options={monthOptions}
                                placeholder="Month"
                                error={Boolean(this.handleErrorText(touched.month, errors.month))}
                                touched={Boolean(touched.month)}
                                helperText={this.handleErrorText(touched.month, errors.month) ? errors.month : ""}
                                label={""}
                            />
                        <SelectField
                            name="day"
                            value={values.day}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            options={Array.from({ length: daysInMonth }, (_, i) => ({
                                label: (i + 1).toString(),
                                value: (i + 1),
                            }))}
                            placeholder="Day"
                            error={Boolean(this.handleErrorText(touched.day, errors.day))}
                            touched={touched.day}
                            helperText={this.handleErrorText(touched.day, errors.day)} label={""}/>
                            <SelectField
                                name="year"
                                value={values.year}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur("year")}
                                options={Array.from({ length: 100 }, (_, i) => ({
                                    label: (new Date().getFullYear() - i).toString(),
                                    value: (new Date().getFullYear() - i),
                                }))}
                                placeholder="Year"
                                error={this.isError(touched.year, errors.year)}
                                touched={touched.year}
                                helperText={touched.year && errors.year}
                                label={""}
                            />
            </Box>
                            <InputLabel className="labelClass">Business category <span className="star">*</span></InputLabel>
                    <TextInputFiled
                      name="businessCategory"
                      data-test-id="full-name-input"
                      placeholder="Enter business category"
                      value={values.businessCategory}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.businessCategory, errors.businessCategory)}
                      helperText={this.helperText(
                        touched.businessCategory,
                        errors.businessCategory
                      )}
                    />
                    <Box className="emailPasswordField">
                    <Box className="fieldSection"><InputLabel className="labelClass">Email <span className="star">*</span></InputLabel>
                    <TextInputFiled
                      name="email"
                      placeholder="Enter your email"
                      value={values.email}
                      data-test-id="email-input"
                      handleChange={(e) => {
                        handleChange(e);
                        this.handleEmailValid();
                      }}
                      handleBlur={handleBlur}
                      type="email"
                      error={this.isError(
                        touched.email,
                        errors.email,
                        this.state.isInvalidEmail
                      )}
                      helperText={this.helperText(
                        touched.email,
                        errors.email,
                        this.state.isInvalidEmail,
                        "Email is already registered"
                      )}
                    />
                    </Box>
                    <Box className="fieldSection"><InputLabel className="labelClass">Password <span className="star">*</span></InputLabel>
                    <TextInputFiled
                      data-test-id="password-input"
                      name="password"
                      placeholder="Enter your password"
                      value={values.password}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type={values.showPassword ? "text" : "password"}
                      error={this.isError(touched.password, errors.password)}
                      helperText={this.helperText(
                        touched.password,
                        errors.password
                      )}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              data-test-id="icon-toggle"
                              onClick={() =>
                                setFieldValue(
                                  "showPassword",
                                  !values.showPassword
                                )
                              }
                              edge="end"
                            >
                              {values.showPassword ? (
                                <img
                                  src={showPassword}
                                  alt="show"
                                  className="password-images"
                                />
                              ) : (
                                <img
                                  src={hidePassword}
                                  alt="hide"
                                  className="password-images"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    </Box>
                    </Box>
                    <Box className="emailPasswordField">
                    
                        <Box className="fieldSection">
                    <InputLabel className="labelClass">Primary contact number <span className="star">*</span></InputLabel>
                    <Box className="numberField">
                    <SelectField
                            placeholder="+91"
                            handleBlur={handleBlur("phoneId")}
                            label=""
                            name="phoneId"
                            options={this.state.phoneNumberData.map((data: any) => ({
                                label: data.label,
                                value: data.value,
                            }))}
                            setFieldValue={setFieldValue} value={values.phoneId} helperText={undefined} 
                    />
                    <TextInputFiled
                      name="primaryContact"
                      data-test-id="full-name-input"
                      placeholder="0000000000"
                      value={values.primaryContact}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.primaryContact, errors.primaryContact)}
                      helperText={undefined}
                    />
                    </Box>
                    <Typography className="errorText">{this.helperText(
                        touched.primaryContact,
                        errors.primaryContact
                      )}</Typography>
                    </Box>
                    <Box className="fieldSection">
                    <InputLabel className="labelClass">Secondary contact number</InputLabel>
                    <Box className="numberField">
                    <SelectField
                            placeholder="+91"
                            handleBlur={handleBlur("phoneIdNo")}
                            label=""
                            name="phoneIdNo"
                            options={this.state.phoneNumberData.map((data: any) => ({
                                label: data.label,
                                value: data.value,
                            }))}
                            setFieldValue={setFieldValue} value={values.phoneIdNo} helperText={undefined} 
                    />
                     <TextInputFiled
                      name="secondaryNumber"
                      data-test-id="full-name-input"
                      placeholder="0000000000"
                      value={values.secondaryNumber}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.secondaryNumber, errors.secondaryNumber)}
                      helperText={undefined}
                    />
                    </Box>
                    <Typography className="errorText">{this.helperText(
                        touched.secondaryNumber,
                        errors.secondaryNumber
                      )}</Typography>
                    </Box>
                    </Box>
                    <InputLabel className="labelClass">GST/Tax number <span className="star">*</span></InputLabel>
                    <TextInputFiled
                      name="gstNumber"
                      data-test-id="full-name-input"
                      placeholder="Enter your GST/Tax no."
                      value={values.gstNumber}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched.gstNumber, errors.gstNumber)}
                      helperText={this.helperText(
                        touched.gstNumber,
                        errors.gstNumber
                      )}
                    />
                    <Box className="inputFieldsSection"> 
                    <InputLabel className="labelClass inputText">Service provided <span className="star">*</span></InputLabel>
                    <div>
                      <Autocomplete
                        multiple  sx={{ width: "100%" }}
                        id="tags-checkbox"
                        options={this.state.serviceData?.map((data : any) => ({
                          label: data?.attributes.name,
                          value: data?.attributes.id,
                      }))}
                        data-test-id="handleServiceSelectChange"
                         isOptionEqualToValue={(option, value) => option.value === value.value}
                        disableCloseOnSelect
                        value={this.state.serviceProvided}
                        onChange={(event, newValue) => {
                          this.handleServiceSelectChange(newValue, setFieldValue);
                        }}
                        renderTags={() => null}
                        renderOption={(props, option) => this.serviceMenuItemFun(props, option, setFieldValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Services"
                            data-test-id="handleServiceSet"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            onBlur={handleBlur}
                            value={values.selectedService}
                            error={this.isError(touched?.selectedService, errors?.selectedService)}
                            helperText={this.helperText(touched?.selectedService, errors?.selectedService)}
                          />
                        )}
                      />
                    </div>
                    <Stack direction="row" spacing={1} className="chipBox">
                      {Array.isArray(this.state.serviceProvided) && this.state.serviceProvided?.map((option : any) => (
                        <Chip
                          key={option.value}
                          label={option.label}
                          style={{ margin: '4px', border:"2px solid rgb(202, 26, 125)", backgroundColor: "transparent",}}
                          data-test-id="handleDeleteServiceChip"
                          onDelete={() => this.handleDeleteServiceChip(option, setFieldValue)}
                          deleteIcon={
                            <IconButton size="small">
                              <CloseIcon fontSize="small" className="closeIcon" />
                            </IconButton>
                          }
                        />
                      ))}
                    </Stack>
                    </Box>
                    <InputLabel className="labelClass">How did you find us? <span className="star">*</span></InputLabel>
                    <SelectField
                      error={this.isError(
                        touched.reference_id,
                        errors.reference_id
                      )}
                      touched={touched.reference_id}
                      placeholder="How did you find us"
                      handleBlur={handleBlur("reference_id")}
                      helperText={touched.reference_id && errors.reference_id}
                      label=""
                      name="reference_id"
                      options={this.state.referenceData.map((data) => ({
                        label: data.name,
                        value: data.id,
                      }))}
                      setFieldValue={setFieldValue}
                      value={values.reference_id}
                    />
                    <CheckBoxContainer
                      data-test-id="CheckBoxContainer"
                      isError={
                        touched.isPrivacyChecked &&
                        errors.isPrivacyChecked &&
                        touched.isTermsChecked &&
                        errors.isTermsChecked
                          ? true
                          : false
                      }
                    >
                      <FormControlLabel
                        data-test-id="check-box-controller"
                        control={
                          <Checkbox
                            data-test-id="check-box"
                            checked={values.isPrivacyChecked && values.isTermsChecked}
                            name="isChecked"
                            icon={<img src={uncheckImage} alt="unchecked" />}
                            checkedIcon={
                              <img src={checkImage} alt="unchecked" />
                            }
                            onChange={(event) => {
                                setFieldValue("isTermsChecked", event.target.checked);
                            setFieldValue("isPrivacyChecked", event.target.checked)
                            }}
                          />
                        }
                        label=""
                      />
                      <Box component="div" className="terms-condition-text">
                        I agree with{" "}
                        <Typography
                          data-test-id="privacy-click"
                          component="span"
                          className="text-decoration"
                          onClick={() => this.openModal(true, "privacy")}
                        >
                          Privacy Policy
                        </Typography>{" "}
                        and{" "}
                        <Typography
                          data-test-id="terms-condition-click"
                          component="span"
                          className="text-decoration"
                          onClick={() => this.openModal(true, "terms")}
                        >
                          Terms and Conditions
                        </Typography>
                      </Box>
                    </CheckBoxContainer>
                    <SignUpButton
                      data-test-id="sign-up-button"
                      type="submit"
                      fullWidth
                      disabled={this.state.isInvalidEmail}
                      isChecked={
                        values.isPrivacyChecked && values.isTermsChecked
                      }
                    >
                      Sign Up
                    </SignUpButton>
                    <Typography className="login-main-text">
                      Already have an account?{" "}
                      <Typography
                        component="span"
                        data-test-id="login"
                        className="login-text"
                        onClick={() => this.goToLogin()}
                      >
                        Log in
                      </Typography>
                    </Typography>
                    <TermsConditionDialog
                      data-test-id="dialog-box"
                      open={this.state.openTermsPrivacyModal.open}
                      onClose={() =>
                        this.openModal(
                          false,
                          this.state.openTermsPrivacyModal.modalName
                        )
                      }
                      maxWidth="lg"
                      fullWidth
                    >
                     {this.dialogWrapper(setFieldValue, values.isTermsChecked, values.isPrivacyChecked)}
                    </TermsConditionDialog>
                  </Form>
                );
              }}
            </Formik>
            
          </Box>
        </ScrollableContentBox>
      </LoginSideImageWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z][A-Z\d]Z[A-Z\d]$/;

const validationSchema = Yup.object({
    businessName: Yup.string()
    .required("Business Name is required")
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Business Name should only contain letters")
    .test(
      "not-only-digits",
      "Business name cannot contain only digits",
      (value) => {
        return !/^\d+$/.test(value);
      }
    )
    .max(50, "Maximum 50 characters are allowed"),
    fullName: Yup.string()
    .required("Full Name is required")
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Full Name should only contain letters")
    .test(
      "not-only-digits",
      "Full name cannot contain only digits",
      (value) => {
        return !/^\d+$/.test(value);
      }
    )
    .max(50, "Maximum 50 characters are allowed"),
    businessCategory: Yup.string()
    .required("Business Category is required")
    .trim()
    .matches(/^[a-zA-Z\s]+$/, "Business Category should only contain letters")
    .test(
      "not-only-digits",
      "Business Category cannot contain only digits",
      (value) => {
        return !/^\d+$/.test(value);
      }
    )
    .max(50, "Maximum 50 characters are allowed"),
    gstNumber: Yup.string()
    .matches(gstRegex, 'Invalid GST no.')
    .required('GST number is required'),
  email: Yup.string()
    .email("Email should be in a valid email format (user@example.com)")
    .required("Email is required")
    .trim(),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character"
    )
    .trim(),
    primaryContact: Yup.string()
    .required('Contact number is required')
    .matches(/^\d+$/, 'Contact number must contain only digits')
    .min(10, 'Contact number must be at least 10 digits')
    .max(10, 'Contact number cannot exceed 10 digits'),

    secondaryNumber: Yup.string()
    .matches(/^\d+$/, 'Contact number must contain only digits')
    .min(10, 'Contact number must be at least 10 digits')
    .max(10, 'Contact number cannot exceed 10 digits'),
//     serviceProvided:Yup.string().required("Service Provided is required"),
  reference_id: Yup.string().required("This field is required"),
  day: Yup.number().required("Day is required").min(1).max(31),
  month: Yup.number().required("Month is required"),
  year: Yup.string().required("Year is required"),
  isTermsChecked: Yup.bool().oneOf(
    [true],
    "You must agree to Terms and Conditions"
  ),
  isPrivacyChecked: Yup.bool().oneOf(
    [true],
    "You must agree to Privacy Policy"
  ),
  isCheckedTermModal: Yup.bool().oneOf(
    [true],
    "You must agree to the Privacy Policy and Terms and Conditions"
  ),
  isCheckedPrivacyModal: Yup.bool().oneOf(
    [true],
    "You must agree to the Privacy Policy and Terms and Conditions"
  ),
  phoneId:Yup.string()
    .required('Phone number is required'),
    phoneIdNo:Yup.string()
});
const validationModalSchema= Yup.object({
    isModalChecked:Yup.bool().oneOf([true], "You must agree to privacy Policy")
})
const ScrollableContentBox = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "100%",
  overflowY: "auto",
  padding: "30px 70px 68px 72.32px",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    minHeight: "fit-content",
    overflowY: "unset",
    height: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "30px 30px 68px",
  },
  "& .sign-up-form-container": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& form": {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "600px",
      },
    },
  },
  "& .dropdown-container": {
    display: "flex",
    gap:"12px",
    "& > *:nth-child(1)": {
        flex: 3,
    },
    "& > *:nth-child(2), & > *:nth-child(3)": {
        flex: 1,
    },
},
    "& .star": {
        color: "#DC2626 !important",
    },
    "& .inputFieldsSection": {
    width: '100%'
  },
    "& .labelClass": {
        color: "#334155",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "22px",
    },
    "& .inputText": {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "poppins",
      color: "#334155",
    },
    "& .errorText":{
        color:"#DC2626",
        fontSize: "12px",
         fontFamily: "Poppins",
         fontWeight: "400",
         lineHeight: "18px",
         marginTop:"-5px",
         marginBottom:"16px"
       },
    "& .emailPasswordField": {
        display: "flex",
        gap: "12px",
        "@media(max-width:600px)":{
            flexDirection:"column"
        }
    },
    "& .numberField":{
        display:'flex',
        gap:'10px',
        width:"100%",
        alignItems:'center',
        "& > *:nth-child(1)": {
            width:"35%",
        },
      },
    "& .fieldSection": {
        display: 'flex',
        flexDirection: "column",
        width:"50%",
        "@media(max-width:600px)":{
            width:"100%",
        }
    },
  "& .wedding-logo": {
    marginBottom: "24px",
    width: "120.62px",
    height: "60px",
    aspectRation: "1",
    cursor: "pointer",
  },
  "& .sign-up-form-container-text-1": {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#000000",
    marginBottom: "6px",
  },
  "& .closeIcon": {
    color: "black"
  },
  "& .chipBox": {
    marginTop: "20px",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  "& .sign-up-form-container-text-2": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#78716C",
    marginBottom: "24px",
  },
  "& .or-box-center": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "24px",
    marginBottom: "24px",
  },
  "& .or-style": {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "24px",
    color: "#A8A29E",
  },
  "& .common-form-label": {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "4px",
  },
  "& .login-main-text": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#78716C",
    marginTop: "20px",
  },
  "& .login-text": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#801187",
    cursor: "pointer",
  },
}));

const SignUpGoogleButton = styled(Button)({
  padding: "9px 16px",
  border: "1px solid #E7E5E4",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "24px",
  color: "#000000",
  textTransform: "none",
  gap: "8px",
});

const CheckBoxContainer = styled(Box)(({ isError }: { isError: boolean }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  margin: "8px 0 30px",
  "& .terms-condition-text,& .terms-condition-text *": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: isError ? "#DC2626 !important" : "#292524",
  },
  "& .text-decoration": {
    textDecoration: "underline",
    cursor: "pointer",
  },
  "& .MuiFormControlLabel-root": {
    marginLeft: "-9px",
    marginRight: "0px",
  },
}));

const SignUpButton = styled(Button)(
  ({ isChecked }: { isChecked: boolean }) => ({
    border: `1px solid ${isChecked ? "#801187" : "#7F128769"}`,
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: isChecked ? "#FFFFFF !important" : "#A8A29E !important",
    borderRadius: "8px",
    padding: "19px 20px",
    textTransform: "unset",
    backgroundColor: isChecked ? "#801187" : "transparent",
    "&:hover": {
      backgroundColor: isChecked ? "#801187" : "transparent",
    },
  })
);

const TermsConditionDialog = styled(Dialog)(({ theme }) => ({
  "& .wedding-logo-3": {
    height: "60px",
    width: "108px",
  },
  "& form": {
    margin: 0,
    display: "flex",
    height: "100%",
    overflow: "hidden",
  },
  "& .terms-conditions-text-1": {
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#000000",
    marginTop: "17px",
    marginBottom: "24px",
  },
  "& .MuiPaper-root": {
    borderRadius: "25px",
    maxWidth: "1248px",
    overflow: "hidden",
  },
  "& .MuiDialogContent-root": {
    padding: "25px 16px 40.48px 60px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "25px 16px 25px 25px",
    },
  },
  "& .terms-condition-description": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#57534E",
    whiteSpace: "pre-wrap",
  },
  "& .terms-condition-button-container": {
    marginTop: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
    "& > div": {
      margin: "0",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    "& .terms-condition-text,& .text-decoration": {
      color: "#292524",
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  "& .text-image-container": {
    flex: "0 0 auto",
  },
  "& .scroll-container": {
    flex: "1 1 auto",
    paddingRight: "56px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E2E8F0",
      borderRadius: "10px",
      margin: "0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#801187",
      borderRadius: "10px",
    },
  },
}));
interface AcceptButtonProps {
  isChecked: boolean;
}
const AcceptButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "isChecked",
  })<AcceptButtonProps>(
  ({ theme, isChecked }: { theme: Theme; isChecked: boolean }) => ({
    border: `1px solid ${isChecked ? "#801187" : "#7F128769"}`,
    fontFamily: "Poppins",
    fontSize: "16px",
    width: "260px",
    height: "64.54px",
    fontWeight: "400",
    lineHeight: "24px",
    color: isChecked ? "#FFFFFF !important" : "#A8A29E !important",
    borderRadius: "8px",
    padding: "19px 20px",
    textTransform: "unset",
    backgroundColor: isChecked
      ? "#801187 !important"
      : "transparent !important",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "54.54px",
    },
    "&:hover": {
      backgroundColor: isChecked ? "#801187" : "transparent",
    },
  })
);
// Customizable Area End
