import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  InputAdornment,
  IconButton,
} from "@mui/material";

// Customizable Area Start
import { WeddingLogo, hidePassword, showPassword } from "./assets";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoginSideImageWrapper from "../../../components/src/LoginSideImageWrapper.web";
import TextInputFiled from "../../../components/src/TextInputField.web";

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character"
    )
    .trim(),
  confirm_password: Yup.string()
    .required("Confirm password is required")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password must match with password"
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Confirm password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character"
    )
    .trim(),
});
// Customizable Area End

import NewPasswordController, { Props } from "./NewPasswordController";

export default class NewPasswordWeb extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginSideImageWrapper isLoading={this.state.isLoading} carouselImages={this.state.newPassdatacarousel}>
        <ScrollableContentBox data-test-id="ScrollableContentBox">
          <Box className="sign-up-form-container">
            <Formik
              data-test-id="Formik"
              initialValues={this.state.formData}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.forgotPasswordWithEmail(values);
              }}
            >
              {({
                touched,
                values,
                errors,
                setFieldValue,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    <img
                      src={WeddingLogo}
                      className="wedding-logo"
                      alt="Wedding Logo"
                    />
                    <Typography className="sign-up-form-container-text-1">
                      Reset password
                    </Typography>
                    <Typography className="sign-up-form-container-text-2">
                      Your new password must be different from the previously
                      used password
                    </Typography>
                    <TextInputFiled
                      name="password"
                      data-test-id="password-input"
                      sx={{ marginBottom: "12.2px" }}
                      placeholder="Enter new password"
                      value={values.password}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type={values.showPassword1 ? "text" : "password"}
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-test-id="icon-toggle"
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setFieldValue(
                                  "showPassword1",
                                  !values.showPassword1
                                )
                              }
                              edge="end"
                            >
                              {values.showPassword1 ? (
                                <img
                                  className="password-images"
                                  src={showPassword}
                                  alt="show"
                                />
                              ) : (
                                <img
                                  alt="hide"
                                  src={hidePassword}
                                  className="password-images"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextInputFiled
                      name="confirm_password"
                      sx={{ marginBottom: "22.83px" }}
                      data-test-id="confirm-password-input"
                      placeholder="Enter confirm password"
                      value={values.confirm_password}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type={values.showPassword2 ? "text" : "password"}
                      error={
                        touched.confirm_password && !!errors.confirm_password
                      }
                      helperText={
                        touched.confirm_password && errors.confirm_password
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-test-id="icon-toggle"
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setFieldValue(
                                  "showPassword2",
                                  !values.showPassword2
                                )
                              }
                              edge="end"
                            >
                              {values.showPassword2 ? (
                                <img
                                  className="password-images"
                                  src={showPassword}
                                  alt="show"
                                />
                              ) : (
                                <img
                                  alt="hide"
                                  src={hidePassword}
                                  className="password-images"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ForgotButton
                      type="submit"
                      data-test-id="sign-up-button"
                      disabled={this.state.inValidEmail}
                      fullWidth
                    >
                      Send
                    </ForgotButton>
                    <Typography className="login-main-text">
                      Return to{" "}
                      <Typography
                        className="login-text"
                        component="span"
                        onClick={() => this.goToLogin()}
                        data-test-id="login"
                      >
                        Log in
                      </Typography>
                    </Typography>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </ScrollableContentBox>
      </LoginSideImageWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ScrollableContentBox = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  padding: "30px 114.96px 30px 72.32px",
  width: "50%",
  height: "100%",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    height: "100%",
    minHeight: "fit-content",
    overflowY: "unset",
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "30px 30px 68px",
  },
  "& .sign-up-form-container": {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& form": {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "600px",
      },
    },
  },
  "& .sign-up-form-container-text-1": {
    color: "#000000",
    marginBottom: "6px",
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "24px",
  },

  "& .login-main-text": {
    color: "#44403C",
    fontFamily: "Poppins",
    lineHeight: "24px",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "500",
    marginTop: "22.68px",
  },
  "& .wedding-logo": {
    width: "120.62px",
    height: "60px",
    marginBottom: "24px",
    aspectRation: "1",
  },
  "& .login-text": {
    fontFamily: "Poppins",
    fontWeight: "500",
    cursor: "pointer",
    fontSize: "16px",
    color: "#7F1287",
    lineHeight: "24px",
  },
  "& .sign-up-form-container-text-2": {
    fontFamily: "Poppins",
    lineHeight: "24px",
    color: "#78716C",
    fontWeight: "400",
    marginBottom: "24px",
    fontSize: "16px",
  },
}));

const ForgotButton = styled(Button)({
  marginTop: "11.78px",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  border: "1px solid  #801187",
  color: "#FFFFFF !important",
  backgroundColor: "#801187",
  borderRadius: "8px",
  padding: "19px 20px",
  textTransform: "unset",
  "&:hover": {
    backgroundColor: "#801187",
  },
});
// Customizable Area End
