import React from "react";
import { Box, styled, Typography, Grid } from "@mui/material";

interface IRealWeddingItemCardProps {
  imageUrl: string[];
  title?: string;
  description?: string;
  showVideo?: boolean;
  videoUrl?:string
}

const RealWeddingItemCard: React.FC<IRealWeddingItemCardProps> = ({
  imageUrl,
  title,
  description,
  showVideo, videoUrl
}) => {
  return (
    <RealWeddingItemCardStyle>
    <Box className="real-wedding-image-container">
        {videoUrl ?  <video src={videoUrl} controls autoPlay muted style={{height:"90%", width:"100%"}}/>:  <img src={imageUrl[0]} alt="wedding" />}
      </Box>
      {(imageUrl.length>=1 && videoUrl) && (
        <Box className="real-wedding-image-container">
       {videoUrl ?  <img src={imageUrl[0]} alt="wedding" />:<img src={imageUrl[1]} alt="wedding" />}
      </Box>
      )}
       {(imageUrl.length==2 && !videoUrl) &&
        <Box className="real-wedding-image-container">
       <img src={imageUrl[1]} alt="wedding" />
      </Box>}
      {(imageUrl.length >= 2 || (videoUrl && imageUrl.length >= 1)) && (
  <Grid container spacing={4}>
    {videoUrl ? (
      imageUrl.slice(1).map((data, index) => (
        <Grid item xs={12} lg={4} md={4} sm={6} key={index}>
          <Box className="real-wedding-image-container-2">
            <img src={data} alt="wedding" />
          </Box>
        </Grid>
      ))
    ) : (
      imageUrl.slice(2).map((data, index) => (
        <Grid item xs={12} lg={4} md={4} sm={6} key={index}>
          <Box className="real-wedding-image-container-2">
            <img src={data} alt="wedding" />
          </Box>
        </Grid>
      ))
    )}
  </Grid>
)}

      {title && (
        <Typography className="image-title-text" component="h6">
          {title}
        </Typography>
      )}
      {description && (
        <Typography className="image-description-text">
          {description}
        </Typography>
      )}
    </RealWeddingItemCardStyle>
  );
};

export default RealWeddingItemCard;

const RealWeddingItemCardStyle = styled(Box)({
  "& .real-wedding-image-container, & .real-wedding-image-container-2": {
    height: "571px",
    width: "100%",
    marginBottom: "32px",
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
  },
  "& .real-wedding-image-container-2": {
    height: "503px",
    marginBottom: "0",
  },
  "& .image-title-text": {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "center",
    color: "#44403C",
    margin: "0px 0 16px",
  },
  "& .image-description-text": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#57534E",
    margin: "0px 0 48px",
  },
  "& .MuiGrid-item": {
    width: "100%",
  },
});
