import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React,{ ChangeEvent, RefObject } from "react";
import { apiCall } from "../../../components/src/utils";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { ToastContentProps, toast } from "react-toastify";

// Customizable Area Start
export interface ColorOption {
  label: string;
  value: number;
}
export interface Item {
  title: string;
  description: string;
}
export interface Size {
  size: string;
  measurements: string;
}
export interface ColorSet {
colorName:string;
uploadImage:File | null;
colorSize:string;
availableQty:string
}
export interface QuesSet {
  Questions:string;
  answer:string;
  id:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface FormDataOption {
  productName:string;
  skuCode:string;
  productCategory:string;
  productDescription:string;
  fabricDetails:string;
  price:string, 
      availableQuantity:string,
      coupons:string,
      selectFunction:string[] | string,
      selectedLocation:string,
      selectedFileName:File |null,
      selectedFileNames: string[];
      sizeChart:string;
productImages: any;
uploadImage:File | null,
items: Item[];
addSize:Size[];
addColor:ColorSet[];
addQues:QuesSet[]
}

interface StateType {
  selectedColors:ColorOption[];
  selectedFunctions:ColorOption[];
  selectedLocations: ColorOption[];
  isLoading:boolean;
  token:string;
  locationData:ColorOption[];
  functionList:ColorOption[];
  productCategory:ColorOption[];
  availableSize:ColorOption[];
  couponData:ColorOption[];
  formData:FormDataOption;
  productVariant:number[];
  IsEditing:boolean;
  // Customizable Area Start
  // Customizable Area End
}
export interface ServiceLocationData {
    id: string,
    type: string,
    attributes: {
        id: number,
        location_name: string
    }
}
interface FunctionAttributes {
  id: number;
  name: string;
  function_image: string | null;
}

interface Function {
  id: string;
  type: string;
  attributes: FunctionAttributes;
}

interface ServiceLocationAttributes {
  id: number;
  location_name: string;
}

interface ServiceLocation {
  id: string;
  type: string;
  attributes: ServiceLocationAttributes;
}

interface ServiceLocations {
  data: ServiceLocation[];
}

interface CustomFields {
  color: string;
  material: string;
}
export interface CountriesList {
  Countries: Array<Countries>;
}interface ProductAttributes {
  id: number;
  name: string;
  SKU: string;
  description: string;
  fabric_details: string;
  price: string;
  is_published: boolean;
  product_faqs: any[];
  size_chart: string | null;
  is_available: boolean;
  is_deleted: boolean;
  available_quantity: number;
  media_files: any[];
  functions: Function[];
  service_locations: ServiceLocations;
  product_variants: any[];
  custom_fields: CustomFields;
  created_at: string;
}
export interface Product {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

interface Countries {
  name: string;
  dial_code: string;
  code: string;
}

export interface Gender {
  label: string;
  value: string;
}

export interface Industry {
  label: string;
  value: string;
}
export interface Country {
  label: string;
  value: string;
}
interface SSType {
  SSTypeid: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddCatalogueFormController extends BlockComponent<
  Props,
  StateType,
  SSType
> {
  // Customizable Area Start
  submitTransactionApiCallId: string = "";
  getOrderApiCallId: string = "";
  serviceLocationApiCallId:string="";
  functionDataApiCallId:string="";
  productCategoryApiCallId:string="";
  createSizeApiCallId:string="";
  availableSizeApiCallId:string="";
  createColorApiCallId:string="";
  createProductApiCallId:string="";
  getProductListApiCallId:string="";
  editProductApiCallId:string="";
  getCouponListApiCallID:string=""
  fileInputRef:RefObject<HTMLDivElement[]>
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.fileInputRef = React.createRef();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      selectedColors:[],
      selectedFunctions:[],
      selectedLocations:[],
      isLoading:false,
      token:"",
      locationData:[],
      functionList:[],
      productCategory:[],
      availableSize:[],
      couponData:[],
      formData:{
        productName:"",
        skuCode:"",
        productCategory:"",
        productDescription:"",
        fabricDetails:"",
        price:"", 
        availableQuantity:"",
        coupons:"",
        selectFunction:"",
        selectedFileName:null,
        selectedLocation:"",
        selectedFileNames: [],
        sizeChart:"",
        productImages:[],
        uploadImage:null,
        items: [{ title: "", description: "" }],
        addSize:[{size:"", measurements:""}],
        addColor:[{colorName:"", uploadImage:null, colorSize:"", availableQty:""}],
        addQues:[{Questions:"", answer:"", id:''}],
      },
      // Customizable Area Start
      productVariant:[],
      IsEditing:false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Api response
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.serviceLocationApiCallId:
          const mappedLocations = responseJson.data.map((location: any) => ({
            value: location.id,
            label: location.attributes.location_name
          }));
          this.setState({ locationData: mappedLocations });
          break;
          case this.functionDataApiCallId:
            const mappedFunction = responseJson.data.map((location: any) => ({
              value: location.id,
              label: location.attributes.name
            }));
            this.setState({ functionList: mappedFunction });
            break;
            case this.productCategoryApiCallId:
              const mappedCategory = responseJson.data.map((location: any) => ({
                value: location.id,
                label: location.attributes.name
              }));
              this.setState({ productCategory: mappedCategory });
              break;
              case this.availableSizeApiCallId:
                const availableSize = responseJson?.data?.map((size: any) => ({
                  value: size.id,
                  label: size.attributes.size_label
                }));
                this.setState({ availableSize: availableSize });
                break;
                case this.createSizeApiCallId:
                  if(!responseJson.errors){
                    toast.success("Size Created Sucessfully");
                  }
                  this.getAvailableSizeApi()
                  break;
                  case this.createColorApiCallId:
                    let productVariant = responseJson.data.attributes.id
                    this.setState((prevState) => ({
                      productVariant: [...prevState.productVariant, productVariant],
                    }));
                    if(!responseJson.errors){
                      toast.success("Color Created Sucessfully");
                    }
                    break;
                    case this.getProductListApiCallId:
                      this.setEditProductData(responseJson)
                      
                  break;
                  case this.getCouponListApiCallID:
                      const couponData = responseJson?.data?.map((size: any) => ({
                        value: size.id,
                        label: size.attributes.coupon_code
                      }));
                      this.setState({ couponData: couponData });
                      
                  break;
                  case this.createProductApiCallId:
                    this.setCreateProductApi(responseJson)
                    break;
                  case this.editProductApiCallId:
                    this.setEditProductApi(responseJson)
                    break;
                default:
      }
    }
    // Customizable Area End
  }
  
  // Customizable Area Start
  componentDidMount = async () => {
    this.serviceLoationApi();
    this.getToken();
    this.functionDataApi()
    this.getProductCategoryApi()
    this.getAvailableSizeApi()
    this.getProductListApiCall()
    this.getCouponList()
    const isEditing =  await getStorageData('IsEditing');
    if(isEditing == "true"){
      this.setState({IsEditing:true})
    }
  };
  setCreateProductApi = (responseJson: { error: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | ((props: ToastContentProps<unknown>) => React.ReactNode) | null | undefined; }) => {
    if(responseJson.error){
      toast.error(responseJson.error)
    }
    else{
      toast.success("Catalogue Created Sucessfully");
      const addCatalogueForm: Message = new Message(getName(MessageEnum.NavigationMessage));
addCatalogueForm.addData(getName(MessageEnum.NavigationTargetMessage), "Catalogue");
addCatalogueForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
this.send(addCatalogueForm);
    }
  }
  setEditProductApi =(responseJson: { error: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | ((props: ToastContentProps<unknown>) => React.ReactNode) | null | undefined; }) => {
    if(responseJson.error){
      toast.error(responseJson.error)
    }
    else{
      toast.success("Edit Catalogue Sucessfully");
      removeStorageData('isEditing')
      const editForm: Message = new Message(getName(MessageEnum.NavigationMessage));
editForm.addData(getName(MessageEnum.NavigationTargetMessage), "Catalogue");
editForm.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
this.send(editForm);
    }
  }
  urlToFile = async (url: RequestInfo, filename: string, mimeType: string) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return new File([arrayBuffer], filename, { type: mimeType });
  };
  setEditProductData = async(responseJson: { data: any[]; }) => {
    let editId = await getStorageData("editId");
                        if (this.state.IsEditing) {
                          let editFilterData = responseJson.data.filter((data:Product) => {
                            return data.attributes.id == editId
                          })
                          const sizeChartUrl = editFilterData[0]?.attributes?.size_chart
                          const locationData = editFilterData[0]?.attributes?.service_locations?.data?.map((location: any) => ({
                            value: location.id,
                            label: location.attributes.location_name
                          }));
                          const functionData = editFilterData[0]?.attributes?.functions?.map((location: any) => ({
                            value: location.attributes.id,
                            label: location.attributes.name
                          }));
                          const attributes = editFilterData[0]?.attributes || {};
                          
                            const items = Object.entries(attributes.custom_fields || {}).map(
                              ([key, value]) => ({
                                title: key,
                                description: value,
                              })
                            );
                            const addColor = await Promise.all(
                              (attributes.product_variants || []).map(async (variant: any) => {
                            
                                return {
                                  colorName: variant.attributes.product_color.attributes.name || '',
                                  uploadImage: variant.attributes.product_color.attributes.color_image
                                    ? await this.urlToFile(
                                        variant.attributes.product_color.attributes.color_image,
                                        "color_image.png",
                                        "image/png"
                                      )
                                    : null,
                                  colorSize: JSON.stringify(variant.attributes.product_size?.attributes?.id) || '',
                                  availableQty: variant.attributes.available_count || 0,
                                };
                              })
                            );
                            const addQues = await Promise.all(
                              (attributes.product_faqs || []).map(async (data: any) => {
                            
                                return {
                                  Questions: data.attributes.question || '',
                                  answer: data.attributes.answer||'',
                                  id: data.attributes.id
                                };
                              })
                            );
                            const addSize = await Promise.all(
                              (attributes.product_variants || []).map(async (variant: any) => {
                            
                                return {
                                  size: variant.attributes.product_size?.attributes?.size_label || '',
                                  measurements: variant.attributes.product_size?.attributes?.measurement||0,
                                };
                              })
                            );
                            
                            const sizeChartFile = await this.urlToFile(sizeChartUrl, "size_chart.png", "image/png");
                          this.setState((prevState:any) => {
                            return {
                              formData: {
                                ...prevState.formData,
                                productName: attributes.name || '',
                                skuCode: attributes.SKU || '',
                                productCategory: attributes.category?.id || '',
                                productDescription: attributes.description || '',
                                fabricDetails: attributes.fabric_details || '',
                                price: attributes.price || 0,
                                availableQuantity: attributes.available_quantity || 0,
                                selectedFileName: sizeChartFile,
                                sizeChart: sizeChartFile?.name || '',
                                selectedLocation: locationData,
                                selectFunction: functionData,
                                coupons:attributes.coupon.id,
                                items,
                                addColor,
                                addSize,
                                addQues,
                                productImages: [
                                  ...(prevState.formData.productImages || []),
                                  ...(attributes.media_files || [])
                                ]
                              },
                            };
                          });
                          
                          this.setState({selectedLocations: locationData ||[], selectedFunctions:functionData ||[]})
                        } 

  }
  handleFunctionSelectChange = (event: React.ChangeEvent<{}>, newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    this.setState({ selectedFunctions: newValue });
    setFieldValue('selectFunction', newValue);
  };
  handleDeleteFunctionChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedFunctions = this.state.selectedFunctions.filter(
      (item) => item.value != optionToDelete.value
    );
    this.setState({ selectedFunctions: newSelectedFunctions });
    setFieldValue('selectFunction', newSelectedFunctions);
  };
  
  toggleFunctionOption = (optionToToggle: ColorOption) => {
    Array.isArray(this.state.selectedFunctions) && this.setState((prevState) => {
      const { selectedFunctions } = prevState;
      const isSelected = Array.isArray(selectedFunctions) && selectedFunctions.some(option => option.value == optionToToggle.value);
      if (isSelected) {
        return {
          selectedFunctions: selectedFunctions.filter(option => option?.value != optionToToggle?.value),
        };
      } else {
        return {
          selectedFunctions: [...selectedFunctions, optionToToggle],
        };
      }
    });
  };
  handleFunctionOption = (option: ColorOption,isSelected:any,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedFunctions = isSelected
    ? this.state.selectedFunctions.filter(
        (selected) => selected.value != option.value
      )
    : [...this.state.selectedFunctions, option];
  this.setState({ selectedFunctions: newSelectedFunctions });
  setFieldValue('selectFunction', newSelectedFunctions);
  }
  handleSelectLocationChange = (event: React.ChangeEvent<{}>, newValue: ColorOption[], setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
      this.setState({ selectedLocations: newValue });
      setFieldValue('selectedLocation', newValue);
    };

  handleDeleteLocationChip = (optionToDelete: ColorOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedLocation = this.state.selectedLocations.filter(
      (item) => item.value != optionToDelete.value
    );
    this.setState({ selectedLocations: newSelectedLocation });
    setFieldValue('selecredLocation', newSelectedLocation);
  };
  toggleLocationOption = (optionToToggle: ColorOption) => {
    this.setState((prevState) => {
      const { selectedLocations } = prevState;
      const isSelected = selectedLocations.some(option => option?.value === optionToToggle?.value);
      if (isSelected) {
        return {
          selectedLocations: selectedLocations.filter(option => option?.value !== optionToToggle?.value),
        };
      } else {
        return {
          selectedLocations: [...selectedLocations, optionToToggle],
        };
      }
    });
  };
  handleLocationOption = (option: ColorOption,isSelected:any,  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const newSelectedLocation = isSelected
                ? this.state.selectedLocations.filter(
                    (selected) => selected.value !== option.value
                  )
                : [...this.state.selectedLocations, option];
              this.setState({ selectedLocations: newSelectedLocation });
              setFieldValue('selectedLocation', newSelectedLocation);
  }
  handleErrorText = (touched: boolean | undefined, error:any): string => {
    return touched && error ? error : '';
  };
  handleIconClick = () => {
    const inputElement = document.getElementById('hiddenFileInput') as HTMLInputElement;
    if (inputElement) inputElement.click();
  };
  handleProductIconClick = () => {
    const inputElement = document.getElementById('hiddenProductFileInput') as HTMLInputElement;
    if (inputElement) inputElement.click();
  };
  handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 9.9 * 1024 * 1024) {
        setFieldValue('selectedFileName', null);
        setFieldValue('sizeChart', '');
      } else {
        setFieldValue('selectedFileName', file);
        setFieldValue('sizeChart', file.name);
      }
    }
  };
  handleColorFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    index: number
  ) => {
    const file = event.target.files?.[0];
  
    if (file) {
      if (file.size <= 2 * 1024 * 1024) {
        setFieldValue(`addColor[${index}].uploadImage`, file);
      } else {
        alert("Please select a file smaller than 2MB.");
        setFieldValue(`addColor[${index}].uploadImage`, null);
      }
    }
  };
  
  serviceLoationApi = async() => {
    this.serviceLocationApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/service_locations",
      method: "GET",
      token: this.state.token,
    });
  }
  functionDataApi = async() => {
    this.functionDataApiCallId = await apiCall({
      endPoint: "bx_block_multipageforms2/functions",
      method: "GET",
      token: this.state.token,
    });
  }
  getToken = async () => {
    const token = await getStorageData("authToken");
    if (token) {
      this.setState({
        token,
      });
    } else {
      this.props.navigation.goBack();
    }
  };
  getProductCategoryApi = async () => {
    this.productCategoryApiCallId = await apiCall({
      endPoint: "bx_block_categories/categories",
      method: "GET",
      token: this.state.token,
    });
    }
    createSizeApi = async (values:FormDataOption, index:number) => {
      const formData = new FormData();
      formData.append("size_label", values.addSize[index].size);
      formData.append("measurement", values.addSize[index].measurements);
      this.createSizeApiCallId = await apiCall({
        endPoint: "bx_block_multipageforms2/product_sizes",
        method: "POST",
        token: this.state.token,
        body: formData,
      });
      }
      getAvailableSizeApi = async () => {
        this.availableSizeApiCallId = await apiCall({
          endPoint: "bx_block_multipageforms2/product_sizes",
          method: "GET",
          token: this.state.token,
        });
        }
        handleSizeDeleteImage = (setFieldValue: (field: string, value: any) => void) => {
         setFieldValue('selectedFileName', null);
         setFieldValue('sizeChart', '');
        };
        handleFileChangeProduct = (event: React.ChangeEvent<HTMLInputElement>, values:FormDataOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
          const files = Array.from(event.target.files || []);
          const newFiles = files.map((file) => ({ file, filename: file.name, url: URL.createObjectURL(file) }));
          setFieldValue("productImages", [...(values.productImages || []), ...newFiles]);
        };
        handleDeleteImage = (index: number, values:FormDataOption, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
          const updatedImages = values.productImages.filter((_: any, imgIndex: number) => imgIndex !== index);
          setFieldValue("productImages", updatedImages);
        };
  createAddColor = async (values:FormDataOption, index:number) => {
          const formData = new FormData();
          const colorData = values.addColor?.[index];
          formData.append("name", values.addColor[index].colorName);
          formData.append("size_id", values.addColor[index].colorSize);
          formData.append("available_quantity", values.addColor[index].availableQty);
          if (colorData.uploadImage) {
            formData.append("color_image", colorData.uploadImage);
          }
          this.createColorApiCallId = await apiCall({
            endPoint: "bx_block_multipageforms2/product_colors",
            method: "POST",
            token: this.state.token,
            body: formData,
          });
   }
   handleProceedApiCAll = async (values:FormDataOption) => {
if(this.state.IsEditing){
this.handleEditCatalogueApi(values)
}
else{
  this.handleCreateCatalogoueApi(values)
   }
  }
 handleCreateCatalogoueApi = async (values:FormDataOption) => {
          let formData = new FormData();
formData.append("product[name]",values.productName);
formData.append("product[description]",values.productDescription);
formData.append("product[fabric_details]",values.fabricDetails);
formData.append("product[price]",values.price);
formData.append("product[available_quantity]",values.availableQuantity);
formData.append("product[SKU]",values.skuCode);
formData.append("product[category_id]",values.productCategory);
formData.append("product[coupon_id]", values.coupons);
formData.append("product[product_variant]",JSON.stringify(this.state.productVariant));
if (Array.isArray(values.selectFunction)) {
  values.selectFunction.forEach((spec: any) => {
    formData.append("product[function_ids][]",spec.value);
  });
} 
if (Array.isArray(values.selectedLocation)) {
  values.selectedLocation.forEach((spec: any) => {
    formData.append("product[service_location_ids][]",spec.value);
  });
} 
  values.items.forEach((item: Item, index: number) => {
    formData.append(`product[custom_fields][${item.title}]`, item.description);
  });
  values.addQues.forEach((item: QuesSet, index: number) => {
    formData.append(`product[product_faqs_attributes][${index}][question]`, item.Questions);
  formData.append(`product[product_faqs_attributes][${index}][answer]`, item.answer);
   })
  if (values.selectedFileName) {
    formData.append("product[size_chart]", values.selectedFileName);
  }
  values.productImages.forEach((image:any, index:number) => {
    if (image?.file) {
      formData.append("product[media_files][]", image.file);
    }
});
  this.createProductApiCallId = await apiCall({
    endPoint: "bx_block_multipageforms2/products",
    method: "POST",
    token: this.state.token,
    body: formData,
  });
        }
      appendProductImagesToFormData= async(values:FormDataOption, formData: FormData) => {
        await Promise.all(
          values.productImages?.map(async (image:any) => {
            const sizeChartFile = await this.urlToFile(image.url, "size_chart.png", "image/png");
            if (sizeChartFile) {
              formData.append("product[media_files][]", sizeChartFile);
            }
          })
        );
        }
 handleEditCatalogueApi = async (values:FormDataOption) => {
          let formData = new FormData();
          let editId = await getStorageData("editId");

formData.append("product[name]", values.productName);

formData.append("product[available_quantity]", values.availableQuantity);
formData.append("product[category_id]", values.productCategory);
formData.append("product[description]",values.productDescription);
formData.append("product[fabric_details]", values.fabricDetails);
formData.append("product[SKU]", values.skuCode);
formData.append("product[price]", values.price);
formData.append("product[product_variant]",JSON.stringify(this.state.productVariant));
formData.append("product[coupon_id]", values.coupons);

if (Array.isArray(values.selectFunction)) {
  values.selectFunction.forEach( (spec: any) => {
    formData.append("product[function_ids][]", spec.value);
  });
} 
  if(values.productImages){
    await this.appendProductImagesToFormData(values, formData);
  }
  if (Array.isArray(values.selectedLocation)) {
    values.selectedLocation.forEach((spec: any) => {
      formData.append("product[service_location_ids][]", spec.value);
    });
  } 
    values.items.forEach((item: Item, index: number) => {
      formData.append(`product[custom_fields][${item.title}]`, item.description);
    });
values.addQues.forEach((item: QuesSet, index: number) => {
  if(item.id){
    formData.append(`product[product_faqs_attributes][${index}][id]`, item.id);
  }
  formData.append(`product[product_faqs_attributes][${index}][question]`, item.Questions);
formData.append(`product[product_faqs_attributes][${index}][answer]`, item.answer);
 })
    if (values.selectedFileName) {
      formData.append("product[size_chart]", values.selectedFileName);
    }
  this.editProductApiCallId = await apiCall({
    endPoint: `bx_block_multipageforms2/products/${editId}`,
    method: "PATCH",
    token: this.state.token,
    body: formData,
  });
        }
  getProductListApiCall = async () => {
          this.getProductListApiCallId = await apiCall({
            endPoint: "bx_block_multipageforms2/products",
            method: "GET",
            token: this.state.token,
          });
        }
  getCouponList = async () => {
          this.getCouponListApiCallID = await apiCall({
            endPoint: "bx_block_discount/checkout_addition_costs",
            method: "GET",
            token: this.state.token,
          });
        }
      // Customizable Area End
}
