export const leftArrow = require("../assets/left-arrow.svg");
export const rightArrow = require("../assets/right-arrow.svg");
export const workImage = require("../assets/work.svg");
export const categoryIcon = require("../assets/category.svg");
export const locationIcon = require("../assets/location.svg");
export const dropdownIcon = require("../assets/dropdown.svg");
export const uncheckImage = require("../assets/unchecked_checkbox.svg");
export const checkImage = require("../assets/checked_checkbox.svg");
export const backgroundDesign = require("../assets/background_image.png");
export const whoAreImage = require("../assets/who-are-image.jpeg");
export const whyChooseImage = require("../assets/why_choose_image.png");
export const howItWorkImage = require("../assets/how_it_work.svg");
export const vendorImage = require("../assets/vendor_image.png");
export const vendorImage2 = require("../assets/vendor_image_2.svg");
export const vendorImage3 = require("../assets/vendor_image_3.svg");
export const vendorImage4 = require("../assets/vendor_image_4.svg");
export const shopImag1 = require("../assets/shop_1.png");
export const shopImag2 = require("../assets/shop_2.png");
export const shopImag3 = require("../assets/shop_3.png");
export const shopImag4 = require("../assets/shop_4.png");
export const rightArrowButton = require("../assets/right_arrow.svg");
export const contactUsBackground = require("../assets/contact-us-background.png");
export const realWedding1 = require("../assets/real-wedding-1.png");
export const realWedding2 = require("../assets/real-wedding-2.png");
export const realWedding3 = require("../assets/real-wedding-3.png");
export const realWedding4 = require("../assets/real-wedding-4.png");
export const doNotWorkImage = require("../assets/don't_work_image.png");
export const ratingStar = require("../assets/rating-star.svg");
export const userImage = require("../assets/user-image.png");
export const minusImage = require("../assets/minus.svg");
export const plusImage = require("../assets/plus.svg");
