import React from "react";

// Customizable Area Start
import {
  Box, Typography, Stack, Avatar, InputLabel, styled, Chip, Button, TextField, InputAdornment, IconButton, Autocomplete, MenuItem, Checkbox, ListItemText,
} from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import HeaderVendorFooter from "../../../../packages/components/src/HeaderVendorFooter";
import TextInputFiled from "../../../components/src/TextInputField.web";
import { Formik, FormikErrors, FieldArray, FormikTouched, Form } from "formik";
import * as Yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import SelectField from "../../../../packages/components/src/SelectField.web";
export type touched = boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
export type errors = string | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined
interface ColorOption {
  label: string;
  value: number;
}
interface Link {
  link: string
}
interface Product {
  product: string
}
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleOnChangeCountry = (value : any) =>{
    this.setState((prevState)=>{
     return  {
      ...prevState,
      formData:{
        ...prevState.formData,
        country:value
      }
    }
  }, () =>{
    this.getAllStateApi(value);
    this.setState({searchTerm : ""})
  })
  }

  helperText = (
    touched: touched,
    errors: errors,
    isValid: boolean = false,
    errorMessage = ""
  ) => {
    return isValid ? errorMessage : touched && errors;
  }
 
  handleOnChangeCity = (value : any) =>{
    this.setState((prevState)=>{
      return  {
       ...prevState,
       formData:{
         ...prevState.formData,
         state:value
       }
      }
     }, () =>{
       this.getCityApiCall(this.state.formData.country,value);
       this.setState({searchTerm : ""})
     })
  }

  handleSelectCityData=(value:any)=>{
    this.setState((prevState)=>{
      return  {
       ...prevState,
       formData:{
         ...prevState.formData,
         city:value
       }
      }
     })
  }
  

  handleDescriptionBox = (values: any, setFieldValue: any, handleBlur: any, touched: FormikTouched<any>, errors: any, handleChange: any) => {
    const sortingCountrycode = this.state.phoneNumberData.sort((a:any,b:any) => a.label-b.label)
    return (
      <Box sx={webstyle.description}>
        <Box sx={webstyle.innerDescription}>
          <InputLabel className="labelClass" style={webstyle.inputText}>Primary contact number<span style={webstyle.star}>*</span></InputLabel>
          <Box sx={webstyle.formInput2}>
            <SelectField sx={{ width: "70px" }}
              placeholder="+91"
              label=""
              data-test-id="primaryContactSelectField"
              name="phoneId"
              options={sortingCountrycode.map((data: any) => ({
                label: data.value,
                value: data.label,
              }))}
              isDisabled={!this.state.isEditing}
              setFieldValue={setFieldValue} value={values && values.phoneId} helperText={undefined} handleBlur={handleBlur} />
            <TextInputFiled sx={{ width: { xs: "100%", sm: "22vw", md: "80%", lg: "11vw" } }}
              name="primaryContact"
              data-test-id="primary-contact"
              placeholder="Enter primary contact number"
              value={values && values.primaryContact}
              handleChange={handleChange}
              handleBlur={handleBlur}
              disabled={!this.state.isEditing}
              type="text"
              error={this.isError(touched && touched.primaryContact,errors && errors.primaryContact)}
              helperText={this.handleErrorText(touched && touched.primaryContact, errors && errors.primaryContact) ? errors.primaryContact as string : ""}
            />
          </Box>
        </Box>
        <Box sx={webstyle.innerDescription}>
          <InputLabel className="labelClass" style={webstyle.inputText}>Secondary contact number<span style={webstyle.star}>*</span></InputLabel>
          <Box sx={webstyle.formInput2}>
            <SelectField sx={{ width: "79px" }}
              placeholder="+91"
              label=""
              name="phoneIdNo"
              options={sortingCountrycode.map((data: any) => ({
                label: data.value,
                value: data.label,
              }))??[]}
              isDisabled={!this.state.isEditing}
              setFieldValue={setFieldValue} value={values && values.phoneIdNo} helperText={undefined} handleBlur={handleBlur} />
            <TextInputFiled
              sx={{ width: { xs: "100%", sm: "23vw", md: "24.5vw", lg: "16vw" } }}
              name="secondaryNumber"
              data-test-id="full-name-input"
              placeholder="Enter secondary contact number"
              value={values && values.secondaryNumber}
              handleChange={handleChange}
              disabled={!this.state.isEditing}
              handleBlur={handleBlur}
              type="text"
              error={this.isError(touched && touched.secondaryNumber, errors && errors.secondaryNumber)}
              helperText={
                this.handleErrorText(touched?.secondaryNumber, errors?.secondaryNumber)
                  ? (errors.secondaryNumber as string)
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                    >
                      <Button style={{ color: "#7F1187", fontSize: "16px", fontFamily: "poppins", fontWeight: 400, textTransform: "none" }}>Verify</Button>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Box>
      </Box>
    )
  }

  businessDetailsBox = (values: any, setFieldValue: any, handleBlur: any, touched: any, errors: any, handleChange: any,) => {   
    console.log(values)
    console.log(setFieldValue)       
    return (
      <Box data-test-id="detailsBox">
        <Typography style={{ fontSize: "16px", fontFamily: "poppins", fontWeight: 700, paddingBottom: "1rem" }}>Business Details:</Typography>
            <InputLabel className="labelClass" style={webstyle.inputText}>Service Provided<span style={webstyle.star}>*</span></InputLabel>
                    <div style={{marginBottom : "5px"}}>
                      <Autocomplete
                        multiple sx = {{ width: { xs: "100%", sm: "90%", md: "80%", lg: "43vw" }}}
                        id="tags-checkbox"
                        options={this.state.serviceData || []}
                        value={this.state.selectedServices || []}
                        data-test-id="handleSelectServiceChange"
                        onChange={(event, newValue) => {
                          this.handleSelectServiceChange(newValue, setFieldValue)
                        }}
                        disableCloseOnSelect
                        renderTags={() => null}
                        renderOption={(props, option: ColorOption) => {
                          const isSelected = Array.isArray(this.state.selectedServices) && this.state.selectedServices.some(selected => selected.value == option.value);
                          return (
                            <MenuItem
                              {...props}
                              key={option && option.value}
                              data-test-id = "menuItemsService"
                              onClick={() => {
                                this.toggleServiceOption(option);
                                this.handleServiceOption(option, isSelected, setFieldValue);
                              }}
                              style={{ borderBottom: '1px solid #E7E5E4' }}
                            ><Checkbox checked={isSelected} style={{ marginRight: 8 }} />
                              <ListItemText
                                primary={
                                  option && option.label}
                              /></MenuItem>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            data-test-id="handleChangeService"
                            placeholder="Select Products"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values && values.selectedService}
                            error={this.isError(touched?.selectedService, errors?.selectedService)}
                            helperText={this.helperText(
                              touched?.selectedService,
                              errors?.selectedService
                            )}
                          />
                        )}
                      />
                    </div>

                    <Stack direction="row" spacing={1} className="chipBox" style={{marginBottom : "15px"}}>
                      {this.state.selectedServices && Array.isArray(this.state.selectedServices) && this.state?.selectedServices.map((option) => (
                        <Chip
                          key={option.value}
                          label={option.label}
                          style={{ margin: '4px' }}
                          data-test-id="handleDeleteServiceChip"
                          disabled={!this.state.isEditing}
                          onDelete={() => this.handleDeleteServiceChip(option, setFieldValue)}
                          deleteIcon={
                            <IconButton size="small">
                              <CloseIcon fontSize="small" className="closeIcon" />
                            </IconButton>
                          }

                        />
                      ))}
                    </Stack>

        <InputLabel className="labelClass" style={webstyle.inputText}>Description</InputLabel>
        <TextInputFiled sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "43vw" } }}
          name="description"
          data-test-id="full-name-input"
          placeholder="vibrant colors, luxurious fabrics, and intricate craftsmanship.."
          value={values && values.description}
          disabled={!this.state.isEditing}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type="text"
          error={this.isError(touched?.description, errors?.description)}
          helperText={this.handleErrorText(touched?.description, errors?.description) ? errors?.description as string : ""}
        />
        <FieldArray name="addProduct" data-test-id="FieldArray1">
          {({ push, remove }: { push: (obj: Product) => void; remove: (index: number) => void }) => (
            <>
              <Box className="faqAskSection">
                <Typography className="labelClass" style={webstyle.inputText}>Products Offered</Typography>
                <Button className="quesBtn" data-test-id="addSizeClick" onClick={() => push({ product: "" })} style={{ color: "#7F1187", fontSize: "14px", fontFamily: "poppins", fontWeight: 600, textTransform: "none" }} >Add</Button>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                {values.addProduct.map((_: any, index: number) => (
                  <Box className="inputFirstSection">
                    <Box className="inputFieldsSection">
                      <TextField
                        name={`addProduct[${index}].product`}
                        placeholder="Select Category"
                        data-test-id="productField"
                        value={values && values.addProduct[index].product}
                        disabled={!this.state.isEditing}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx= {{ width: { xs: "100%", sm: "90%", md: "80%", lg: "43vw" } }}
                        type="text"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CloseIcon fontSize="small" sx={{ color: "black", cursor: "pointer" }} data-test-id="removeProduct" onClick={() => this.removeproduct(index, setFieldValue, values)} />
                            </InputAdornment>
                          ),
                        }}
                        error={
                          Boolean(
                            touched?.addProduct?.[index]?.product &&
                            (errors.addProduct?.[index] as FormikErrors<Product>)?.product
                          )
                        }
                        helperText={
                          touched?.addProduct?.[index]?.product &&
                          (errors.addProduct?.[index] as FormikErrors<Product>)?.product
                        }
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </FieldArray>
      </Box>
    )

  }

  paymentAndBankingBox = (values: any, setFieldValue: any, handleBlur: any, touched: FormikTouched<any>, errors: any, handleChange: any) => {
    return (
      <Box>
        <Typography style={{ fontSize: "16px", fontFamily: "poppins", fontWeight: 700, padding: "1rem 0rem" }}>Payment and Banking:</Typography>
        <InputLabel className="labelClass" style={webstyle.inputText}>Payment and Banking<span style={webstyle.star}>*</span></InputLabel>
        <TextInputFiled sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "43vw" } }}
          name="paymentMethod"
          data-test-id="full-name-input"
          placeholder="State Bank of India"
          value={values && values.paymentMethod}
          disabled={!this.state.isEditing}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type="text"
          error={this.isError(touched?.paymentMethod, errors?.paymentMethod)}
          helperText={this.handleErrorText(touched?.paymentMethod, errors?.paymentMethod) ? errors.paymentMethod as string : ""}
        />

        <InputLabel className="labelClass" style={webstyle.inputText}>Bank Account Details<span style={webstyle.star}>*</span></InputLabel>
        <TextInputFiled sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "43vw" } }}
          name="bankAccountDetails"
          data-test-id="full-name-input"
          placeholder="446XXXXXXXXX90"
          value={values && values.bankAccountDetails}
          disabled={!this.state.isEditing}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type="text"
          error={this.isError(touched?.bankAccountDetails, errors?.bankAccountDetails)}
          helperText={this.handleErrorText(touched?.bankAccountDetails, errors?.bankAccountDetails) ? errors?.bankAccountDetails as string : ""}
        />


        <Box sx={webstyle.formInput}>
          <Box sx={webstyle.innerDescription}>
            <InputLabel className="labelClass" style={webstyle.inputText}>IFSC code<span style={webstyle.star}>*</span></InputLabel>
            <TextInputFiled sx={{ width: { xs: "35vw", sm: "35.5vw", md: "33vw", lg: "20.5vw" } }}
              name="ifscCode"
              data-test-id="full-name-input"
              placeholder="SBIN657646"
              value={values && values.ifscCode}
              disabled={!this.state.isEditing}
              handleChange={handleChange}
              handleBlur={handleBlur}
              type="text"
              error={this.isError(touched?.ifscCode, errors?.ifscCode)}
              helperText={this.handleErrorText(touched?.ifscCode, errors?.ifscCode) ? errors?.ifscCode as string : ""}
            />
          </Box>
          <Box sx={webstyle.innerDescription}>
            <InputLabel className="labelClass" style={webstyle.inputText}>Branch Name<span style={webstyle.star}>*</span></InputLabel>
            <TextInputFiled sx={{ width: { xs: "34vw", sm: "35.5vw", md: "33vw", lg: "20.5vw" } }}
              name="branchName"
              data-test-id="full-name-input"
              placeholder="Andheri east"
              value={values && values.branchName}
              disabled={!this.state.isEditing}
              handleChange={handleChange}
              handleBlur={handleBlur}
              type="text"
              error={this.isError(touched?.branchName, errors?.branchName)}
              helperText={this.handleErrorText(touched?.branchName, errors?.branchName) ? errors?.branchName as string : ""}
            />
          </Box>
        </Box>
      </Box>
    )
  }

  isError = (
    touched: touched,
    errors: errors,
    isValid: boolean = false
  ) => {
    return isValid ? isValid : touched && !!errors;
  };

  handleErrorText = (touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined, errors: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined,) => {
    return touched && errors
  }

  getHelperText = (isTouched: any, errors: any) => (isTouched && errors ? errors : "");

  handleTextField = (values: any, handleChange: any, handleBlur: any, touched: any, errors: any) => {
    return (<TextInputFiled sx={{ width: { xs: "35vw", sm: "36vw", md: "32vw", lg: "20.5vw" } }}
      name="primaryContactPersonName"
      data-test-id="full-name-input"
      placeholder="Enter your primary name"
      value={values && values.primaryContactPersonName}
      handleChange={handleChange}
      handleBlur={handleBlur}
      disabled={!this.state.isEditing}
      type="text"
      error={this.isError(touched && touched.primaryContactPersonName, errors && errors.primaryContactPersonName)}
      helperText={this.handleErrorText(touched && touched.primaryContactPersonName, errors && errors.primaryContactPersonName) ? errors.primaryContactPersonName as string : ""}
    />)
  }

  cityNames = () => {
    return ([
      { label: 'Delhi', value: 1 },
      { label: 'Jaipur', value: 2 },
      { label: 'Noida', value: 3 },
      { label: 'Lukhnow', value: 4 },
      { label: 'Mumbai', value: 5 },
    ])
  };
  bankAccountDetails = () => {
    return [
      { label: 'Account Holder Name', value: 1 },
      { label: 'Account Number', value: 2 },
      { label: 'Bank Name', value: 3 },
      { label: 'IFSC Code', value: 4 },
      { label: 'Account Type', value: 5 },
    ];
  };


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HeaderVendorFooter navigation={this.props.navigation}>
        <CustomBox sx={webstyle.mainContainer}>
          <Box sx={webstyle.customBox}>
            <Typography style={{ fontFamily: "poppins", fontSize: "20px", fontWeight: 600, padding: "1.5rem" }}>Business Settings</Typography>
            <Box sx={webstyle.upperSettingHeader}>
              <Box sx={webstyle.settingsHeader}>
                <div>
                  <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                    <Avatar
                      src={this.state.uploadImage ? URL.createObjectURL(this.state.uploadImage) : undefined}
                      style={{
                        width: '132px',
                        height: '132px',
                        color: '#A666FF',
                        background: '#F0E5FF',
                        fontSize: '56px',
                        fontWeight: 600,
                      }}
                    >
                      {!this.state.uploadImage && 'H'}
                    </Avatar>
                  </label>

                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="file-upload"
                    type="file"
                    data-test-id="handleImageChange"
                    onChange={this.handleImageChange}
                    disabled={!this.state.isEditing}
                  />
                </div>
                <Typography style={{ fontSize: "16px", fontWeight: 500, fontFamily: "poppins" }}>{this.state.showName}</Typography>
              </Box>
              <Box>
              {!this.state.isEditing && 
              <Box sx={webstyle.flexInput} onClick={this.handleEdit} data-test-id="handleEdit">
                <Typography style={{ fontFamily: "poppins", fontSize: "16px", fontWeight: 600, color: "#801187" }}>Edit</Typography>
                <CreateIcon style={{ color: "#801187" }} />
              </Box>
              }
              </Box>
            </Box>
            <Formik
              data-test-id="Formik"
              initialValues={this.state.formData}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.handleEditApiCall(values)
              }}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate style={{ padding: "2rem", gap: "0.6rem" }}>
                    <Typography style={{ fontSize: "16px", fontFamily: "poppins", fontWeight: 700, paddingBottom: "1rem" }}>
                      Basic Information:
                    </Typography>
                    <InputLabel className="labelClass" style={webstyle.inputText}>Business/Individual Name <span style={webstyle.star}>*</span></InputLabel>
                    <TextInputFiled sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "43vw" } }}
                      name="businessName"
                      data-test-id="full-name-input"
                      placeholder="Enter your business name"
                      value={values && values.businessName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      disabled={!this.state.isEditing}
                      error={this.isError(touched && touched.businessName, errors?.businessName)}
                      helperText={this.handleErrorText(touched && touched.businessName, errors?.businessName) ? errors.businessName as string : ""}
                    />
                    <Box sx={webstyle.formInput}>
                      <Box>
                        <InputLabel className="labelClass" style={webstyle.inputText}>GST Number <span style={webstyle.star}>*</span></InputLabel>
                        <TextInputFiled sx={{ width: { xs: "34vw", sm: "35.5vw", md: "33vw", lg: "20.5vw" } }}
                          name="gstNumber"
                          data-test-id="full-name-input"
                          placeholder="Enter your GST number"
                          value={values && values.gstNumber}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          type="text"
                          disabled={!this.state.isEditing}
                          error={this.isError(touched?.gstNumber, errors?.gstNumber)}
                          helperText={this.handleErrorText(touched?.gstNumber, errors?.gstNumber) ? errors.gstNumber as string : ""}
                        />
                      </Box>
                      <Box>
                        <InputLabel className="labelClass" style={webstyle.inputText}>Primary Contact Person Name <span style={webstyle.star}>*</span></InputLabel>
                        {this.handleTextField(values, handleChange, handleBlur, touched, errors)}
                      </Box>

                    </Box>
                    <InputLabel className="labelClass" style={webstyle.inputText}>Email<span style={webstyle.star}>*</span></InputLabel>
                    <TextInputFiled sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "43vw" } }}
                      name="email"
                      data-test-id="full-name-input"
                      placeholder="Enter your email"
                      value={values && values.email}
                      disabled={!this.state.isEditing}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched?.email, errors?.email)}
                      helperText={this.handleErrorText(touched?.email, errors?.email) ? errors?.email as string : ""}
                    />
                    {this.handleDescriptionBox(values, setFieldValue, handleBlur, touched, errors, handleChange)}
                    <InputLabel className="labelClass" style={webstyle.inputText}>Address<span style={webstyle.star}>*</span></InputLabel>
                    <TextInputFiled sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "43vw" } }}
                      name="address"
                      data-test-id="full-name-input"
                      placeholder="Enter your complete address"
                      value={values && values.address}
                      handleChange={handleChange}
                      disabled={!this.state.isEditing}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched?.address, errors?.address)}
                      helperText={this.handleErrorText(touched?.address, errors?.address) ? errors.address as string : ""}
                    />
                    <Box sx={webstyle.formInput}>
                      <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>Country<span style={webstyle.star}>*</span></InputLabel>
                        <SelectField sx={{ width: { xs: "35vw", sm: "35.5vw", md: "33vw", lg: "20.5vw" } }}
                          name="country"
                          data-test-id="getAllStateApi"
                          value={this.state.formData && this.state.formData.country || ""}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          options={this.state.countryData && this.state.countryData.filter((country: any) => country?.label?.toLowerCase().startsWith(this.state.searchTerm.toLowerCase()))}
                          isDisabled={!!!this.state.isEditing}
                          placeholder="India"
                          onChange={(e) => {
                            this.handleOnChangeCountry(e)
                          }}
                          onKeyDown={this.handleSearckKeyDown}
                          helperText={this.handleErrorText(touched?.country, errors?.country) ? errors?.country as string : ""}
                          label={""}
                          error={Boolean(this.handleErrorText(touched?.country, errors?.country))}
                          touched={touched?.country}
                        >
                        </SelectField>
                      </Box>

                      <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>State<span style={webstyle.star}>*</span></InputLabel>
                        <SelectField sx={{ width: { xs: "34vw", sm: "35.5vw", md: "33vw", lg: "20.5vw" } }}
                          name="state"
                          data-test-id="getCityApiCall"
                          value={this.state.formData && this.state.formData.state || ""}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          isDisabled={!this.state.isEditing || values.country == ""}
                          options={this.state.stateData ? this.state.stateData?.filter((state: any) => state?.label?.toLowerCase().startsWith(this.state.searchTerm.toLowerCase())) : []}
                          onChange= {(e:any) => this.handleOnChangeCity(e)}
                          placeholder="Maharashtra"
                          onKeyDown={this.handleSearckKeyDown}
                          helperText={this.handleErrorText(touched?.state, errors?.state) ? errors?.state as string : ""}
                          label={""}
                          error={Boolean(this.handleErrorText(touched?.state, errors?.state))}
                          touched={touched?.state}
                        />
                      </Box>
                    </Box>
                    <Box sx={webstyle.formInput}>
                      <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>City<span style={webstyle.star}>*</span></InputLabel>
                        <SelectField sx={{ width: { xs: "100px", sm: "35.5vw", md: "33vw", lg: "20.5vw" } }}
                          name="city"
                          data-test-id="monthCheck"
                          value={this.state.formData?.city}
                          setFieldValue={setFieldValue}
                          isDisabled={!this.state.isEditing || values.state == ""}
                          handleBlur={handleBlur}
                          onChange={(e:any)=>this.handleSelectCityData(e)}
                          onKeyDown={this.handleSearckKeyDown}
                          options={this.state.cityData ? this.state.cityData?.filter((city: any) => city?.label?.toLowerCase().startsWith(this.state.searchTerm.toLowerCase())) : []}
                          placeholder="Mumbai"
                          helperText={this.handleErrorText(touched?.city, errors?.city) ? errors?.city as string : ""}
                          label={""}
                          error={Boolean(this.handleErrorText(touched?.city, errors?.city))}
                          touched={touched?.city}
                        />
                      </Box>
                      <Box sx={webstyle.innerDescription}>
                        <InputLabel className="labelClass" style={webstyle.inputText}>Pin Code<span style={webstyle.star}>*</span></InputLabel>
                        <TextInputFiled sx={{ width: { xs: "35vw", sm: "35.5vw", md: "33vw", lg: "20.5vw" } }}
                          name="pincode"
                          data-test-id="monthCheck"
                          placeholder="Enter your pincode"
                          value={values && values.pincode}
                          handleChange={handleChange}
                          disabled={!this.state.isEditing}
                          handleBlur={handleBlur}
                          type="text"
                          error={this.isError(touched?.pincode, errors?.pincode)}
                          helperText={this.handleErrorText(touched?.pincode, errors?.pincode) ? errors?.pincode as string : ""}
                        />
                      </Box>
                    </Box>
                    <InputLabel className="labelClass" style={webstyle.inputText}>Service Location<span style={webstyle.star}>*</span></InputLabel>
                    <div>
                      <Autocomplete
                        multiple sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "43vw" }}}
                        id="tags-checkbox"
                        options={this.state.locationData || []}
                        value={this.state.selectedLocations || []}
                        data-test-id="handleSelectLocationChange"
                        onChange={(event, newValue) => {
                          this.handleSelectLocationChange(newValue, setFieldValue)
                        }}
                        disableCloseOnSelect
                        disabled={!this.state.isEditing}
                        renderTags={() => null}
                        renderOption={(props, option: ColorOption) => {
                          const isSelected = Array.isArray(this.state.selectedLocations) && this.state.selectedLocations?.some(selected => selected.value == option.value);
                          return (
                            <MenuItem
                              {...props}
                              key={option?.value}
                              onClick={() => {
                                this.toggleLocationOption(option);
                                this.handleLocationOption(option, isSelected, setFieldValue);
                              }}
                              style={{ borderBottom: '1px solid #E7E5E4' }}
                            ><Checkbox checked={isSelected} style={{ marginRight: 8 }} />
                              <ListItemText
                                primary={
                                  option?.label}
                              /></MenuItem>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            data-test-id="handleChangeLocation"
                            placeholder="Select the location where you can deliver your products for Outfit"
                            onChange={(event) => handleChange(event)}
                            onBlur={handleBlur}
                            value={values && values.selectedLocation}
                            error={this.isError(touched && touched.selectedLocation,errors && errors.selectedLocation)}
                            helperText={this.helperText(
                              touched && touched.selectedLocation,
                              errors && errors.selectedLocation
                            )}
                          />
                        )}
                      />
                    </div>
                    <Stack direction="row" spacing={1} className="chipBox">
                      {this.state.selectedLocations && Array.isArray(this.state.selectedLocations) && this.state?.selectedLocations.map((option) => (
                        <Chip
                          key={option.value}
                          label={option.label}
                          style={{ margin: '4px' }}
                          data-test-id="handleDeleteLocationChip"
                          disabled={!this.state.isEditing}
                          onDelete={() => this.handleDeleteLocationChip(option, setFieldValue)}
                          deleteIcon={
                            <IconButton size="small">
                              <CloseIcon fontSize="small" className="closeIcon" />
                            </IconButton>
                          }

                        />
                      ))}
                    </Stack>
                    <InputLabel className="labelClass" style={{ paddingTop: "1rem", fontSize: "14px", fontWeight: 500, fontFamily: "poppins", color: "#334155" }}>Portfolio link (optional)</InputLabel>
                    <TextInputFiled sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "43vw" } }}
                      name="portfolioLink"
                      data-test-id="full-name-input"
                      placeholder="htttp://portfolio.srivari.co.in"
                      handleChange={handleChange}
                      value={values && values.portfolioLink}
                      disabled={!this.state.isEditing}
                      handleBlur={handleBlur}
                      type="text"
                      error={this.isError(touched && touched.portfolioLink, errors?.portfolioLink)}
                      helperText={this.handleErrorText(touched?.portfolioLink, errors?.portfolioLink) ? errors?.portfolioLink as string : ""}
                    />

                    <FieldArray name="addLink" data-test-id="FieldArray2">
                      {({ push, remove }: { push: (obj: Link) => void; remove: (index: number) => void }) => (
                        <div style={{marginBottom : "15px"}}>
                          <Box className="faqAskSection">
                            <Typography className="labelClass" style={webstyle.inputText}>Social Media Link</Typography>
                            <Button className="quesBtn" data-test-id="addSizeClick" onClick={() => push({ link: "" })} style={{ color: "#7F1187", fontSize: "14px", fontFamily: "poppins", fontWeight: 600, textTransform: "none" }}>Add</Button>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 3}}>
                            {values?.addLink.map((_, index) => (
                              <Box className="inputFirstSection">
                                <Box className="inputFieldsSection">
                                  <TextField
                                    name={`addLink[${index}].link`}
                                    placeholder="Instagram account link"
                                    data-test-id="linkField"
                                    value={values && values.addLink[index].link}
                                    onChange={handleChange}
                                    disabled={!this.state.isEditing}
                                    onBlur={handleBlur}
                                    sx= {{ width: { xs: "100%", sm: "90%", md: "80%", lg: "43vw" } }}
                                    type="text"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <CloseIcon fontSize="small" data-test-id="removeLink" sx={{ color: "black", cursor: "pointer" }} onClick={() => this.removeLink(index, setFieldValue, values)} />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={
                                      Boolean(
                                        touched?.addLink?.[index]?.link &&
                                        (errors.addLink?.[index] as FormikErrors<Link>)?.link
                                      )
                                    }
                                    helperText={
                                      touched?.addLink?.[index]?.link &&
                                      (errors?.addLink?.[index] as FormikErrors<Link>)?.link
                                    }
                                  />
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </div>
                      )}
                    </FieldArray>
                    {this.businessDetailsBox(values, setFieldValue, handleBlur, touched, errors, handleChange)}
                    {this.paymentAndBankingBox(values, setFieldValue, handleBlur, touched, errors, handleChange)}
                    <Box sx={webstyle.saveButton}>
                      <Button sx={this.state.isEditing ? webstyle.saveClick : webstyle.saveBtn} type="submit" disabled={!this.state.isEditing} data-test-id="handleEditApiCall" >Save changes</Button>
                    </Box>
                  </Form>
                )
              }}
            </Formik>
          </Box>
        </CustomBox>
      </HeaderVendorFooter>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webstyle = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  customChip: {
    display: "flex",
    gap: "12px"
  },
  upperSettingHeader: {
    display: "flex",
    justifyContent: "space-between"
  },
  saveButton: {
    padding: "2rem 1rem"
  },
  saveBtn: {
    width: "172px",
    height: "44px",
    borderRadius: "33px",
    background: "#F1F5F9",
    color: "#64748B",
    textTransform: "none",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "poppins"
  },
  saveClick: {
    width: "172px",
    height: "44px",
    borderRadius: "33px",
    background: "#801187",
    color: "#FFFFFF",
    textTransform: "none",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "poppins",
    "&:hover": {
      background: "#801187",

    }
  },
  flexInput: {
    display: "flex",
    paddingRight: "2rem",
    gap: "12px",
    cursor: "pointer",
  },
  star: {
    color: "red"
  },
  settingsHeader: {
    display: "flex",
    alignItems: "center",
    gap: "18px",
    paddingLeft: "1.5rem"
  },
  formInput: {
    display: "flex",
    gap: "32px",
    alignItems: "center"
  },
  inputText: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "poppins",
    color: "#334155",
    marginBottom : "5px"
  },
  formInput2: {
    display: "flex",
    gap: "16px",
    alignItems: "center"
  },
  description: {
    display: "flex",
    alignItems: "center",
    gap: "32px"
  },
  innerDescription: {
    display: "flex",
    flexDirection: "column"
  },
  customBox: {
    width: "90vw",
    height: "auto",
    border: "1px solid lightgrey",
    borderRadius: "8px"
  },
  settingsDetals: {
    padding: "1.5rem"
  },
  imageBox: {
    height: "25%",
    width: "10%",
    borderRadius: "50%",
    border: "1px solid black"
  }
}
const CustomBox = styled(Box)({
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: "0px",
    borderBottom: "1px",
    borderRight: "0px",
    borderTop: "0px",
    borderStyle: "solid",
    borderColor: "#E2E8F0",
  },
  "& .MuiInputBase-input": {
    padding: "10.5px 8px ",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#0F172A",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#A8A29E",
    opacity: 1,
  },
  "& .faqAskSection": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    
  },
  "& .MuiChip-root": {
    border: "2px solid #CA1A7D",
    backgroundColor: "transparent",
    padding: '6px, 8px, 6px, 12px'
  },
  "& .MuiChip-label": {
    color: "#292524",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    textTransform: "capitalize"
  },
  "& .closeIcon": {
    color: "black"
  },
})


const validationSchema = Yup.object().shape({
  businessName: Yup.string()
    .required('Business name is required')
    .min(2, "Enter a valid Business Name")
    .max(50, "Enter a valid Business Name")
    .matches(/^[a-zA-Z0-9\s]*$/, 'Business name cannot contain special characters')
    .nullable(),

  gstNumber: Yup.string()
    .required('GST number is required')
    .matches(/^\d{2}[A-Z]{5}\d{4}[A-Z][1-9A-Z]Z[0-9A-Z]$/, 'Enter a valid GST number')
    .nullable(),

  primaryContactPersonName: Yup.string()
    .required('Primary contact person name is required')
    .min(2, 'Invalid Primary Contact Person Name')
    .max(50, 'Invalid Primary Contact Person Name')
    .matches(/^[A-Za-z\s]+$/, 'Invalid Primary Contact Person Name')
    .nullable(),

  email: Yup.string()
    .required('Email is required')
    .matches(/^[A-Za-z0-9]+@[A-Za-z]+\.[cC][oO][mM]$/, 'Invalid email format. Example: example1@domain.com')
    .nullable(),

  primaryContact: Yup.string()
    .required('Primary contact number is required')
    .matches(/^\d+$/,'Only numbers are allowed')
    .min(8, 'Enter a valid Primary Contact Number')
    .max(13, 'Enter a valid Primary Contact Number')
    .nullable(),

  secondaryNumber: Yup.string()
  .matches(/^\d+$/,'Only numbers are allowed')
    .min(8, 'Enter a valid Secondary Contact Number')
    .max(13, 'Enter a valid Secondary Contact Number')
    .notRequired()
    .nullable(),

  address: Yup.string()
    .required('Address is required')
    .max(100, 'Enter a valid Address')
    .nullable(),

  city: Yup.string()
    .required('City is required')
    .nullable(),

  state: Yup.string()
    .required('State is required')
    .nullable(),

  ifscCode: Yup.string()
    .required('IFSC Code is required')
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC Code format')
    // .nullable()
    ,

  branchName: Yup.string()
    .required('Branch Name is required.')
    .matches(/^[A-Za-z0-9\s]+$/, 'Branch Name must contain only characters and spaces.')
    // .nullable()
    ,

  country: Yup.string()
    .required('Country is required')
    .nullable(),

  pincode: Yup.string()
    .required('Pin Code is required')
    .matches(/^[1-9]\d{5}$/, 'Invalid Pin Code')
    .nullable(),

  portfolioLink: Yup.string()
    .url('Invalid URL format')
    .notRequired()
    .nullable()
    ,

  addLink: Yup.array().of(
    Yup.object().shape({
      link: Yup.string()
        .url('Invalid URL format')
        .notRequired(),
    })
  ),
  paymentMethod: Yup.string()
    .required('Payment Method is required')
    // .nullable()
    ,

  bankAccountDetails: Yup.string()
    .required('Bank Account Detail is required')
    // .nullable()
    ,

  productOffered: Yup.array().of(
      Yup.object().shape({
        product: Yup.string()
          .notRequired(),
      })
    ),
});

// Customizable Area End
