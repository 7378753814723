Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.httpPatchMethod = "PATCH"
exports.getUserProfileEndpoint = "account_block/accounts/id"
exports.userProfileEndpoint ="/account_block/accounts/update"
exports.btnExampleTitle = "CLICK ME";
exports.getCountryCodeEndpoint = 'account_block/accounts/country_code_and_flags/fetch_countries'
// Customizable Area End