import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  styled,
  Typography,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { happi_ffie_image } from "./assets";

// Customizable Area End

import WelcomeController, { Props } from "./WelcomeController";
import LoginSideImageWrapper from "../../../components/src/LoginSideImageWrapper.web";

export default class ShowVerification extends WelcomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginSideImageWrapper isLoading={this.state.isLoading}  carouselImages={this.state.carouselListItem}>
        <ScrollableContentBox data-test-id="ScrollableContentBox">
          <Box className="sign-up-form-container">
            <img
              src={happi_ffie_image}
              alt="wedding"
              className="sign-up-main-image"
            />
            <Typography className="verify-email-text">
              Welcome to Happiffie
            </Typography>
            <Typography className="verify-email-description">
              Your dream wedding begins here. Explore our personalized planning
              tools and resources to create a magical and unforgettable
              celebration.
            </Typography>

            <SignUpButton
              data-test-id="continue-button"
              fullWidth
              onClick={this.redirectToHomePage}
            >
              Continue
            </SignUpButton>
          </Box>
        </ScrollableContentBox>
      </LoginSideImageWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ScrollableContentBox = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  boxSizing: "border-box",
  padding: "98px 98px 40px",
  width: "50%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    overflowY: "unset",
    width: "100%",
    height: "auto",
    minHeight: "fit-content",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "60px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "30px",
  },
  "& .sign-up-form-container": {
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      maxWidth: "600px",
    },
  },
  "& .sign-up-main-image": {
    width: "428px",
    height: "237px",
    [theme.breakpoints.down("md")]: {
      height: "unset",
      width: "100%",
    },
  },
  "& .verify-email-text": {
    fontFamily: "Poppins",
    margin: "40px 0 24px",
    fontSize: "24px",
    fontWeight: "600",
    color: "#000000",
    lineHeight: "30px",
    textAlign: "center",
  },
  "& .verify-email-description": {
    fontFamily: "Poppins",
    textAlign: "center",
    lineHeight: "27px",
    color: "#000000",
    fontSize: "18px",
    fontWeight: "400",
    "& span": {
      fontWeight: "600",
    },
  },
}));

const SignUpButton = styled(Button)({
  marginTop: "81px",
  border: `1px solid #801187`,
  fontSize: "16px",
  backgroundColor: "#801187",
  fontFamily: "Poppins",
  fontWeight: "400",
  borderRadius: "8px",
  padding: "19px 20px",
  textTransform: "unset",
  lineHeight: "24px",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#801187",
  },
});
// Customizable Area End
