// Customizable Area Start
import React, { Component } from "react";
import {
  Box,
  Typography,
  styled,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { IFaqQuestionAnswer } from "../LandingPageController";
import MainTitle from "./MainTitle.web";

import { plusImage, minusImage } from "../assets";

interface Props {
  faqQuestionAnswer: IFaqQuestionAnswer[];
}

interface State {}

export default class FaqSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { props } = this;
    return (
      <FAQContainer component="section" id="faqs">
        <MainTitle  title="You may still be wondering..."/>
        <Typography className="faq-description">
          Planning an Indian wedding involves many details and decisions. We
          know you might have lots of questions, and we’re here to help! Below
          are some of the most frequently asked questions we receive from
          couples and their families. If you don’t find the answer you’re
          looking for, please feel free to contact us directly. We’re always
          here to assist you in making your special day as perfect as possible.
        </Typography>
        <Box className="faq-color-container">
          {props.faqQuestionAnswer.map((data) => (
            <Accordion className="faq-accordion" key={data.id}>
              <AccordionSummary
                expandIcon={
                  <>
                    <img src={plusImage} className="plus-image" alt="plus" />
                    <img src={minusImage} className="cross-image" alt="cross" />
                  </>
                }
              >
                <Typography variant="h4" className="accordion-heading">
                  {data.attributes.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accordion-details">
                  {data.attributes.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </FAQContainer>
    );
  }
}


const FAQContainer = styled(Box)({
  padding: "20px 0 0px",
  "& .faq-description": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#57534E",
    margin: "18px 0 32px",
  },
  "& .faq-color-container": {
    backgroundColor: "#FBE8FC",
    padding: "59px 36px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "22px",
  },
  "& .faq-accordion": {
    border: "1px solid #E2E8F0",
    boxShadow: "none",
    borderRadius: "8px !important",
    "&::before": {
      display: "none",
    },
    "&.Mui-expanded": {
      margin: "0 !important",
      padding: "0 12px",
    },
    "& .MuiAccordionSummary-root": {
      padding:"0 12px",
      "& .MuiAccordionSummary-content": {
        margin: "23px 0",
      },
      "& .accordion-heading": {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        textAlign: "left",
        color: "#000000",
      },
      "& .plus-image": {
        height: "24px",
        width: "24px",
        display: "block",
      },
      "& .cross-image": {
        height: "24px",
        width: "24px",
        display: "none",
      },
    },

    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "unset",
      padding:"0",
      "& .accordion-heading": {
        fontWeight: "600",
        color: "#7F1187",
      },
      "& .MuiAccordionSummary-content": {
        margin: "10px 0",
      },
      "& .plus-image": {
        height: "24px",
        width: "24px",
        display: "none",
      },
      "& .cross-image": {
        height: "24px",
        width: "24px",
        display: "block",
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: "8px 0px 16px",
    },
    "& .accordion-details": {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      textAlign: "left",
      color: "#57534E",
    },
  },
});
// Customizable Area End
