import React from "react";

// Customizable Area Start
import {
  Box,
  Divider,styled,
  TextField,InputAdornment, 
  Typography
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import CollectionsIcon from '@mui/icons-material/Collections';
const images = require('./assets')
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderChatBox = () => (
      <Box className="container">
        <Box className="chatHead">
          <Box>
            <img src={images.logoImage} alt="logo"/>
              <Typography className="headerText">{configJSON.chatFirstText}<br/>{configJSON.chatSecondText}<br/>{configJSON.chatThirdText}<span className="partyText">{configJSON.privacyText}</span></Typography>
          </Box>
        <ClearIcon onClick={this.props.handleChatClose}/>
        </Box>
        <Divider/>
        <Box className ="chatSection">
          <Box>
          <img src={images.avatar}/>
          </Box>
          <Box  className = "showChatSection">
        <Typography className="showName">Natasha...</Typography>
        <Typography className="showChat">Hi ! Simran</Typography>
        <Typography className="showChat">How may I help you ?</Typography>
        </Box>
        </Box>
      <TextField placeholder="Write here..." className="chatField" data-test-id="handleChatBox" onChange={this.handleChatBox}
      value={this.state.chatValue}
      InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end" className="inputIcons">
                        <CollectionsIcon/><ClearIcon data-test-id="handleChatClear" onClick={this.handleChatClear}/>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                                  <img src={images.avatar1}/>
                      </InputAdornment>
                    )
                  }}/>
                  <Box className="text">
                  <Typography className="thirdPartyText">by <span className="partyText">Third party</span></Typography>
                  </Box>
                  </Box>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <CustomBox >
            {this.renderChatBox()}
      </CustomBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomBox = styled(Box)({
    width:"481px",
    height:"685px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    borderRadius:"18px",
    boxShadow: "0px 4px 4px 0px #00000040",
    zIndex:4,
    "@media (max-width:500px)":{
      width:"350px",
      marginLeft:"20%"
    },
  "& .container":{
    width:"90%",
    height:"95%",
    display:'flex',
    flexDirection:"column",
    gap:'30px',
    position:"relative",
  },
  "& .chatField":{
    border:"1px solid #E7E5E4",
     width:"100%",
     position:"absolute",
     bottom:"45",
     borderRadius:"8px",
     outline:"none !important",
     "& .MuiOutlinedInput-input":{
      color:"#A8A29E !important",
      fontFamily: "Poppins",
       fontSize: "14px",
       fontWeight: 400,
       lineHeight: "24px",
       "&:focus-visible":{
        outline:"none !important",
       }
     },
  },
  "& .chatHead":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  },
  "& .partyText":{
    textDecoration:"underline",
    cursor:"pointer"
  },
  "& .thirdPartyText":{
     color:"#A8A29E",
     fontFamily: "Poppins",
      fontSize: "10px",
      fontWeight: 400,
      lineHeight: "24px",
  },
  "& .text":{
    display:'flex',
    justifyContent:"center",
    width:'100%',
    position:"absolute",
     bottom:"0",
  },
  '& .headerText':{
    color:"#57534E",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    paddingLeft:"7%",
    width:"100%"

  },
  "& .chatSection":{
    display:"flex",
    gap:"8px",
    paddingLeft:"3%",
  },
  "& .showName":{
    color:"#000000",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18px",
    paddingBottom:"12px"
  },
  "& .showChat":{
    color:"#000000",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    paddingBottom:"12px"
  },
  "& .inputIcons":{
    display:'flex',
    gap:"10px",
    cursor: 'pointer'
  },
  "& .showChatSection":{
     paddingTop:'5px'
  }
})
// Customizable Area End
