import React from "react";
import { Field } from "formik";
import {
  OutlinedInput,
  Typography,
  Box,
  styled,
  Theme,
  SxProps,
} from "@mui/material";
import { FormHelperText } from "@mui/material";

interface TextAreaFiledProps {
  name: string;
  label?: string;
  placeholder?: string;
  value: string;
  error?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent) => void;
  type: string;
  helperText: string | boolean | undefined;
  sx?: SxProps<Theme>;
  isRequired?: boolean;
}

const StyledTextAreaField = styled(Box)({
  marginBottom: "16px",
  "& .common-form-label": {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    display: "block",
    color: "#334155",
    marginBottom: "4px",
    "& span": {
      color: "#DC2626",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
    },
  },
  "& .MuiInputBase-input": {
    fontFamily: "Poppins",
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "0",
      background: "#E2E8F0",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      background: "#801187",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    opacity: 1,
    color: "#A8A29E",
  },
  "& .MuiInputBase-root.Mui-error": {
    borderRadius: "8px",
  },
  "& .MuiInputBase-root": {
    padding: "9px 8px",
    borderRadius: "0px",
    backgroundColor: "#FFFFFF",
  },
  "& .MuiFormHelperText-root": {
    lineHeight: "18px",
    color: "#DC2626",
    margin: "8px 0 0",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "400",
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171 !important",
    borderStyle: "solid",
    borderWidth: "1px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderLeft: "0px",
    borderBottom: "1px",
    borderRight: "0px",
    borderTop: "0px",
    borderColor: "#E2E8F0",
    borderStyle: "solid",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderRadius: "8px",
    },
});

const TextAreaFiled: React.FC<TextAreaFiledProps> = ({
  placeholder = "",
  value,
  name,
  label,
  error,
  handleChange,
  handleBlur,
  sx,
  helperText,
  type,
  isRequired,
}) => {
  return (
    <StyledTextAreaField sx={sx}>
      {label && (
        <Typography component="label" className="common-form-label">
          {label}
          {isRequired && <Typography component="span">*</Typography>}
        </Typography>
      )}
      <Field
        as={OutlinedInput}
        name={name}
        multiline={true}
        type={type}
        placeholder={placeholder}
        fullWidth
        minRows={3}
        maxRows={3}
        value={value}
        sx={{
          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
            {
              "-webkit-appearance": "none",
              margin: 0,
            },
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
        }}
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </StyledTextAreaField>
  );
};

export default TextAreaFiled;
