import React from "react";
import { Modal, Fade, CircularProgress, styled } from "@mui/material";
interface myProps {
  isLoading: boolean;
}

export default function Loader(props: myProps) {
  return (
    <LoaderStyle
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.isLoading}
      closeAfterTransition
      disableAutoFocus={true}
    >
      <Fade in={props.isLoading} style={{ border: "none" }}>
        <div
          style={{
            backgroundColor: "transparent",
            outline: "none",
            borderRadius: 29,
            width: "auto",
            height: "auto",
          }}
        >
          <CircularProgress value={10} size={60} />
        </div>
      </Fade>
    </LoaderStyle>
  );
}

const LoaderStyle = styled(Modal)({
  "& .MuiBackdrop-root": {
    backgroundColor: "white",
    opacity: "0.6 !important",
  },
});
