Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.SortAPiEndPoint = "sorting/sorting";
exports.SortAscAPiEndPoint = "sorting/sorting?sort[order_by]=price&sort[direction]=asc";
exports.SortDescAPiEndPoint = "sorting/sorting?sort[order_by]=price&sort[direction]=desc";
exports.SortNewestAPiEndPoint = "sorting/sorting?sort[order_by]=created_at&sort[direction]=desc";
exports.SortPopularityAPiEndPoint = "sorting/sorting?sort[order_by]=average_rating&sort[direction]=desc";
exports.labelTitleText = "sorting";
exports.labelBodyText = "sorting Body";
exports.btnExampleTitle = "CLICK ME";
exports.sortBtnLabel = "Sort";
exports.sortByBtnLabel = "Sort by";
exports.priceLowToHightBtnLabel = "Price Low to High";
exports.priceHightToLowBtnLabel = "Price High to Low";
exports.newestProductsBtnLabel = "Newest Products";
exports.popularityBtnLabel = "Popularity";
exports.shopByCategoryEndpoint = "bx_block_categories/categories/shop_by_category";
exports.productsListingEndpoint = "bx_block_categories/categories/fetch_products_by_function_or_category";
exports.priceFilterDataEndpoint = "bx_block_categories/categories/fetch_price_ranges";
exports.serviceLocationDataEndpoint = "bx_block_multipageforms2/service_locations";
exports.addToCartEndpoint = "bx_block_shopping_cart/orders";
exports.addToWishlistEndpoint = "bx_block_wishlist/wishlists";
// Customizable Area End
