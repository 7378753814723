import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Container, Grid } from "@mui/material";
import Loader from "../../../components/src/AppLoader.web";
import ShoppingCard from "../../../components/src/ShoppingCard.web";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";

// Customizable Area End

import InspirationsController, { Props } from "./InspirationsController";

export default class Inspirations extends InspirationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { state, props } = this;
    // Customizable Area End
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={props.navigation}>
        <Loader isLoading={state.isLoading} />
        <CustomContainer>
          <InspirationItemStyle component="section" id="inspiration">
            {state.inspirationData.map((data) => (
              <React.Fragment key={data.id}>
                <Typography className="header-inspiration" component="h3">
                  {data.attributes.title}
                </Typography>
                <Typography className="description-inspiration">
                  {data.attributes.description}
                </Typography>
                <Box className="inspiration-main-image-container">
                  <img
                    src={data.attributes.image.url}
                    className="inspiration-main-image"
                    alt="image"
                  />
                </Box>
              </React.Fragment>
            ))}

            {state.browseByCategoryData.map((data) => (
              <React.Fragment key={data.id}>
                <Typography className="header-inspiration" component="h3">
                  {data.attributes.title}
                </Typography>
                <Typography className="description-inspiration">
                  {data.attributes.description}
                </Typography>
              </React.Fragment>
            ))}
            {state.inspirationDetailData.length > 0 && (
              <Grid
                spacing={3}
                container
                className="inspiration-item-container"
              >
                {state.inspirationDetailData.map((data) => (
                  <Grid item sm={6} md={4} lg={3} xs={12} key={data.id}>
                    <ShoppingCard
                      image_url={data.attributes.image.url}
                      id={data.attributes.id}
                      description={data.attributes.description}
                      data-test-id="shopping-card"
                      onCardClick={this.redirectToInspirationItemsPage}
                      title={data.attributes.heading}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {state.inspirationDetailData.length === 0 && !state.isLoading && (
              <Box className="no-detail-found-container">
                <Typography className="no-detail-found">
                  No inspiration data found
                </Typography>
              </Box>
            )}
          </InspirationItemStyle>
        </CustomContainer>
      </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});

const InspirationItemStyle = styled(Box)({
  padding: "56px 0 0",
  "& .header-inspiration": {
    fontSize: "24px",
    fontWeight: "600",
    fontFamily: "Poppins",
    lineHeight: "24px",
    textAlign: "center",
    color: "#44403C",
  },
  "& .description-inspiration": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#57534E",
    margin: "16px 0",
  },
  "& .inspiration-main-image-container": {
    width: "100%",
    height: "571px",
    marginBottom: "62px",
    "& .inspiration-main-image": {
      height: "100%",
      objectFit: "cover",
      width: "100%",
    },
  },
  "& .inspiration-item-container": {
    marginTop: "-12px",
    "> .MuiGrid-item": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      paddingTop: "32px",
    },
  },
  "& .no-detail-found": {
    fontFamily: "Poppins",
    fontSize: "30px",
    fontWeight: "400",
    lineHeight: "36px",
    textAlign: "center",
    color: "rgb(68, 64, 60)",
    margin: "16px 0px",
  },
  "& .no-detail-found-container": {
    height: "200px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
// Customizable Area End
