import React from "react";
// Customizable Area Start
import { Box, Typography, Button, styled, CardActionArea, CardContent, CardActions, Card, Rating, Skeleton } from "@mui/material";
import { shopbg, mehndiImg, haldiImg, weddingImg, jewllery, footwear, bangles, accessories, garland, arrivalbg, shoppingImg1, shoppingImg2, shoppingImg3, shoppingImg4 } from "./assets";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import HeaderFooterLayout from "../../../../packages/components/src/HeaderFooterLayout";
// Customizable Area End

import ShopController, {
  Props,
} from "./ShopController.web";
import Carousel from "react-multi-carousel";
import { productDefaultImage } from "../../../../packages/blocks/sorting/src/assets";

export default class Shop extends ShopController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  products = [
    {
      image: shoppingImg2,
      title: 'Lehenga',
      price: '₹ 46,000',
      location: 'Mumbai',
      rating: 1,
      ratingText: '7.5 (678 Ratings)',
    },
    {
      image: shoppingImg4,
      rating: 1,
      title: 'Lehenga',
      price: '₹ 46,000',
      location: 'Mumbai',
      ratingText: '7.5 (678 Ratings)',
    },
    {
      location: 'Mumbai',
      image: shoppingImg1,
      price: '₹ 46,000',
      title: 'Lehenga',
      rating: 1,
      ratingText: '7.5 (678 Ratings)',
    },
    {
      image: shoppingImg3,
      price: '₹ 46,000',
      title: 'Lehenga',
      location: 'Mumbai',
      ratingText: '7.5 (678 Ratings)',
      rating: 1,

    },
  ];

  functionsResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3, // Show 3 cards on large screens
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3, // Show 2 cards on medium screens
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2, // Show 1 card on smaller screens
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // Show 1 card on mobile
    },
  };

  CategoriesResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 5, // Show 3 cards on large screens
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 4, // Show 2 cards on medium screens
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2, // Show 1 card on smaller screens
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // Show 1 card on mobile
    },
  };
  // Customizable Area End

  render() {
    const { pageHeader, isLoading, functionsData, categories, discountBanner } = this.state;
    return (
      // Customizable Area Start
      <>
        <HeaderFooterLayout navigation={this.props.navigation}>
          <CustomBox>
            <Box className="mainBox">
              {!isLoading && pageHeader.title ?
                <>
                  <Box className='shopImgBox'>
                    <img className="shopbgImg" style={{ objectFit: 'cover', objectPosition: 'top' }} src={pageHeader.image} alt="Shop background" />
                    <Box className="imageOverlay"></Box>
                    <Box className='imgtextBox'>
                      <Typography className="imgMainText">
                        {pageHeader.title}
                      </Typography>
                      <Typography className="imgDescText">
                        {pageHeader.subHeading}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "3rem 0rem" }}>
                    <Typography className="responsiveText">
                      {pageHeader.description}
                    </Typography>
                  </Box>
                </>
                :
                <Box className='shopImgBox' width={'95%'} height={'100vh'} style={{ backgroundColor: '#D3D3D3' }}>
                  <Skeleton animation="wave" variant="rounded" width={'95%'} height={'100vh'} />
                  <Box className='imgtextBox' gap={'20px'}>
                    <Skeleton variant="rounded" width={'500px'} height={'100px'} />
                    <Skeleton variant="rounded" width={'650px'} height={'20px'} />
                    <Skeleton variant="rounded" width={'650px'} height={'20px'} />
                  </Box>
                </Box>
              }

              <Typography style={{ fontSize: "24px", fontFamily: "poppins", fontWeight: 600, textAlign: "center", marginBottom: "2rem" }}>Browse by function</Typography>
              <CarouselStyle sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "30px" }}>
                <Carousel
                  responsive={this.functionsResponsive}
                  swipeable
                  draggable
                  showDots={false}
                  infinite={true}
                  keyBoardControl={true}
                  customTransition="all 0.5s"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  itemClass="carousel-item-padding-40-px"
                  removeArrowOnDeviceType={[]}
                >
                  {functionsData.map((item, index) => (
                    <Box key={item.id} margin={"0px 20px"} position={'relative'} style={{ cursor: 'pointer' }} data-test-id={`fucntionCard${index}`} onClick={() => this.showFunctionDress(item.name)}>
                      <Box className="functionImageOverlay"></Box>
                      <Typography className="functionName" component={'h2'}>{item.name}</Typography>
                      <img style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '12px' }} src={item.image || productDefaultImage} alt={item.name} />
                    </Box>
                  ))}
                </Carousel>
              </CarouselStyle>


              <Typography style={{ fontSize: "24px", fontFamily: "poppins", fontWeight: 600, textAlign: "center", paddingTop: "2rem", marginBottom: "2rem" }}>Shop All</Typography>
              <CarouselStyle sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "50px" }}>
                <Carousel
                  responsive={this.CategoriesResponsive}
                  swipeable
                  draggable
                  showDots={false}
                  infinite={true}
                  keyBoardControl={true}
                  customTransition="all 0.5s"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  itemClass="carousel-item-padding-40-px"
                  removeArrowOnDeviceType={[]}
                >
                  {categories.map((item, index) => (
                    <Box onClick={() => this.openCategoryProducts(item.name)} key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0 1rem", cursor: "pointer" }}>
                      <img style={{
                        width: "185px",
                        height: "185px",
                        borderRadius: '50%', objectFit: 'cover'
                      }} src={item.image || productDefaultImage} alt={item.name} />
                      <Typography className='shopsImgText'>
                        {item.name}
                      </Typography>
                    </Box>
                  ))}
                </Carousel>
              </CarouselStyle>

              <Box borderRadius={"12px"} marginLeft={"50px"} marginRight={"50px"} overflow={"hidden"}>
                <Box className="newArrivalsBox">
                  <img style={{ width: '60%', height: '100%', objectFit: 'cover', objectPosition: 'top' }} src={discountBanner} alt="arrivalbg" />
                  <Box className="discountBannerImageOverlay"></Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: "15%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <Typography style={{ fontSize: "32px", fontWeight: 400, fontFamily: "timesNewRoman", color: "white" }}>
                      NEW ARRIVALS
                    </Typography>
                    <Typography style={{ fontSize: "128px", fontWeight: 700, fontFamily: "timesNewRoman", color: "white" }}>50%<span style={{ fontSize: "76px", fontWeight: 700, fontFamily: "timesNewRoman", color: "white" }}>OFF</span></Typography>
                    <Button className="shopNowButton">SHOP NOW</Button>
                    <Typography className="termsConditions">Terms & Conditions apply</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="trendingNowBox">
                <Typography className="trendingNowText">Trending now</Typography>
                <Button className="viewAllButton">View All</Button>
              </Box>
              <Box
                className="productListBox"
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 2,
                  justifyContent: 'center',
                }}
              >
                {this.products.map((product, index) => (
                  <Card key={index} className="productCard">
                    <Box className="likeIconBox">
                      <FavoriteBorderIcon sx={{ color: '#FF6F61' }} />
                    </Box>
                    <CardActionArea>
                      <img className='cardsImg' src={product.image} alt={product.title} />
                      <CardContent>
                        <Box className="productTitleRatingBox">
                          <Typography className="productTitle">{product.title}</Typography>
                          <Box className="ratingBox">
                            <Rating value={product.rating} max={1} precision={1} readOnly />
                            <Typography className="rating">{product.ratingText}</Typography>
                          </Box>
                        </Box>
                        <Typography className="productLocationText">{product.location}</Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Typography className="productPriceText">{product.price}</Typography>
                    </CardActions>
                  </Card>
                ))}
              </Box>
            </Box>
          </CustomBox>
        </HeaderFooterLayout>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CarouselStyle = styled(Box)(({ theme }) => ({
  height: "max-content",
  width: "100%",
  position: "relative",
  "& .carousel-container": {
    width: "90%",
    height: "100%",
    "& .carousel-button-group": {
      position: "absolute",
      inset: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      "& .carousel-button-group-container": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      },
      "& .previous-button,& .next-button": {
        height: "44px",
        width: "44px",
        minWidth: "unset",
        padding: "0",
        borderRadius: "50%",
        background: "#fff",
        color: "#000",
        zIndex: 1,
      },
    },
  },
  "& .react-multiple-carousel__arrow--left": {
    left: "0px",
    background: "#FFF",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  "& .react-multiple-carousel__arrow--left::before": {
    color: "#000",
  },
  "& .react-multiple-carousel__arrow--right": {
    right: "0px",
    background: "#FFF",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  "& .react-multiple-carousel__arrow--right::before": {
    color: "#000",
  },
}));

const CustomBox = styled(Box)({
  '& .mainBox': {
    width: "100vw",
    height: "auto",
  },
  '& .shopImgBox': {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    overflow: "hidden",
  },
  '& .shopbgImg': {
    width: "95%",
    height: "auto",
    maxHeight: "100vh",
  },
  '& .imgtextBox': {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  '& .imgMainText': {
    fontSize: "40px",
    fontWeight: 600,
    fontFamily: "Poppins",
    color: "#ffffff",
    '@media (max-width: 1200px)': { fontSize: "32px" },
    '@media (max-width: 768px)': { fontSize: "24px" },
    '@media (max-width: 480px)': { fontSize: "18px" },
  },
  '& .imageOverlay': {
    width: "95%",
    height: "100vh",
    background: '#0000004D',
    position: 'absolute',
    overflow: 'hidden',
  },
  '& .productListBox': {
    padding: "0rem 2rem",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    '@media (max-width: 768px)': { padding: "0rem 1rem" },
  },
  '& .produMuiOutlinedInput-notchedOutlinectCard': {
    width: "16vw",
    marginBottom: "1rem",
    position: "relative",
    '@media (max-width: 768px)': { width: "30vw" },
    '@media (max-width: 480px)': { width: "40vw" },
  },
  '& .likeIconBox': {
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem",
    zIndex: 1,
  },
  '& .productTitleRatingBox': {
    display: "flex",
    justifyContent: "space-between",
  },
  '& .productTitle': {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: 'Poppins',
    '@media (max-width: 768px)': { fontSize: '1rem' },
    '@media (max-width: 480px)': { fontSize: '0.9rem' },
  },
  '& .carouselContainer': {
    width: "100vw",
    display: "flex",
    justifyContent: "space-evenly",
    paddingTop: "2rem",
    alignItems: "center",
    gap: "2rem",
    '@media (max-width: 768px)': {
      gap: "1rem",
      flexDirection: "column"
    }
  },
  '& .arrowIcon': {
    fontSize: "2rem",
    '@media (max-width: 1200px)': {
      fontSize: "1.8rem",
    },
    '@media (max-width: 768px)': {
      fontSize: "1.5rem",
    },
    '@media (max-width: 480px)': {
      fontSize: "1.2rem",
    },
  },
  '& .carouselImage': {
    '@media (max-width: 768px)': {
      width: "40vw"
    },
    '@media (max-width: 1200px)': {
      width: "20vw"
    },
    '@media (max-width: 480px)': {
      width: "60vw"
    },
  },
  '& .ratingBox': {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
  },
  '& .rating': {
    fontSize: "12px",
    fontFamily: 'Poppins',
    color: "#78716C",
    '@media (max-width: 480px)': { fontSize: '0.6rem' },
    '@media (max-width: 768px)': { fontSize: '0.7rem' },
  },
  '& .productLocationText': {
    paddingTop: "1rem",
    fontFamily: 'Poppins',
    '@media (max-width: 768px)': { paddingTop: "0.5rem" },
    '@media (max-width: 480px)': { fontSize: '0.8rem' },
  },
  '& .productPriceText': {
    fontWeight: 600,
    fontSize: "16px",
    color: "#801188",
    fontFamily: 'Poppins',
    '@media (max-width: 768px)': { fontSize: "14px" },
    '@media (max-width: 480px)': { fontSize: '0.9rem' },
  },
  '& .shopAllText': {
    fontSize: "24px",
    fontFamily: 'Poppins',
    fontWeight: 600,
    textAlign: "center",
    paddingTop: "2rem",
    '@media (max-width: 768px)': { fontSize: "20px", paddingTop: "1rem" },
  },
  '& .shopItemsBox': {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "3rem 0",
    '@media (max-width: 768px)': { flexDirection: "column", padding: "2rem 0" },
  },
  '& .shopItem': {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 1rem",
    '@media (max-width: 768px)': { margin: "1rem 0" },
  },
  '& .cardsImg': {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  '& .shopItemLabel': {
    fontSize: "18px",
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: "#801187",
    '@media (max-width: 768px)': { fontSize: "16px" },
  },
  '& .newArrivalsBox': {
    width: "100%",
    height: "600px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    overflow: 'hidden',
    position: "relative",
    background: "#000",
  },
  '& .newArrivalsTitle': {
    fontSize: "32px",
    fontWeight: 400,
    fontFamily: 'Times New Roman',
    color: "white",
    '@media (max-width: 768px)': { fontSize: "24px" },
  },
  '& .functionImg': {
    '@media (max-width: 1350px)': {
      width: "25%"
    }
  },
  '& .shopsImg': {
    '@media (max-width: 1140px)': {
      width: "85%"
    }
  },
  '& .shopsImgText': {
    fontSize: "18px", fontWeight: 600, fontFamily: "Poppins", color: "#801187",
    '@media (max-width: 1350px)': {
      width: "25%"
    }
  },
  '& .newArrivalsDiscount': {
    fontSize: "128px",
    fontWeight: 700,
    fontFamily: 'Times New Roman',
    color: "white",
    '@media (max-width: 768px)': { fontSize: "96px" },
  },
  '& .discountText': {
    fontSize: "76px",
    '@media (max-width: 768px)': { fontSize: "56px" },
  },
  '& .trendingNowBox': {
    padding: "2rem 5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '@media (max-width: 768px)': { flexDirection: "column", padding: "2rem 1rem" },
  },
  '& .trendingNowText': {
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: 'Poppins',
    '@media (max-width: 768px)': { fontSize: "20px" },
  },
  '& .viewAllButton': {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: "#44403C",
    textTransform: "none",
    '@media (max-width: 768px)': { fontSize: "14px" },
  },
  '& .shopNowButton': {
    width: "9.6vw",
    height: "3vw",
    background: "#FFFFFF",
    color: "black",
    fontSize: "19px",
    fontWeight: 700,
    fontFamily: 'Times New Roman',
    borderRadius: "8px",
    marginTop: "16px",
    '@media (max-width: 768px)': { width: "50vw", height: "auto", fontSize: "16px" },
    '&:hover': {
      background: "#FFF",
    }
  },
  '& .termsConditions': {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: "white",
    paddingTop: "3rem",
    '@media (max-width: 768px)': { fontSize: "12px", paddingTop: "1rem" },
  },
  '& .imgDescText': {
    width: "60%",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Poppins",
    color: "#ffffff",
    lineHeight: "1.5",
    marginTop: "10px",
    wordSpacing: "0.3rem",
    '@media (max-width: 1200px)': { fontSize: "16px" },
    '@media (max-width: 768px)': { fontSize: "14px" },
    '@media (max-width: 480px)': { fontSize: "12px", lineHeight: "1.4", textAlign: "center", padding: "0 10px" },
  },
  '& .responsiveText': {
    width: "70%",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    textAlign: "center",
    lineHeight: "1.6",
    padding: "0 2rem",
    '@media (max-width: 1200px)': { fontSize: "14px", padding: "0 1.5rem" },
    '@media (max-width: 768px)': { fontSize: "12px", padding: "0 1rem" },
    '@media (max-width: 480px)': { fontSize: "11px", padding: "0 0.5rem" },
  },
  '& .functionImageBox': {
    width: "100vw",
    display: "flex",
    justifyContent: "space-evenly",
    paddingTop: "2rem",
    alignItems: "center",
    '@media (max-width: 768px)': { flexDirection: "column", paddingTop: "1rem" },
  },
  '& .functionCarouselContainer': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  '& .functionCarousel': {
    display: "flex",
    justifyContent: "center",
    overflowX: "hidden",
    scrollBehavior: "smooth" as "smooth",
    width: "100%",
    margin: "0 10px",
  },
  '& .arrowButtonLeft': {
    marginRight: "50px",
    color: "#000",
  },
  '& .arrowButtonRight': {
    marginLeft: "50px",
    color: "#000",
  },
  '& .categoryArrowButtonLeft': {
    marginRight: "20px",
    color: "#000",
  },
  '& .categoryArrowButtonRight': {
    marginLeft: "20px",
    color: "#000",
  },
  '& .functionName': {
    fontSize: '32px',
    fontWeight: 600,
    fontFamily: "Poppins",
    color: '#FFF',
    position: 'absolute',
    bottom: '20px',
    left: '25px',
    textTransform: 'capitalize',
  },
  '& .functionImageOverlay': {
    width: '100%',
    height: "100%",
    background: '#0000001A',
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: '12px',
  },
  '& .categoriesCarousel': {
    display: "flex",
    overflowX: "hidden",
    scrollBehavior: "smooth" as "smooth",
    width: "80%",
    margin: "0 10px",
    columnGap: "100px",
  },
  '& .discountBannerImageOverlay': {
    width: '100%',
    height: "600px",
    background: 'linear-gradient(to right, rgba(255,0,0,0), rgba(0,0,0,1) 70%, rgba(0,0,0,1) 100%)',
    position: 'absolute',
    borderRadius: '12px',
  }
});


