// Customizable Area Start
import React, { Component } from "react";
import { Box, Typography, styled } from "@mui/material";

import VendorCard from "../../../../components/src/VendorCard.web";
import {
  vendorImage,
  vendorImage2,
  vendorImage3,
  vendorImage4,
} from "../assets";
import MainTitle from "./MainTitle.web";


interface Props {}

interface State {}

export default class VendorSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <VendorTeamContainer component="section" id="vender-team">
        <MainTitle title="Build a vendor team" />
        <Typography className="vendor-description">
          Planning your dream wedding starts here. We’re excited to help you
          every step of the way! Whether you have questions, need inspiration,
          or are ready to start planning, we’re here for you.
        </Typography>
        <Box className="vendor-items-first-row">
          <VendorCard
            isType1
            title="Wedding venue"
            description="Step into a world of elegance and romance at our breathtaking
                wedding venues."
            buttonText="Explore venues"
            vendorImage={vendorImage}
          />
          <VendorCard
            isType1
            title="Vendors"
            description="Step into a world of elegance and romance at our breathtaking
            wedding venues."
            buttonText="Start your research"
            vendorImage={vendorImage}
          />
        </Box>
        <Box className="vendor-items-second-row">
          <VendorCard
            isType1={false}
            title="Planning tools"
            description="Custom planning tools to manage your checklist , budget and
            guests."
            buttonText="Discover"
            vendorImage={vendorImage2}
          />
          <VendorCard
            isType1={false}
            title="Inspirations"
            description="Custom planning tools to manage your checklist , budget and
            guests."
            buttonText="Discover"
            vendorImage={vendorImage3}
          />
          <VendorCard
            isType1={false}
            title="Products"
            description="Custom planning tools to manage your checklist , budget and
            guests."
            buttonText="Discover"
            vendorImage={vendorImage4}
          />
        </Box>
      </VendorTeamContainer>
    );
  }
}

const VendorTeamContainer = styled(Box)(({ theme }) => ({
  padding: "30px 0",
  "& .vendor-description": {
    margin: "18px 0 60px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#57534E",
  },
  "& .vendor-items-first-row": {
    display: "flex",
    gap: "32px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  "& .vendor-items-second-row": {
    display: "flex",
    gap: "32px",
    padding: "32px 29px 0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px 0",
    },
  },
}));

// Customizable Area End
