import React, {createRef} from "react";

// Customizable Area Start
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import { Box, Typography,styled,  CardActionArea, CardContent, CardActions, Card, Rating,Button} from "@mui/material";
import { weddingImg, bangles,footwear, shoppingImg1,shoppingImg2,shoppingImg4} from "./assets";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


// Customizable Area End
import CategoriessubcategoriesVendorController,{Props} from "./CategoriessubcategoriesVendorController.web";
import VendorCategories from "./VendorCategories.web";

export default class Categoriessubcategories extends CategoriessubcategoriesVendorController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
      }
      // Customizable Area Start
      vendorCategories = [
        {
            image : weddingImg,
            name : "Wedding Photography",
            title : "Wedding Photography"
        },
        {
            image : bangles,
            name : "Event Planner",
            title : "Event Planner"
        },{
            image : footwear,
            name : "Catering Services",
            title : "Catering Services"
        },{
            image : weddingImg,
            name : "Wedding Photography",
            title : "Wedding Photography"
        },
        {
            image : bangles,
            name : "Event Planner",
            title : "Event Planner"
        },{
            image : footwear,
            name : "Catering Services",
            title : "Catering Services"
        },{
            image : weddingImg,
            name : "Wedding Photography",
            title : "Wedding Photography"
        },
        {
            image : bangles,
            name : "Event Planner",
            title : "Event Planner"
        },{
            image : footwear,
            name : "Catering Services",
            title : "Catering Services"
        },
      ];

      weddingPhotographer = [
        {
          image: shoppingImg2,
          title: 'Lehenga',
          location: 'Mumbai',
          price: '₹ 46,000',
          rating: 1,
          ratingText: '7.5 (678 Ratings)',
        },
        {
          image: shoppingImg4,
          title: 'Lehenga',
          location: 'Mumbai',
          price: '₹ 46,000',
          rating: 1,
          ratingText: '7.5 (678 Ratings)',
        },
        {
          image: shoppingImg1,
          title: 'Lehenga',
          location: 'Mumbai',
          price: '₹ 46,000',
          rating: 1,
          ratingText: '7.5 (678 Ratings)',
        },
       
      ]

      services = [
        {
            image : weddingImg,
            title : "Wedding Decoration",
            desc : "wedding theme is simply an idea or concept that flows throughout the events and ceremonies."
        },
        {
            image : weddingImg,
            title : "Wedding Decoration",
            desc : "wedding theme is simply an idea or concept that flows throughout the events and ceremonies."
        },
        {
            image : weddingImg,
            title : "Wedding Decoration",
            desc : "wedding theme is simply an idea or concept that flows throughout the events and ceremonies."
        },
        {
            image : weddingImg,
            title : "Wedding Decoration",
            desc : "wedding theme is simply an idea or concept that flows throughout the events and ceremonies."
        }
      ]

      private categoryCarouselRef = createRef<HTMLDivElement>();
      handleCategoryScroll = (direction: "left" | "right") => {
        const scrollContainer = this.categoryCarouselRef.current;
        if (scrollContainer) {
          const scrollAmount = direction === "right" ? 500 : -500;
          scrollContainer.scrollLeft += scrollAmount;
        }
      };
        //Customizable Area End
        render() {
            const { viewAll, isLoading, services } = this.state;
            return (
              <>
                {!viewAll && !isLoading ? (
                  <HeaderFooterLayout navigation={this.props.navigation}>
                    <CustomBox style={{ margin: "0 20px" }}>
                      <Typography
                        style={{
                          fontSize: "24px",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          textAlign: "center",
                          marginBottom: "2rem",
                          marginTop: "2rem",
                        }}
                        data-test-id="categoryTitle"
                      >
                        Explore vendor by category
                      </Typography>
        
                      <Box className="categoryCarouselContainer">
                        <Button
                          className="arrowButtonLeft"
                          data-test-id="carouselLeftButton"
                          onClick={() => this.handleCategoryScroll("left")}
                        >
                          <ArrowBackIosIcon />
                        </Button>
                        <Box className="categoryCarousel" ref={this.categoryCarouselRef}>
                          {services.length > 0 && services.map((item: any) => (
                            <Box
                              key={item.id}
                              width={"350px"}
                              height={"300px"}
                              position={"relative"}
                              style={{ cursor: "pointer" }}
                            >
                              <Box className="categoryImageOverlay"></Box>
                              <Typography className="categoryName" component={"h2"}>
                                {item.attributes.name}
                              </Typography>
                              <img
                                style={{
                                  width: "350px",
                                  height: "300px",
                                  objectFit: "cover",
                                  borderRadius: "12px",
                                }}
                                src={item.attributes.image.url}
                                alt={item.attributes.name}
                                data-test-id={`categoryImage-${item.id}`}
                              />
                            </Box>
                          ))}
                        </Box>
                        <Button
                          className="arrowButtonRight"
                          data-test-id="carouselRightButton"
                          onClick={() => this.handleCategoryScroll("right")}
                        >
                          <ArrowForwardIosIcon />
                        </Button>
                      </Box>
        
                      <Typography className="description" data-test-id="categoryDescription">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cumque quasi est dignissimos corrupti labore, sit illum ad ipsa tenetur hic unde ipsam voluptate reiciendis quisquam quis commodi ipsum deserunt officia?
                      </Typography>
                      <div className="buttonContainer">
                        <Button
                          className="viewAllButton"
                          data-test-id="viewAllButton"
                          onClick={this.viewAllHandler}
                        >
                          {viewAll ? "View Less" : "View All"}
                        </Button>
                      </div>
        
                      <Typography
                        style={{
                          fontSize: "24px",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                        data-test-id="weddingPhotographersTitle"
                      >
                        Wedding Photographers
                      </Typography>
        
                      <Typography className="description" data-test-id="weddingPhotographersDescription">
                        Discover The Top Photographers Near You Who Will Immortalize Your Wedding's Most Precious Moment
                      </Typography>
        
                      <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
                        <Box className="photographBox">
                          {this.weddingPhotographer.map((photograph, index) => (
                            <Card key={index} className="photographerCard" data-test-id={`photographerCard-${index}`}>
                              <CardActionArea>
                                <img
                                  className="cardsImg"
                                  src={photograph.image}
                                  alt={photograph.title}
                                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                                  data-test-id={"photographerImage"}
                                />
                                <CardContent>
                                  <Box
                                    className="photographerTitileRatingBox"
                                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                  >
                                    <Typography className="photographerTitle" variant="h6" data-test-id={`photographerTitle-${index}`}>
                                      {photograph.title}
                                    </Typography>
                                    <Box className="ratingBox" sx={{ display: "flex", alignItems: "center" }}>
                                      <Rating value={photograph.rating} max={1} precision={1} readOnly size="small" />
                                      <Typography className="ratingText" sx={{ marginLeft: "4px", fontSize: "0.875rem" }} data-test-id={`photographerRating-${index}`}>
                                        {photograph.ratingText}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Typography className="photographerLocation" variant="body2" color="textSecondary" data-test-id={`photographerLocation-${index}`}>
                                    {photograph.location}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                              <CardActions>
                                <Typography className="photographerPackage" variant="h4" data-test-id={`photographerPackage-${index}`}>
                                  <span className="photographerPackageText">Package start @</span>{photograph.price}
                                </Typography>
                                <Button className="getEquiryButton" data-test-id={`getEnquiryButton-${index}`}>Get Enquiry</Button>
                              </CardActions>
                            </Card>
                          ))}
                        </Box>
                      </Box>
        
                      <Typography
                        style={{
                          fontSize: "18px",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          textAlign: "center",
                          marginBottom: "2rem",
                          marginTop: "2rem",
                        }}
                        data-test-id="recommendedServicesTitle"
                      >
                        Recommended Services
                      </Typography>
        
                      <Box className="serviceContainer" data-test-id="serviceContainer">
                        {this.services.map((item, index) => (
                          <Box className="serviceBox" key={index} data-test-id={`serviceBox-${index}`}>
                            <img
                              style={{
                                width: "185px",
                                height: "185px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                              src={item.image}
                              alt={item.title}
                              data-test-id={`serviceImage-${index}`}
                            />
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                fontWeight: "500",
                                textAlign: "center",
                                color: "rgb(128, 17, 135)",
                                margin: "18px 0px 9px",
                              }}
                              data-test-id={`serviceTitle-${index}`}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "22px",
                                textAlign: "center",
                                color: "rgb(68, 64, 60)",
                                maxWidth: "276px",
                                overflowWrap: "break-word",
                              }}
                              data-test-id={`serviceDesc-${index}`}
                            >
                              {item.desc}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
        
                      <div className="buttonContainer">
                        <Button
                          className="viewAllButton"
                          data-test-id="viewAllButton"
                          onClick={this.viewAllHandler}
                        >
                          {viewAll ? "View Less" : "View All"}
                        </Button>
                      </div>
        
                    </CustomBox>
                  </HeaderFooterLayout>
                ) : (
                  <VendorCategories
                    navigation={this.props.navigation}
                    id={""}
                    viewAll={viewAll}
                    services={services}
                    viewAllHandler={this.viewAllHandler}
                    data-test-id="vendorCategoriesPage"
                  />
                )}
              </>
            );
          }
}

// Customizable Area Start
const CustomBox = styled(Box)({
    '& .mainBox': {
    width: "100vw",
    height: "auto",
  },
  '& .description' : {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "34px",
    textAlign: "center",
    color: "#57534E",
    margin: "40px 55px 32px",
  },
  '& .CategoryCarouselContainer': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width : "100%"
  },
  '& .categoryCarousel': {
    display: "flex",
    overflowX: "hidden",
    scrollBehavior: "smooth" as "smooth",
    width: "100%",
    margin: "0 7px",
    columnGap: "20px",
  },
  '& .arrowButtonLeft': {
    // marginRight: "50px",
    color: "#000",
  },
  '& .arrowButtonRight': {
    // marginLeft: "50px",
    color: "#000",
  },
  '& .categoryImageOverlay':{
    width: '350px',
    height: "300px",
    background: '#0000001A',
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: '12px',
  },
  '& .categoryName': {
    fontSize: '30px',
    fontWeight: 600,
    fontFamily: "Poppins",
    color: '#FFF',
    position: 'absolute',
    bottom: '20px',
    left: '25px',
    textTransform: 'capitalize',
  },
  

  // Wedding Photographer
  '& .photographBox': {
        width: "100%",
        display: 'flex',
        flexWrap: 'wrap',
        gap: "3rem",
        justifyContent: 'center',
      },
      '& .photographerCard': {
        position: 'relative',
        transition: 'opacity 0.3s ease',
        cursor: 'pointer',
      },
    '& .cardsImg': {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
      '@media (max-width : 768px)': {padding : "20px"},
      '@media (max-width: 480px)': {padding : "10px"},
    },
    '& .select': {
        marginRight: '16px',
        border: "1px solid white",
        '& .MuiOutlinedInput-notchedOutline': 
        { border: "none" },
      },
    '& .photographerTitileRatingBox': {
      display: "flex",
      justifyContent: "space-between",
    },
    '& .photographerTitle': {
      fontSize: "14px",
      fontWeight: 600,
      fontFamily: 'Poppins',
      '@media (max-width: 768px)': { fontSize: '1rem' },
      '@media (max-width: 480px)': { fontSize: '0.9rem' },
    },
    '& .ratingText': {
      fontSize: "10px",
      fontWeight: 400,
      fontFamily: 'Poppins',
      color: "#78716C",
      '@media (max-width: 768px)': { fontSize: '0.7rem' },
      '@media (max-width: 480px)': { fontSize: '0.6rem' },
    },
    '& .photographerLocation': {
      paddingTop: "1rem",
      fontFamily: 'Poppins',
      color : "#78716C",
      '@media (max-width: 768px)': { paddingTop: "0.5rem" },
      '@media (max-width: 480px)': { fontSize: '0.8rem' },
    },
     '& .photographerPackage': {
      fontSize: "12px",
      fontWeight: 500,
      fontFamily: 'Poppins',
      color: "#ff6d00",
      '@media (max-width: 768px)': { fontSize: "14px" },
      '@media (max-width: 480px)': { fontSize: '0.9rem' },
    },
    '& .photographerPackageText' : {
        fontSize: "12px",
      fontFamily: 'Poppins',
      color: "#78716C",
      '@media (max-width: 768px)': { fontSize: "14px" },
      '@media (max-width: 480px)': { fontSize: '0.9rem' },
    },
    '& .getEquiryButton':{
        fontSize : "12px",
        fontWeight : 600,
        fontFamily: 'Poppins',
        marginLeft : "50px",
        color : '#8a00c4',
        border: "2px solid #8a00c4",
        cursor: "pointer",
        borderRadius: "8px"
    },
    '& .buttonContainer' : {
        textAlign : "center",
        marginBottom : "20px"
    },
    '& .viewAllButton' :{
        fontSize : "12px",
        fontFamily: 'Poppins',
        padding: "5px 25px",
        textAlign: "center",
        color : '#8a00c4',
        border: "1px solid #8a00c4",
        cursor: "pointer",
        borderRadius: "30px"
    },
    '& .serviceContainer' :{  
    display: "flex",
    justifyContent: "center",
    padding: "0 0px 28px",
    flexWrap: "wrap",
    gap: "30px",
    },
    '& .serviceBox' : {
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        width : "fit-content",
        cursor : "pointer"
    }

    
})
// Customizable Area End
