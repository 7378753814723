import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
export const baseURL = require("../../../framework/src/config.js");

interface DataCarousel {
  id: number;
  attributes: {
    id: number;
    img_vid_url: {
      id: 23,
      type: string;
      url:string;     
    }
}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  datacarousel:DataCarousel[]
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PasswordConfirmationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  newPasswordApiCallId: string = "";
  getCarouselsImagesCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isLoading: false,
      datacarousel:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.carouselSliderData(message);
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    this. carouselImagesApi();
   };

  goToLogin = async() => {
    let checkLogin = await getStorageData("vendorLogin")
    if(checkLogin){
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "VendorLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
      await removeStorageData("vendorLogin");
    }
    else{
    this.props.navigation.navigate("LoginEmail");
    }
  };

  carouselSliderData= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.getCarouselsImagesCallId &&
			this.getCarouselsImagesCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson.data){
        this.setState({datacarousel:responseJson.data})
			}
		}
  }

  carouselImagesApi = async () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.carouselGetApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); this.getCarouselsImagesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End
}
