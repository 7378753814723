import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall, clearStorageData, redirectToPage } from "../../../components/src/utils";
import { toast } from "react-toastify";
import { setStorageData } from "../../../framework/src/Utilities";
import { CredentialResponse } from "@react-oauth/google";
import jwtDecode from "jwt-decode";

export const baseURL = require("../../../framework/src/config.js");

interface IReference {
  updated_at: string;
  id: number;created_at: string;
  name: string;
}

type WebResponseJson = { errors: string[] } & {
  data: { id: number; attributes: { email: string } }; meta: { token: string };
 
};

interface CrouselData {
  id: number;
  attributes: {
    id: number;
    img_vid_url: {
      id: 23,
      url:string;   
      type: string;
    }
}
}
interface ITermsCondition {
  id: number;
  account_id: null;
  updated_at: string;
  created_at: string;
  description: string; 
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  formData: {
    reference_id: string;
    email: string;
    password: string;
    fullName: string;
    phone:string;
    showPassword: boolean;
    isTermsChecked: boolean;
    isPrivacyChecked: boolean;
    phoneId:string;
  };
  referenceData: IReference[];
  isLoading: boolean;
  termsConditionData: ITermsCondition[];
  phoneNumberData:Array<object>
  privacyData: ITermsCondition[];
  openTermsPrivacyModal: { open: boolean; modalName: "terms" | "privacy" };
  isInvalidEmail: boolean;
  credentialResponse:string | undefined;
  crouslData:CrouselData[];
  error:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resourceApiCallId: string = "";
  signUpEmailApiCallId: string = "";
  termsConditionApiCallId: string = "";
  privacyApiCallId: string = "";
  apiGetCarouselListCallId:string=""
  apiGoogleCallId:string="";
  getCountriesDataApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      formData: {
        email: "",
        password: "",
        fullName: "",
        phone:"",
        showPassword: false,
        reference_id: "",
        isPrivacyChecked: false,
        isTermsChecked: false,
        phoneId:'',
      },
      referenceData: [],
      isLoading: true,
      termsConditionData: [],
      phoneNumberData:[],
      privacyData: [],
      openTermsPrivacyModal: { open: false, modalName: "terms" },
      isInvalidEmail: false,
      crouslData:[],
      credentialResponse:undefined,
      error:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (webApiRequestCallId) {
        case this.resourceApiCallId:
          this.handleResourcesApiResponse(webResponseJson);
          break;
        case this.signUpEmailApiCallId:
          this.handleSignUpAPiResponse(webResponseJson);
          break;
        case this.privacyApiCallId:
          this.handlePrivacyApiResponse(webResponseJson);
          break;
          case this.termsConditionApiCallId:
            this.handleTermsConditionApiResponse(webResponseJson);
            break;
        case this.apiGoogleCallId:
          this.handleSignUpAPiResponse(webResponseJson);
          break;
        case this.apiGetCarouselListCallId:
          this.setState({
            crouslData: webResponseJson.data
          })
          break;
        case this.getCountriesDataApiId:
          this.handleCountriesDataResponse(webResponseJson);
          break;
        default:
          break;
      }
      this.setState({
        isLoading: false,
      });
    }

    // Customizable Area End
  }


  // Customizable Area Start
  componentDidMount = async () => {
    await clearStorageData();
    this.apiGetCarusel();
    this.getToken();
    this.getResourceList();
    this.getPrivacyDetails();
    this.getTermsConditionDetails();
    this.getCountriesData();
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  handleResourcesApiResponse = (webResponseJson: IReference[]) => {
    if (webResponseJson) {
      this.setState(
        {
        referenceData: webResponseJson,
      }
    );
}};

  handleSignUpAPiResponse = async (webResponseJson: any) => {
    if (Array.isArray(webResponseJson.errors)) {
      toast.error(webResponseJson.errors[0]);
      if (webResponseJson.errors[0] == "Email has already been taken") 
        {
        this.setState(
          {isInvalidEmail: true }
        );
      } return;
    }

    if (webResponseJson && webResponseJson.data && webResponseJson.meta) 
      {
      toast.success("User account created successfully.");
      await setStorageData("authToken", webResponseJson.meta.token); await setStorageData("email", webResponseJson.data.attributes.email);
      this.handleNavigateToShowVerification();
    }
    if (webResponseJson && webResponseJson.account && webResponseJson.meta) 
      {
      toast.success("User account created successfully.");
      await setStorageData("authToken", webResponseJson.meta.token); await setStorageData("email", webResponseJson.account.email);
      redirectToPage(this.props.navigation, "LandingPageWeb");
    }
  };

  handleNavigateToShowVerification = () => {
    this.props.navigation.navigate("ShowVerification");
  };

  goToLogin = () => {
    this.props.navigation.navigate("LoginEmail");
  };

  getResourceList = async () => {
    this.setState({
      isLoading: true,
    });
    this.resourceApiCallId = await apiCall({
      endPoint: "account_block/references",
      method: "GET",
      contentType: "application/json",
    });
  };

  signUpWithEmail = async (values: {
    email: string;
    password: string;
    fullName: string;
    reference_id: string;
    phone:string;
  }) => {
    this.setState({
      isLoading: true,
    });
    const bodyDate = {
      type: "email_account",
      attributes: {
        full_name: values.fullName.trim(),
        email: values.email.trim(),
        password: values.password,
        reference_id: values.reference_id,
        full_phone_number:values.phone
      },
    };
    this.signUpEmailApiCallId = await apiCall({
      endPoint: "account_block/accounts",
      method: "POST",
      contentType: "application/json",
      body: JSON.stringify({ data: bodyDate }),
    });
  };

  getTermsConditionDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.termsConditionApiCallId = await apiCall({
      endPoint: "bx_block_terms_and_conditions/terms_and_conditions",
      method: "GET",
    });
  };

  handleTermsConditionApiResponse = (webResponseJson: ITermsCondition[]) => {
    if (webResponseJson) {
      this.setState({
        termsConditionData: webResponseJson,
      });
    }
  };

  googleSignUpApi = async (jwtDecoded:any, credentialResponse:CredentialResponse) => {
    this.setState({ isLoading: true });
    this.apiGoogleCallId = await apiCall({
      endPoint: "/account_block/accounts",
      method: "POST",
      body:JSON.stringify({
        "data" :
        {
            "type" : "social_account",
            "attributes": {
            "first_name": jwtDecoded.given_name,
            "last_name": jwtDecoded.family_name,
             "email": jwtDecoded.email,
             "unique_auth_id": credentialResponse.clientId
            }
             
        }   
    }),
      contentType: "application/json",
    });
  };


  apiGetCarusel = async () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.carouselGetApiEndpoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiGetCarouselListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getPrivacyDetails = async () => {
    this.setState({
      isLoading: true,
    });
    this.privacyApiCallId = await apiCall({
      endPoint: "bx_block_terms_and_conditions/privacy_policies",
      method: "GET",
    });
  };

  handlePrivacyApiResponse = (webResponseJson: ITermsCondition[]) => {
    if (webResponseJson) {
      this.setState({
        privacyData: webResponseJson,
      });
    }
  };

  openModal = (open: boolean, modalName: "terms" | "privacy") => {
    this.setState({
      openTermsPrivacyModal: {
        open,
        modalName,
      },
    });
  };

  handleChangeEmailValid = () => {
    this.setState({
      isInvalidEmail: false,
    });
  };

 goToHomeSignUp = () => {
    const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPageWeb");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }; 

  redirectFromSignUpToHomePage = () => {
    redirectToPage(this.props.navigation, "LandingPageWeb");
  };

  googleSignupSuccess = (credentialResponse: CredentialResponse) => {
    this.setState({credentialResponse:credentialResponse.credential})
    const jwtDecoded = credentialResponse.credential && jwtDecode(credentialResponse.credential);
    this.googleSignUpApi(jwtDecoded,credentialResponse)
  };

  googleSignupFailed = () => {
    toast.error("Google user signup failed");
  }

  getCountriesData = async() => {
    this.setState({ isLoading: true });
    this.getCountriesDataApiId = await apiCall({
      endPoint: configJSON.fetchCountriesEndpoint,
      method: configJSON.validationApiMethodType,
    });
  }

  handleCountriesDataResponse = async (response: {
    data: {
      country_code: string,
      country_full_name: string,
      numeric_code: string,
    }[]
  }) => {
    if (response?.data) {
      const phoneNumberData = response.data.map((item, index) => {
        return {
          id: index + 1,
          label: `+${item.numeric_code}`,
          value: `+${item.numeric_code}`,
        }
      });
      this.setState({ phoneNumberData });
    } else {
      this.setState({ phoneNumberData: [] });
    }
    this.setState({ isLoading: false });
  }

  // Customizable Area End
}
