import React from "react";
// Customizable Area Start
import { Box, Typography, InputLabel, InputAdornment, Button, styled } from "@mui/material";
import HeaderVendorFooter from "../../../../packages/components/src/HeaderVendorFooter";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Formik } from "formik";
import * as Yup from "yup";
import TextInputFiled from "../../../components/src/TextInputField.web";
// Customizable Area End
import CreateVendorOfferController, { Props,FormdataOptionExport } from "./CreateVendorOfferController.web";

const validatonVendorSchema = Yup.object({
  couponCode: Yup.string()
    .trim()
    .required("Please enter coupon code"),
  expDate : Yup.string()
  .trim()
  .required("Please enter expiry date"),
  orderValue: Yup.string()
    .trim()
    .required("Please enter order value"),
  offer: Yup.string()
    .trim()
    .required("Please enter offer value"),
  termsPolicy: Yup.string()
    .trim()
    .required("Please add terms and policy"),
});

export default class CreateVendorOffer extends CreateVendorOfferController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  isError = (
    touched: boolean | undefined,
    errors: string | undefined,
  ) => {
    return touched && !!errors;
  };
  helperText = (
    touched: boolean | undefined,
    errors: string | undefined,
  ) => {
    return touched && errors;
  }
  // Customizable Area End

  render() {
    const { isEditing } = this.state;
    return (
      // Customizable Area Start
      <HeaderVendorFooter navigation={this.props.navigation}>
        <CustomBox>
          <Formik
          enableReinitialize={true}
          validationSchema={validatonVendorSchema}
            data-test-id="Formik"
            initialValues={
              {
              "couponCode": this.state.isEditing?this.state.formData?.couponCode :"",
              "expDate":  this.state.isEditing?this.state.formData?.expDate: "",
              "orderValue":  this.state.isEditing?this.state.formData?.orderValue : "",
              "maxDiscount":  this.state.isEditing?this.state.formData?.maxDiscount : "",
              "offer":  this.state.isEditing?this.state.formData?.offer: "",
              "termsPolicy":  this.state.isEditing?this.state.formData?.termsPolicy: "",
              }              
            }
            
            onSubmit={(values:FormdataOptionExport) => {
              this.createOfferApi(values)
            }}
          >
            {({
              touched,
              values,
              errors,
              setFieldValue,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => {
              return (
                <form onSubmit={handleSubmit} noValidate className="formStyle">
                  <Typography className="offerHead">{isEditing ? "Edit Offers" : "Create Offers"}</Typography>
                    <Box>
                      <Box className="fieldSection">
                        <Box className="sectionBox">
                          <InputLabel className="labelClass">Coupon code<span className="star">*</span></InputLabel>
                          <TextInputFiled
                            name="couponCode"
                            value={values.couponCode}
                            placeholder="Enter Coupon Code"
                            label=""
                            data-test-id="couponCode-input"
                            handleChange={handleChange}
                            type="couponCode"
                            handleBlur={handleBlur}
                            error={this.isError(touched.couponCode, errors.couponCode)}
                            helperText={this.helperText(
                              touched.couponCode,
                              errors.couponCode
                            )} />
                        </Box>
                        <Box className="sectionBox">
                          <InputLabel className="labelClass">Exp Date</InputLabel>
                          <TextInputFiled
                            value={values.expDate}
                            label=""
                            name="expDate"
                            data-test-id="expiry-data-input"
                            placeholder="Enter Exp Date"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            error={this.isError(touched.expDate, errors.expDate)}
                            type="date"
                            helperText={this.helperText(
                              touched.expDate,
                              errors.expDate
                            )} />
                        </Box>
                      </Box>
                      <Box className="fieldSection">
                        <Box className="sectionBox">
                          <InputLabel className="labelClass">Minimum Order value <span className="star">*</span></InputLabel>
                          <TextInputFiled
                            value={values.orderValue}
                            label=""
                            name="orderValue"
                            data-test-id="orderValue-input"
                            placeholder="Enter Order Value"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            type="text"
                            error={this.isError(touched.orderValue, errors.orderValue)}
                            helperText={this.helperText(
                              touched.orderValue,
                              errors.orderValue
                            )} />
                        </Box>
                        <Box className="sectionBox">
                          <InputLabel className="labelClass">Max Discount</InputLabel>
                          <TextInputFiled
                            value={values.maxDiscount}
                            label=""
                            name="maxDiscount"
                            data-test-id="orderValue-input"
                            placeholder="Enter Maximum Discount"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            error={this.isError(touched.maxDiscount, errors.maxDiscount)}
                            type="text"
                            helperText={this.helperText(
                              touched.maxDiscount,
                              errors.maxDiscount
                            )}
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <InputAdornment position="start" className="inputIcons">
                                  <CurrencyRupeeIcon fontSize="small" />
                                </InputAdornment>
                              ),
                            }}
                             />
                        </Box></Box>
                      <Box>
                        <InputLabel className="labelClass">Offer <span className="star">*</span></InputLabel>
                        <TextInputFiled
                          value={values.offer}
                          label=""
                          name="offer"
                          data-test-id="offer-input"
                          placeholder="Enter Offer Value  %"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          type="text"
                          error={this.isError(touched.offer, errors.offer)}
                          helperText={this.helperText(
                            touched.offer,
                            errors.offer
                          )} />
                      </Box>
                      <Box>
                        <InputLabel className="labelClass">Terms & Policies <span className="star">*</span></InputLabel>
                        <TextInputFiled
                          value={values.termsPolicy}
                          label=""
                          name="termsPolicy"
                          data-test-id="termsPolicy-input"
                          placeholder="Add Terms & Policies"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          type="text"
                          error={this.isError(touched.termsPolicy, errors.termsPolicy)}
                          helperText={this.helperText(
                            touched.termsPolicy,
                            errors.termsPolicy
                          )} />
                      </Box>

                    </Box>
                  

                  <Box className="Btns">
                    <Button className="cancelBtn">Cancel</Button>
                    <Button className="createBtn" type="submit" data-test-id="handleCreateCatalogoueApi"> {isEditing ? "Edit Offer" : "Create Offer"} </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </CustomBox>
      </HeaderVendorFooter>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const CustomBox = styled(Box)({
  width: "80%",
  margin: "auto",
  border: "1px solid #BDBDBD",
  borderRadius: "8px",
  padding: "20px",
  height: "637px",
  "& .star": {
    color: "#DC2626 !important",
  },
  "& .offerHead": {
    color: "#292524",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    paddingBottom: "35px",
    paddingTop: "20px"
  },
  "& .labelClass": {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    textTransform: "capitalize",
  },
  "& .fieldSection": {
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
    justifyContent: "space-between",
    "&.MuiTextField-root": {
      width: "100%",
      margin: "10px 0",
    },
  },
  "& .sectionBox": {
    width: "48%"
  },
  "& .formStyle": {
    display: "flex",
    flexDirection: "column",
    gap: "20px"
  },
  "& .cancelBtn": {
    color: "#94A3B8",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#E2E8F0",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "180px",
    cursor: "pointer",
    "&:hover": {
      background: "#E2E8F0"
    }
  },
  "& .createBtn": {
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textTransform: "capitalize",
    background: "#7F1187",
    padding: "10px 0px",
    borderRadius: "30px",
    width: "180px",
    cursor: "pointer",
    "&:hover": {
      background: "#7F1287"
    }
  },
  "& .Btns": {
    display: "flex",
    width: '100%',
    justifyContent: "flex-end",
    gap: "24px"
  },
})
// Customizable Area End
