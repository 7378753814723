// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { apiCall } from "../../../components/src/utils";
import { toast } from "react-toastify";
import eventEmitter from "../../../components/src/EventEmitter";

export const configJSON = require("./config");

export interface WishlistData {
  data: WishlistItem[];
}

export interface WishlistItem {
  id: string;
  type: string;
  attributes: WishlistAttributes;
}

export interface WishlistAttributes {
  id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
  item: ItemData;
}

export interface ItemData {
  data: ProductData;
}

export interface ProductData {
  id: string;
  type: string;
  attributes: ProductDataAttributes;
}

export interface ProductDataAttributes {
  id: number;
  name: string;
  SKU: string;
  category: Category;
  description: string;
  fabric_details: string;
  price: string;
  available_quantity: number;
  media_files: MediaFile[];
  functions: Function[];
  service_locations: ServiceLocations;
  product_variants: ProductVariant[];
  custom_fields: Record<string, any>;
  created_at: string;
  is_published: boolean;
  product_faqs: ProductFaqs[];
  size_chart: string;
  is_available: boolean;
  is_deleted: boolean;
  coupon?: Coupon;
}

export interface Category {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}

export interface CategoryAttributes {
  id: number;
  name: string;
  description: string | null;
  dark_icon: string | null;
  dark_icon_active: string | null;
  dark_icon_inactive: string | null;
  light_icon: string | null;
  light_icon_active: string | null;
  light_icon_inactive: string | null;
  rank: number | null;
  created_at: string;
  updated_at: string;
  sub_categories: any[];
  selected_sub_categories: any | null;
  image: string;
}

export interface MediaFile {
  url: string;
  filename: string;
  content_type: string;
  byte_size: number;
}

export interface Function {
  id: string;
  type: string;
  attributes: FunctionAttributes;
}

export interface FunctionAttributes {
  id: number;
  name: string;
  function_image: string;
  description: string;
}

export interface ServiceLocations {
  data: ServiceLocation[];
}

export interface ServiceLocation {
  id: string;
  type: string;
  attributes: ServiceLocationAttributes;
}

export interface ServiceLocationAttributes {
  id: number;
  location_name: string;
}

export interface ProductVariant {
  id: string;
  type: string;
  attributes: ProductVariantAttributes;
}

export interface ProductVariantAttributes {
  id: number;
  product_size: ProductSize;
  product_color: ProductColor;
  available_count: number;
}

export interface ProductSize {
  id: string;
  type: string;
  attributes: ProductSizeAttributes;
}

export interface ProductSizeAttributes {
  id: number;
  size_label: string;
  measurement: number;
}

export interface ProductColor {
  id: string;
  type: string;
  attributes: ProductColorAttributes;
}

export interface ProductColorAttributes {
  id: number;
  name: string;
  color_image: string | null;
}

export interface Coupon {
  id: string;
  type: string;
  attributes: CouponAttributes;
}

export interface CouponAttributes {
  id: number;
  cost_type: string;
  coupon_code: string;
  exp_date: string;
  min_order_value: string;
  max_discount: string;
  discount_percentage: number;
  terms_policies: string;
  is_expired: boolean;
  amount: string | null;
}

export interface ProductFaqs {
  id: string;
  type: string;
  attributes: ProductFaqAttributes;
}

export interface ProductFaqAttributes {
  id: number;
  question: string;
  answer: string;
  open?: boolean;
}

const wishlistDummyResponse: WishlistData = {
  "data": [
    {
      "id": "2",
      "type": "wishlist",
      "attributes": {
        "id": 2,
        "account_id": 2,
        "created_at": "2024-12-03T12:39:06.684Z",
        "updated_at": "2024-12-03T12:39:06.684Z",
        "item": {
          "data": {
            "id": "19",
            "type": "multipage_product",
            "attributes": {
              "id": 19,
              "name": "Hello ssssssss",
              "SKU": "2-90",
              "category": {
                "id": "6",
                "type": "category",
                "attributes": {
                  "id": 6,
                  "name": "Lehnga",
                  "description": null,
                  "dark_icon": null,
                  "dark_icon_active": null,
                  "dark_icon_inactive": null,
                  "light_icon": null,
                  "light_icon_active": null,
                  "light_icon_inactive": null,
                  "rank": null,
                  "created_at": "2024-10-08T10:38:20.916Z",
                  "updated_at": "2024-10-20T14:29:50.916Z",
                  "selected_sub_categories": null,
                  "sub_categories": [],
                  "image": "https://weddingmarketplace-587932-ruby.b587932.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBYms9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--eb95fc7a24534c745aafb9f67b79eb029aa78d47/atikh-bana-_KaMTEmJnxY-unsplash.jpg"
                }
              },
              "description": "Lorem Ipsum okok",
              "fabric_details": "Lorem Ipsum silk",
              "price": "455.0",
              "available_quantity": 12,
              "media_files": [
                {
                  "url": "https://weddingmarketplace-587932-ruby.b587932.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcFlCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b8acd8f92b2823e0793d47ed65d7998cd227d131/image_%20(4).png",
                  "filename": "image_ (4).png",
                  "content_type": "image/png",
                  "byte_size": 1011797
                },
                {
                  "url": "https://weddingmarketplace-587932-ruby.b587932.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcGNCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--63104273399f0cf52bc28b68a993d40324dab42b/image_%20(3).png",
                  "filename": "image_ (3).png",
                  "content_type": "image/png",
                  "byte_size": 1392002
                },
                {
                  "url": "https://weddingmarketplace-587932-ruby.b587932.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcGtCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ead1ffde131a37e36ee2004f670b82fbe5e4be66/size_chart.png",
                  "filename": "size_chart.png",
                  "content_type": "image/png",
                  "byte_size": 1011797
                },
                {
                  "url": "https://weddingmarketplace-587932-ruby.b587932.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcG9CIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0f2e8ef7bfb7304dacc9fba94857d63e5af63ad5/size_chart.png",
                  "filename": "size_chart.png",
                  "content_type": "image/png",
                  "byte_size": 1392002
                },
                {
                  "url": "https://weddingmarketplace-587932-ruby.b587932.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcHdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3b7083db5eaace6ff08ae9d4b74d4ef115729ab6/size_chart.png",
                  "filename": "size_chart.png",
                  "content_type": "image/png",
                  "byte_size": 1392002
                },
              ],
              "functions": [
                {
                  "id": "4",
                  "type": "function",
                  "attributes": {
                    "id": 4,
                    "name": "Engagement",
                    "function_image": "https://weddingmarketplace-587932-ruby.b587932.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdWdCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a35476ea187ab4d7c6e16cba29211ea48936e251/360_F_461027810_NNULAnH9eo4PFWizgsgn5aZii08iVZ24.jpg",
                    "description": "The Engagement ceremony is a vibrant and joyful prelude to your wedding, filled with laughter, music, and the intricate art of henna. To complement this cherished tradition, finding the perfect outfit is key. At Happiffie, we’re here to help you shine in style as you celebrate this beautiful occasion."
                  }
                }
              ],
              "service_locations": {
                "data": [
                  {
                    "id": "2",
                    "type": "service_location",
                    "attributes": {
                      "id": 2,
                      "location_name": "chennai"
                    }
                  },
                  {
                    "id": "1",
                    "type": "service_location",
                    "attributes": {
                      "id": 1,
                      "location_name": "hyderabad"
                    }
                  }
                ]
              },
              "product_variants": [
                {
                  "id": "66",
                  "type": "product_variant",
                  "attributes": {
                    "id": 66,
                    "product_size": {
                      "id": "99",
                      "type": "product_size",
                      "attributes": {
                        "id": 99,
                        "size_label": "Mm",
                        "measurement": 45
                      }
                    },
                    "product_color": {
                      "id": "68",
                      "type": "product_color",
                      "attributes": {
                        "id": 68,
                        "name": "red blue",
                        "color_image": "https://weddingmarketplace-587932-ruby.b587932.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcFVCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--7bce8bc2d13d6dd8410c00c525977ef02ef357a0/image_%20(19).png"
                      }
                    },
                    "available_count": 12
                  }
                }
              ],
              "custom_fields": {},
              "created_at": "2024-11-13T11:40:40.772Z",
              "is_published": false,
              "product_faqs": [
                {
                  "id": "1",
                  "type": "product_faq",
                  "attributes": {
                    "id": 1,
                    "question": "hellosss",
                    "answer": "yes ok"
                  }
                },
                {
                  "id": "2",
                  "type": "product_faq",
                  "attributes": {
                    "id": 2,
                    "question": "sample ues",
                    "answer": "answer"
                  }
                }
              ],
              "size_chart": "https://weddingmarketplace-587932-ruby.b587932.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBczhCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0682073fa76b445f39337c92e086fce24ab74d09/size_chart.png",
              "is_available": true,
              "is_deleted": false,
              "coupon": {
                "id": "2",
                "type": "coupon_code",
                "attributes": {
                  "id": 2,
                  "cost_type": "percent",
                  "coupon_code": "HELLOS",
                  "exp_date": "2024-11-10",
                  "min_order_value": "6000.0",
                  "max_discount": "3000.0",
                  "discount_percentage": 60,
                  "terms_policies": "1. Coupon by vendor, 2. Will be added to the products.",
                  "is_expired": true,
                  "amount": null
                }
              }
            }
          }
        }
      }
    }
  ]
}

export interface Props {
  navigation: any;
}

interface S {
  isLoading: boolean;
  wishlistArray: [];
  wishlistAllArray: WishlistItem[];
  token: string;
  clickedProductId: number;
  productCardLoader: boolean;
  openDeleteConfirmationModal: boolean;
  deleteProductId: number;
  openAddToCartSuccessModal: boolean;
  cartProduct: ProductDataAttributes | null;
}

interface SS {

}

export default class WishlistControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  wishlistApiCallId: string = "";
  toggleWishlistApiCallId: string = "";
  wishlistAllApiCallId: string = "";
  addProductToCartId: string = "";
  wishlistDeleteApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      isLoading: false,
      wishlistArray: [],
      wishlistAllArray: [],
      token: '',
      clickedProductId: 0,
      productCardLoader: false,
      openDeleteConfirmationModal: false,
      deleteProductId: 0,
      openAddToCartSuccessModal: false,
      cartProduct: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    this.getAllWishlist();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (webApiRequestCallId) {
        case this.wishlistAllApiCallId:
          this.handleGetAllWishlistResponse(webResponseJson);
          break;

        case this.addProductToCartId:
          this.handelAddToCartResponse(webResponseJson);
          break;

        case this.wishlistDeleteApiId:
          this.handelDeleteWishlistResponse(webResponseJson);
          break;

        default:
          break;
      }
    }
  }

  getAllWishlist = async () => {
    this.setState({ isLoading: true });
    this.wishlistAllApiCallId = await apiCall({
      endPoint: configJSON.getWishlist,
      method: configJSON.validationApiMethodType,
      contentType: 'application/json',
    });
  }

  handleGetAllWishlistResponse = (response: WishlistData) => {
    if (response?.data) {
      let arrayholder = response.data;
      if (arrayholder && arrayholder.length !== 0) {
        this.setState({ wishlistAllArray: arrayholder });
      }
    } else {
      this.setState({ wishlistAllArray: wishlistDummyResponse.data });
    }
    this.setState({ isLoading: false });
  }

  openProductSummary = (productId: number) => {
    this.props.navigation.navigate("ProductSummary", { id: productId });
  }

  handleAddToCart = async (event: any, productId: number) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    const { wishlistAllArray } = this.state;
    const wishlistProducts = wishlistAllArray.map((item) => {
      return item.attributes.item.data.attributes;
    })
    const product = wishlistProducts.find((item) => item.id == productId);
    this.setState({ cartProduct: product || null });
    const variant = product?.product_variants[0];

    if (variant && variant.id) {
      this.addToCartApiCall(variant, productId);
    } else {
      toast.error('No product variant available', { position: 'bottom-right' });
    }
  }

  addToCartApiCall = async (variant: any, productId: number) => {
    const requestBody = {
      cart: {
        product_id: productId,
        product_size_id: parseInt(variant.attributes.product_size.id),
        product_color_id: parseInt(variant.attributes.product_color.id),
        quantity: 1
      }
    }
    this.setState({ clickedProductId: productId, productCardLoader: true });
    this.addProductToCartId = await apiCall({
      endPoint: configJSON.addToCartEndpoint,
      method: configJSON.exampleAPiMethod,
      contentType: 'application/json',
      body: JSON.stringify(requestBody),
    });
  }

  handelAddToCartResponse = (response: { data: any, error: string, errors: any[], message: string }) => {
    if (response?.data) {
      eventEmitter.emit('updateCartCounter', 1, []);
      this.handleOpenAddToCartSuccessModal();
      toast.success('Product added to cart', { position: 'bottom-right' });
    }
    if (response?.message) {
      toast.warning(response.message, { position: 'bottom-right' });
    }
    if (response?.error) {
      toast.error(response.error, { position: 'bottom-right' });
    }
    if (response?.errors) {
      toast.error(response.errors[0]?.token || 'Failed to add product to cart', { position: 'bottom-right' });
    }
    this.setState({ clickedProductId: 0, productCardLoader: false });
  }

  handleDeleteFromWishlist = async () => {
    const { deleteProductId } = this.state;
    this.setState({ openDeleteConfirmationModal: false, isLoading: true })
    this.wishlistDeleteApiId = await apiCall({
      endPoint: configJSON.getWishlist + `/${deleteProductId}`,
      method: configJSON.deleteAPiMethod,
      contentType: 'application/json',
    });
  }

  handelDeleteWishlistResponse = (responseJson: { data: any, error: string, errors: any[], message: string }) => {
    if (responseJson?.data) {
      toast.success('Product deleted from wishlist', { position: 'bottom-right' });
    }
    if (responseJson?.errors) {
      toast.error(responseJson.errors[0]?.token || 'Failed to delete product from wishlist', { position: 'bottom-right' });
    }
    if (responseJson?.error) {
      toast.error(responseJson.error, { position: 'bottom-right' });
    }
    if (responseJson?.message) {
      toast.warning(responseJson.message, { position: 'bottom-right' });
    }
    this.setState({ isLoading: false, clickedProductId: 0, deleteProductId: 0 });
    this.getAllWishlist();
  }

  handleOpenDeleteConfirmationModal = (event: any, productId: number) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    this.setState({ deleteProductId: productId, openDeleteConfirmationModal: true });
  }

  handleCloseDeleteConfirmationModal = () => {
    this.setState({ openDeleteConfirmationModal: false });
  }

  handleOpenAddToCartSuccessModal = () => {
    this.setState({ openAddToCartSuccessModal: true });
  }

  handleCloseAddToCartSuccessModal = () => {
    this.setState({ openAddToCartSuccessModal: false, cartProduct: null });
  }

  handleGotoCart = () => {
    this.handleCloseAddToCartSuccessModal();
    this.props.navigation.navigate("Cart");
  }
}
// Customizable Area End
