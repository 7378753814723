import React from "react"; 
// Customizable Area Start
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";
import { Box, Typography, Button, styled } from "@mui/material";

// Customizable Area End
import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
  navigation: any;
  id: string;
  viewAll: boolean;
  viewAllHandler: any;
  services: any;
}

interface S {
  viewAll: boolean;
  viewAllHandler: Function;
  services: any;
}

interface SS {
  id: any;
}

export default class VendorCategories extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      viewAll: props.viewAll,
      viewAllHandler: props.viewAllHandler,
      services: props.services,
    };
  }

  // Sample vendor categories array for testing
  render() {
    const { viewAll, services } = this.props;

    return (
      <HeaderFooterLayout navigation={this.props.navigation}>
        <CustomBox data-test-id="vendorCategoriesContainer">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "poppins",
              fontWeight: 500,
              textAlign: "center",
              marginBottom: "2rem",
              marginTop: "2rem",
            }}
            data-test-id="vendorCategoriesTitle"
          >
            Explore vendor by category
          </Typography>
          <Box className="categoryBox" sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            <Box className="vendorCategoryContainer" data-test-id="vendorCategoryList">
              {services.map((item: any) => (
                <Box
                  key={item.id}
                  width={"300px"}
                  height={"250px"}
                  position={"relative"}
                  style={{ cursor: "pointer" }}
                  data-test-id={"vendorCategoryItems"}
                >
                  <Box className="ImageOverlay" />
                  <Typography className="vendorTitle" component={"h2"}>
                    {item.attributes.name}
                  </Typography>
                  <img
                    style={{
                      width: "300px",
                      height: "250px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                    src={item.attributes.image.url}
                    alt={item.name}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          <div style={{ margin: "0 3rem" }} data-test-id="vendorCategoryDescription">
            <Typography className="vendorDescription">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis nam culpa eos hic magni
              tenetur aliquam debitis ipsum placeat molestias tempore fuga doloribus aspernatur, odit minus
              cupiditate suscipit error consequatur.
            </Typography>
          </div>
          <div className="buttonContainer" data-test-id="viewAllButtonContainer">
            <Button
              className="viewAllButton"
              onClick={this.props.viewAllHandler}
              data-test-id="viewAllButton"
            >
              {viewAll ? "View Less" : "View All"}
            </Button>
          </div>
        </CustomBox>
      </HeaderFooterLayout>
    );
  }
}


const CustomBox = styled(Box)({
  '& .vendorCategoryContainer':{
      display: 'flex',
        flexWrap: 'wrap',
        gap: "1rem",
        justifyContent: 'center',
        margin : "0 20px"
  },
  '& .ImageOverlay' : {
    width: '300px',
    height: "250px",
    background: '#0000001A',
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: '8px',
    top : 0,
    bottom : 0,
  },
  '& .vendorTitle' : {
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: "Poppins",
    color: '#FFF',
    position: 'absolute',
    bottom: '20px',
    left: '25px',
    textTransform: 'capitalize',
  },
  '& .vendorDescription':{
    fontFamily: "Poppins",
    fontSize: "16px", 
    fontWeight : "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "rgb(87, 83, 78)", 
    margin: "16px 10px"
  },
  '& .buttonContainer' : {
        textAlign : "center",
        marginBottom : "20px"
    },
    '& .viewAllButton' :{
        fontSize : "12px",
        fontFamily: 'Poppins',
        padding: "5px 25px",
        textAlign: "center",
        color : '#8a00c4',
        border: "1px solid #8a00c4",
        cursor: "pointer",
        borderRadius: "30px"
    },
})


